import React from "react";

import NewsTemplate from "@templates/News/News";

type Props = {};

const News = (props: Props) => {
  return <NewsTemplate />;
};

export default News;
