import ImageHalfSize from "@components/atoms/ImageHalfSize/ImageHalfSize";

import EmailRecoveryForm from "@components/organisms/Recovery/EmailRecoveryForm/EmailRecoveryForm";

import styles from "./EmailRecovery.module.scss";

type Props = {};

const EmailRecovery = (props: Props) => {
  return (
    <div className={styles.EmailRecovery}>
      <div className={styles.imageContainer}>
        <ImageHalfSize />
      </div>
      <EmailRecoveryForm />
    </div>
  );
};

export default EmailRecovery;
