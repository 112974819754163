import React from "react";

import NewsSection from "@components/organisms/News/NewsSection";

type Props = {};

const News = (props: Props) => {
  return (
    <>
      <NewsSection />
    </>
  );
};

export default News;
