import { Accountant } from "@entities/Accountant.entity";
import { fetching } from "@entities/Fetching.entity";
import {
  getAccountantById,
  getAllAccountants,
  getAllAccountantsLikeIdOrNames,
  getTotalAccountants,
  updateAccountant,
} from "@service/Accountant.service";
import { errorMessage } from "@utils/Status";
import { toastError, toastLoading, toastUpdateLoading } from "@utils/Toast";
import { useCallback, useContext, useEffect, useState } from "react";

import { AuthContext } from "@providers/providers";

import {
  UseAccountantByIdResult,
  UseAccountantsByParamsResult,
  UseAccountantsResult,
} from "./types";

const useAccountants = (): UseAccountantsResult => {
  const { auth } = useContext(AuthContext);
  const [accountants, setAccountants] = useState<Accountant[] | undefined>(
    undefined
  );
  const [totalAccountants, setTotalAccountants] = useState<number>(0);
  const [active, setActive] = useState<boolean>(true);
  const [pagination, setPagination] = useState<number>(1);
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getAccountants = useCallback(async () => {
    try {
      const { data } = await getAllAccountants(
        active,
        auth!!.token,
        pagination
      );
      setAccountants(data.data);
      setFetchingState(fetching.Success);
    } catch (error: any) {
      errorMessage(error.response, undefined, [
        { status: 403, msg: "Error al cargar los datos de los contadores!" },
      ]);
      setFetchingState(fetching.Error);
    }
  }, [pagination, setPagination, active, setActive]);

  const getTotalAccs = async () => {
    try {
      if (auth) {
        const { data } = await getTotalAccountants(active, auth!!.token);
        setTotalAccountants(data.data.total);
      }
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  useEffect(() => {
    if (auth) {
      getTotalAccs();
      getAccountants();
    }
  }, [pagination, setPagination, active, setActive]);

  const updateData = () => {
    if (auth) {
      getTotalAccs();
      getAccountants();
    }
  };

  return [
    accountants,
    totalAccountants,
    active,
    fetchingState,
    pagination,
    setPagination,
    updateData,
    setActive,
  ];
};

const useAccountantsByParams = (): UseAccountantsByParamsResult => {
  const { auth } = useContext(AuthContext);

  const [accsFound, setAccsFound] = useState<Accountant[] | undefined | null>(
    undefined
  );
  const [accToSearch, setAccToSearch] = useState("");
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getSearchAccountants = useCallback(async () => {
    if (accToSearch.length === 0) return setAccsFound(undefined);
    try {
      const { data } = await getAllAccountantsLikeIdOrNames(
        accToSearch,
        auth!!.token
      );
      const result = data.data === undefined ? null : data.data;
      setAccsFound(result);
      setFetchingState(fetching.Success);
    } catch (error: any) {
      errorMessage(error.response, undefined, [
        { status: 403, msg: "Error al cargar los datos de los contadores!" },
      ]);
      setFetchingState(fetching.Error);
    }
  }, [accToSearch, setAccToSearch]);

  return [
    accsFound,
    accToSearch,
    setAccToSearch,
    getSearchAccountants,
    setAccsFound,
  ];
};

const useAccountantById = (idUser: string): UseAccountantByIdResult => {
  const { auth } = useContext(AuthContext);

  const [accountant, setAccountant] = useState<Accountant | undefined>();
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );
  const [fetchingStatePicture, setFetchingStatePicture] = useState<fetching>(
    fetching.Loading
  );

  const getAccountantInfo = useCallback(async () => {
    if (idUser) {
      try {
        const result = await getAccountantById(idUser, auth!!.token);
        if (result) {
          setAccountant(result.data.data);
          setFetchingState(fetching.Success);
        }
      } catch (error: any) {
        errorMessage(error.response, undefined, [
          { status: 403, msg: "Error al cargar los datos de los contadores!" },
        ]);
        setFetchingState(fetching.Error);
      }
    }
  }, [fetchingStatePicture, setFetchingStatePicture]);

  const updateProfilePicture = async (val: File | undefined) => {
    if (!val) return toastError("Imagen no adjuntada, inténtelo nuevamente.");
    const idToast = toastLoading("Actualizando información...");
    try {
      const result = await updateAccountant(
        idUser!!.toString(),
        { profilePicture: val },
        auth!!.token
      );
      if (result) {
        setFetchingStatePicture(fetching.Success);
        toastUpdateLoading(
          idToast,
          "Imagen del perfil del contador actualizada",
          "success",
          "bell"
        );
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        { status: 400, msg: "Error al actualizar al contador!" },
      ]);
    }
  };

  const updateProfileInfo = async (data: Partial<Accountant>) => {
    const idToast = toastLoading("Actualizando información...");
    try {
      const result = await updateAccountant(idUser, data, auth!!.token);
      if (result) {
        toastUpdateLoading(
          idToast,
          "Se actualizó la información",
          "success",
          "bell"
        );
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        { status: 400, msg: "Error al actualizar al contador!" },
      ]);
    }
  };

  const updateData = () => {
    if (auth) getAccountantInfo();
  };

  useEffect(() => {
    if (auth) getAccountantInfo();
  }, [fetchingStatePicture, setFetchingStatePicture]);

  return [
    accountant,
    fetchingState,
    updateProfilePicture,
    updateProfileInfo,
    updateData,
  ];
};

export { useAccountants, useAccountantsByParams, useAccountantById };
