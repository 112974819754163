import { size } from "./../../../../types/fonts";

type Props = {
  spanText: string;
  strongText: string;
  fontSize?: size;
  strongLeft?: boolean;
};

const SpanWithStrong = ({
  spanText,
  strongText,
  fontSize = "text-xl",
  strongLeft = false,
}: Props) => {
  return (
    <span className={`font-inter font-medium ${fontSize} text-gray-500`}>
      {strongLeft ? (
        <>
          <strong className="text-black">{strongText}</strong>
          {spanText}
        </>
      ) : (
        <>
          {spanText}
          <strong className="text-black">{strongText}</strong>
        </>
      )}
    </span>
  );
};

export default SpanWithStrong;
