type Props = {
  color?: string;
  style: string;
};

const AddDate = ({ color = "black", style }: Props) => {
  return (
    <svg
      className={style}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      style={{ color }}
    >
      <path
        fill-rule="evenodd"
        d="M18 5h1a2 2 0 0 1 2 2v2H3V7c0-1 .9-2 2-2h1V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1h3V4a1 1 0 1 1 2 0v1ZM3 11v8c0 1.2.9 2 2 2h14a2 2 0 0 0 2-2v-8H3Zm8 7a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1a1 1 0 1 0-2 0v1h-1a1 1 0 1 0 0 2h1v1Z"
        clip-rule="evenodd"
      />
    </svg>
  );
};

export default AddDate;
