import { Client } from "@entities/Client.entity";
import { validateLength } from "@utils/Validations";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import InputPhoneNumber from "@components/atoms/InputFormik/InputPhoneNumber/InputPhoneNumber";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import styles from "./EditClient.module.scss";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  editProfileInfo: (data: Partial<Client>) => void;
  updateData: () => void;
  email: string;
  names: string;
  surnames: string;
  phoneNumber: string;
  companyName: string;
  prefix: string;
  rol: string;
}

interface EditClientEntity {
  email: string;
  names: string;
  surnames: string;
  phoneNumber: string;
  prefix: string;
  companyName: string;
  rol: string;
}

const EditClient = ({
  isOpenModal,
  setIsOpenModal,
  editProfileInfo,
  updateData,
  email,
  names,
  surnames,
  phoneNumber,
  companyName,
  prefix,
  rol,
}: Props) => {
  const initialValues: EditClientEntity = {
    email: email,
    names: names,
    surnames: surnames,
    phoneNumber: phoneNumber,
    prefix: prefix,
    companyName: companyName,
    rol: rol,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email no válido")
      .required("Este campo es obligatorio"),
    names: Yup.string().required("Este campo es obligatorio"),
    surnames: Yup.string().required("Este campo es obligatorio"),
    phoneNumber: Yup.number().required("Este campo es obligatorio"),
    companyName: Yup.string().required("Este campo es obligatorio"),
    rol: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: EditClientEntity) => {
    const data: Partial<EditClientEntity> = {
      names: values.names,
      surnames: values.surnames,
      phoneNumber: `${values.prefix} ${values.phoneNumber}`,
      email: values.email,
      companyName: values.companyName,
      rol: values.rol,
    };
    editProfileInfo(data);
    if (updateData) updateData();
    setIsOpenModal();
  };

  return (
    <>
      <Modal
        title={"Editar Cliente"}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <div className={styles.formContainer}>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Nombres"}
                    />
                    <Input name={"names"} type={"text"} />
                  </div>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Apellidos"}
                    />
                    <Input name={"surnames"} type={"text"} />
                  </div>
                </div>
                <InputPhoneNumber
                  prefix={"prefix"}
                  name={"phoneNumber"}
                  type={"number"}
                  setValue={setFieldValue}
                  validation={validateLength}
                />
                <div className={styles.formContainer}>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Email"}
                    />
                    <Input name={"email"} type={"email"} />
                  </div>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Nombre de la Compañia"}
                    />
                    <Input name={"companyName"} type={"text"} />
                  </div>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Cargo"}
                    />
                    <Input name={"rol"} type={"text"} />
                  </div>
                </div>
                <div className={styles.btnsContainer}>
                  <Button
                    height="h-10"
                    text={"Actualizar"}
                    type={"submit"}
                    bgColor="bg-gray-800 hover:bg-gray-900"
                    extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                    padding="px-1 py-1"
                  />
                  <Button
                    height="h-10"
                    text={"Cancelar"}
                    onClick={setIsOpenModal}
                    type={"button"}
                    bgColor="bg-red-800 hover:bg-red-900"
                    extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                    padding="px-1 py-1"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default EditClient;
