import { Accounting } from "@entities/Accounting.entity";
import { IBalance, MonthTypes } from "@hooks/Accounting/types";
import { Dispatch, SetStateAction } from "react";

import BarChart from "@components/atoms/Chart/BarChart/BarChart";

import InfoContainer from "../InfoContainer/InfoContainer";

interface Props {
  accounting: Accounting[] | null | undefined;
  balanceInfo: IBalance | null | undefined;
  month: MonthTypes;
  toolTipContent?: (content: any) => JSX.Element | null;
  setMonth: Dispatch<SetStateAction<MonthTypes>>;
}

const BarChartBalance = ({
  accounting,
  balanceInfo,
  month,
  toolTipContent,
  setMonth,
}: Props): JSX.Element => {
  let title: string = "";
  if (balanceInfo) {
    switch (balanceInfo.balance) {
      case "asset":
        title = "Variación de activos total por mes";
        break;
      case "liabilities":
        title = "Variación de pasivo total por mes";
        break;
      case "equity":
        title = "Variación de patrimonio total por mes";
        break;
    }
  }

  const empty = Array(12)
    .fill(0)
    .map((_, index) => {
      return {
        month: index + 1,
        value: NaN,
      };
    });

  const data = [];
  if (balanceInfo) {
    balanceInfo.accountingByBalance.forEach((el) => {
      empty[el.month].month = el.month + 1;
      empty[el.month].value = el.value;
    });
  }

  data.push(...empty);

  return (
    <InfoContainer title={title}>
      <div className="p-4">
        <BarChart
          data={data}
          height={275}
          contentTooltip={accounting === null ? () => <></> : toolTipContent}
          indexHover={month}
          onPressCell={(val) => {
            if (Object.values(val).length > 0) {
              setMonth((val.activePayload[0].payload.month - 1) as MonthTypes);
            }
          }}
        />
      </div>
    </InfoContainer>
  );
};

export default BarChartBalance;
