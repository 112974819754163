import { FC } from "react";

interface Props {
  width: string;
  height?: string;
  alt: string;
  source: string;
  extraStyles?: string;
}

const Image: FC<Props> = ({
  width,
  height = "h-auto",
  alt,
  extraStyles,
  source,
}) => {
  return (
    <img
      alt={alt}
      className={`${width} ${height} ${alt} ${extraStyles}`}
      src={source}
    />
  );
};

export default Image;
