import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/auth`;

export interface authForm {
  email: string;
  password: string;
  recoveryCode: string;
}

export const login = async (auth: Partial<authForm>) => {
  return await axios.post(`${baseUrl}/login`, auth, { withCredentials: true });
};

export const renovateToken = async (token: string) => {
  return await axios.get(`${baseUrl}/renovate`, {
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: true,
  });
};

export const logout = async () => {
  return await axios.delete(`${baseUrl}/logout`, { withCredentials: true });
};

export const resetPassword = async (auth: Pick<authForm, "email">) => {
  return await axios.post(`${baseUrl}/resetPassword`, auth);
};

export const changePassword = async (
  auth: Pick<authForm, "email" | "recoveryCode">
) => {
  return await axios.post(`${baseUrl}/changePassword`, auth);
};
