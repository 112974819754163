import { fetching } from "@entities/Fetching.entity";
import { useContext } from "react";
import { useParams } from "react-router-dom";

import PQRsCloseTicket from "@components/organisms/PQRs/PQRsCloseTicket/PQRsCloseTicket";
import PQRsMessage from "@components/organisms/PQRs/PQRsMessage/PQRsMessage";
import PQRsResponse from "@components/organisms/PQRs/PQRsResponse/PQRsResponse";

import { AuthContext } from "@providers/providers";

import { usePqrsById, useUpdatePqrs } from "@hooks/Pqrs/usePqrs";

type Props = {};

const PqrsContent = (props: Props) => {
  const { auth } = useContext(AuthContext);
  const { id } = useParams();

  const [pqrs, pqrsResponse, fetchingState, updateData] = usePqrsById(
    parseInt(id as string)
  );
  const [updateP] = useUpdatePqrs(parseInt(id as string));

  return (
    <div className="flex flex-col gap-5">
      <PQRsMessage pqrs={pqrs} fetchingState={fetchingState} />
      {pqrsResponse &&
        pqrsResponse.map((pqrsRes, index) => {
          return (
            <>
              <PQRsMessage
                key={index}
                pqrs={pqrs}
                pqrsResponse={pqrsRes}
                fetchingState={fetchingState}
              />
            </>
          );
        })}

      {pqrs?.status !== "cerrada" && (
        <>
          {fetchingState === fetching.Success && (
            <>
              <PQRsResponse id={pqrs ? pqrs.id : 0} updateData={updateData} />
              {auth?.type !== "client" && (
                <PQRsCloseTicket
                  setIsClosed={async () =>
                    await updateP({ status: "cerrada" }, updateData)
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PqrsContent;
