import Cookies from "universal-cookie";

import { getCurrentDate } from "./Date";

export const appCookies = new Cookies(null, { path: "/" });

export const addCookie = (name: string, data: any) => {
  const creationDate = getCurrentDate();
  data.creationDate = creationDate;
  appCookies.set(name, data);
};

export const getCookie = (name: string) => {
  return appCookies.get(name);
};

export const removeCookie = (name: string, opts: { [key: string]: any }) => {
  return appCookies.remove(name, opts);
};
