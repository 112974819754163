import { Folder } from "@entities/index";
import { addFolder } from "@service/Documents.service";
import { getFolderPathToAddFolder } from "@utils/Documents";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Field, Form, Formik } from "formik";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

import styles from "./AddFolder.module.scss";

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
};

const AddFolder = ({ isOpenModal, setIsOpenModal, updateData }: Props) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  const initialValues: Partial<Folder> = {
    name: "",
    shared: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Este campo es obligatorio")
      .matches(/^[a-zA-Z0-9\s]*$/, {
        message: "Solo se permiten valores alfanuméricos",
      }),
  });

  const onSubmit = async ({ name, shared }: Partial<Folder>) => {
    const path = location.pathname.split("/");
    const folderPath = getFolderPathToAddFolder(path);
    const folderData: Partial<Folder> = {
      name,
      shared,
    };
    if (path.length > 3) {
      folderData.parentFolderId = parseInt(path.pop()?.split("-")[1] || "");
    }

    const idToast = toastLoading("Subiendo información...");
    try {
      const result = await addFolder(folderPath, folderData, auth!!.token);
      if (result) {
        toastUpdateLoading(idToast, "Carpeta agregada", "success", "bell");
        if (updateData) updateData();
        setIsOpenModal();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al añadir la carpeta",
        },
      ]);
    }
  };

  return (
    <Modal
      title={"Nueva carpeta"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form className={styles.form}>
              <div>
                <Span weight={"font-bold"} size={"text-base"} text={"Nombre"} />
                <Input name={"name"} type={"text"} />
                <label htmlFor="" className="mb-8">
                  <Field type="checkbox" name="shared" />
                  La carpeta es editable por el cliente.
                </label>
              </div>
              <div className={styles.btnsContainer}>
                <Button
                  height="h-10"
                  text={"Añadir"}
                  type={"submit"}
                  bgColor="bg-gray-800 hover:bg-gray-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
                <Button
                  height="h-10"
                  text={"Cancelar"}
                  onClick={setIsOpenModal}
                  type={"button"}
                  bgColor="bg-red-800 hover:bg-red-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddFolder;
