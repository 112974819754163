import { fetching } from "@entities/Fetching.entity";
import { Pqrs, PqrsType } from "@entities/Pqrs.entity";
import { PqrsResponse } from "@entities/PqrsResponse.entity";
import {
  addPqrs,
  getAllPqrs,
  getPqrsByAccountantId,
  getPqrsByClientNit,
  getPqrsById,
  getTotalPqrs,
  updatePqrs,
} from "@service/Pqrs.service";
import {
  addPqrsResponse,
  getPqrsResponseByPqrsId,
} from "@service/PqrsResponse.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "@providers/providers";

import { UsePqrsByIdResult, UsePqrsResult } from "./types";

const useTotalPqrs = (type: PqrsType): number[] => {
  const { auth } = useContext(AuthContext);
  const [totalPqrs, setTotalPqrs] = useState<number>(0);

  const getTPqrs = async () => {
    try {
      if (auth) {
        const { data } = await getTotalPqrs(type, auth!!.token);
        setTotalPqrs(data.data.total);
      }
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  useEffect(() => {
    if (auth) getTPqrs();
  }, []);

  return [totalPqrs];
};

const usePqrs = (): UsePqrsResult => {
  const { auth } = useContext(AuthContext);
  const { state } = useLocation();

  const [pqrs, setPqrs] = useState<Pqrs[] | undefined>();
  const [pqrsType, setPqrsType] = useState<PqrsType>(
    state && state.pqrsType ? state.pqrsType : "peticion"
  );
  const [pagination, setPagination] = useState<number>(1);
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getPqrs = useCallback(async () => {
    try {
      let data;
      switch (auth?.type) {
        case "client":
          data = await getPqrsByClientNit(
            auth!!.nit,
            auth!!.token,
            pagination,
            pqrsType
          );
          break;
        case "admin":
          data = await getAllPqrs(auth!!.token, pagination, pqrsType);
          break;
        case "accountant":
          data = await getPqrsByAccountantId(
            auth!!.id,
            auth!!.token,
            pagination,
            pqrsType
          );
          break;
      }
      if (data) setPqrs(data.data.data);
      setFetchingState(fetching.Success);
    } catch (error: any) {
      errorMessage(error.response);
      setFetchingState(fetching.Error);
    }
  }, [pagination, setPagination, pqrsType, setPqrsType]);

  useEffect(() => {
    if (auth) getPqrs();
  }, [pagination, setPagination, pqrsType, setPqrsType]);

  const updateData = () => {
    if (auth) getPqrs();
  };

  return [
    pqrs,
    pqrsType,
    setPqrsType,
    fetchingState,
    pagination,
    setPagination,
    updateData,
  ];
};

const usePqrsById = (idPqrs: number): UsePqrsByIdResult => {
  const { auth } = useContext(AuthContext);

  const [pqrs, setPqrs] = useState<Pqrs | undefined>();
  const [pqrsResponses, setPqrsResponses] = useState<
    PqrsResponse[] | undefined
  >();
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getPqrsInfo = async () => {
    try {
      const result = await getPqrsById(idPqrs, auth!!.token);
      if (result) {
        setPqrs(result.data.data);
        setFetchingState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.response);
      setFetchingState(fetching.Error);
    }
  };

  const getPqrsResponses = async () => {
    try {
      const result = await getPqrsResponseByPqrsId(idPqrs, auth!!.token);
      if (result) {
        setPqrsResponses(result.data.data);
      }
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  useEffect(() => {
    if (auth) {
      getPqrsInfo();
      getPqrsResponses();
    }
  }, []);

  const updateData = () => {
    if (auth) {
      getPqrsInfo();
      getPqrsResponses();
    }
  };

  return [pqrs, pqrsResponses, fetchingState, updateData];
};

const useAddPqrs = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  const savePqrs = async (data: Partial<Pqrs>) => {
    const idToast = toastLoading("Subiendo información...");
    try {
      const result = await addPqrs(data, auth!!.token);
      if (result) {
        toastUpdateLoading(
          idToast,
          "Pqrs de respuesta añadida exitosamente",
          "success",
          "bell"
        );
        navigate("/pqrs", { state: { pqrsType: data.type } });
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al añadir la pqrs de respuesta!",
        },
      ]);
    }
  };

  return [savePqrs];
};

const useAddResponse = () => {
  const { auth } = useContext(AuthContext);

  const savePqrsResponse = async (
    id: number,
    data: Partial<PqrsResponse>,
    updateData?: () => void
  ) => {
    const dataToSend: Pick<
      PqrsResponse,
      "pqrsId" | "authorName" | "response" | "supportFile"
    > = {
      pqrsId: id,
      authorName: `${auth?.names} ${auth?.surnames}`,
      response: data.response!!,
      supportFile: data.supportFile,
    };
    const idToast = toastLoading("Subiendo información...");
    try {
      const result = await addPqrsResponse(dataToSend, auth!!.token);
      if (result) {
        if (updateData) updateData();
        toastUpdateLoading(
          idToast,
          "Pqrs de respuesta añadida exitosamente",
          "success",
          "bell"
        );
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al añadir la pqrs de respuesta!",
        },
      ]);
    }
  };

  return [savePqrsResponse];
};

const useUpdatePqrs = (id: number) => {
  const { auth } = useContext(AuthContext);

  const updateP = async (data: Partial<Pqrs>, updateData: () => void) => {
    const idToast = toastLoading("Cerrando PQRS...");
    const dataToSend: Partial<Pqrs> = {
      accountantId: auth!!.type === "accountant" ? auth!!.id : undefined,
      status: data.status ? data.status : undefined,
    };
    try {
      await updatePqrs(id, dataToSend, auth!!.token).finally(updateData);
      toastUpdateLoading(
        idToast,
        "PQRS cerrada exitosamente!",
        "success",
        "bell"
      );
    } catch (error: any) {
      errorMessage(error.response, idToast);
    }
  };

  return [updateP];
};

export {
  useTotalPqrs,
  usePqrs,
  usePqrsById,
  useAddPqrs,
  useAddResponse,
  useUpdatePqrs,
};
