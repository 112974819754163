import { useContext } from "react";

import Icon from "@components/atoms/Icons/Icon";
import SpanWithStrong from "@components/atoms/SpanWithStrong/SpanWithStrong";

import { AuthContext } from "@providers/providers";

import styles from "./PageHeader.module.scss";

type Props = {};

const PageHeader = ({}: Props) => {
  const { auth } = useContext(AuthContext);

  return (
    <header className={styles.header}>
      <div className={styles.page}>
        <Icon icon={"bars"} />
      </div>
      <SpanWithStrong
        spanText="Bienvenido, "
        strongText={`${auth?.names} ${auth?.surnames}`}
      />
    </header>
  );
};

export default PageHeader;
