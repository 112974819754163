import { FC } from "react";

import Button from "../Button/Button";
import Icon from "../Icons/Icon";

interface Props {
  value: string;
  setValue: (val: string) => void;
  handleSearch: () => void;
  withBtn?: boolean;
}

const SearchInput: FC<Props> = ({
  value,
  setValue,
  handleSearch,
  withBtn = true,
}) => {
  return (
    <>
      <div
        className="relative"
        style={{ height: "53px", display: "flex", alignItems: "center" }}
      >
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Icon icon={"searchloop"} color="#4B5563" size={4} />
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 pl-10 text-sm text-gray-600 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Buscar"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ padding: 0, paddingLeft: "2.5rem", height: "100%" }}
          required
        />
        {withBtn && (
          <Button
            text={"Buscar"}
            onClick={handleSearch}
            type={"button"}
            width="w-18"
            height="h-8"
            bgColor="bg-gray-800 hover:bg-gray-900"
            padding="px-3 py-1"
            extraStyles="text-xs absolute right-2.5 rounded-[6px] focus:ring-4 focus:outline-none focus:ring-gray-300"
          />
        )}
      </div>
    </>
  );
};

export default SearchInput;
