import { Table } from "flowbite-react";
import React, { FC } from "react";
import "./CustomTable.css";

type Props = {
  columns: string[];
  Row: FC<any>;
  data: any;
  updateData?: () => void;
};

const CustomTable = ({ columns, Row, data, updateData }: Props) => {
  return (
    <div className="TableContainer">
    <Table className="DataTable">
      <Table.Head className="font-inter font-bold text-sm text-gray-500">
        {columns.map((el: string, i: number) => (
          <Table.HeadCell key={i}>{el}</Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="h-[66px]">
        {data.map((el: any, i: number) => (
          <Row key={i} data={el} updateData={updateData} />
        ))}
      </Table.Body>
    </Table>
    </div>
  );
};

export default CustomTable;
