import ImgError from "@assets/Images/rafiki.svg";

import ErrorTemplate from "@templates/Errors/Error";

type Props = {};

const Error404 = (props: Props) => {
  return (
    <ErrorTemplate
      code="404"
      title="PÁGINA NO ENCONTRADA"
      text="Lo sentimos, la página que buscaba no se ha encontrado. Puede que la dirección URL ingresada sea incorrecta, o la página se haya movido. Por favor compruebe la URL y vuelva a intentarlo."
      img={ImgError}
    />
  );
};

export default Error404;
