import React, { FC } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import Icon, { IconNames } from "../../atoms/Icons/Icon";
import TitleH3 from "../../atoms/TitleH3/TitleH3";

import styles from "./InfoContainer.module.scss";

interface Props {
  children: React.ReactNode;
  title: string;
  titleColor?: string;
  iconName?: IconNames;
  iconSize?: number;
  iconColor?: string;
  RightSideInfo?: React.ReactNode;
  goBack?: boolean;
  backgroundColor?: string;
  navigateOptions?: NavigateOptions;
  navigateRoute?: string;
}

const InfoContainer: FC<Props> = ({
  children,
  title,
  titleColor = "text-black",
  iconName,
  iconSize = 5,
  iconColor = "black",
  RightSideInfo = "",
  goBack = false,
  backgroundColor = "white",
  navigateOptions,
  navigateRoute,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerContainer} style={{ backgroundColor }}>
          <div>
            {goBack ? (
              <div
                style={{ cursor: "pointer", marginRight: 20 }}
                onClick={() =>
                  navigateRoute
                    ? navigate(navigateRoute, navigateOptions)
                    : navigate(-1)
                }
              >
                <Icon icon={"arrowLeft"} size={iconSize} color={iconColor} />
              </div>
            ) : null}
            {iconName && (
              <Icon icon={iconName} size={iconSize} color={iconColor} />
            )}
            <TitleH3 text={title} color={titleColor} weight="font-medium" align="text-left" />
          </div>
          {RightSideInfo}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default InfoContainer;
