import Input from "@components/atoms/InputFormik/Input/Input";
import Span from "@components/atoms/Span/Span";

const NewUrlForm = () => {
  return (
    <div className="flex flex-col items-start">
      <Span weight="font-bold" size="text-base" text="URL de la noticia" />
      <Input name="url" placeholder="URL" type="text" />
    </div>
  );
};

export default NewUrlForm;
