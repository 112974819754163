import { Recovery } from "@entities/Recovery.entity";
import { resetPassword } from "@service/Auth.service";
import { errorMessage } from "@utils/Status";
import { toastSuccess } from "@utils/Toast";
import { Form, Formik } from "formik";
import { FC, useContext, useState } from "react";
import * as Yup from "yup";

import User from "@assets/Icons/User.svg";
import GA_MainLogo from "@assets/Logos/GA_MainLogo.png";

import Anchor from "@components/atoms/Anchor/Anchor";
import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import Loader from "@components/atoms/Loader/Loader";
import Span from "@components/atoms/Span/Span";
import TitleH1 from "@components/atoms/TitleH1/TitleH1";

import { RecoveryPasswordContext } from "@providers/providers";

import styles from "./EmailRecoveryForm.module.scss";

interface Props {}

type initialValues = {
  email: string;
};

const EmailRecoveryForm: FC<Props> = ({}) => {
  const { passwordRecovery, setPasswordRecovery } = useContext(
    RecoveryPasswordContext
  );
  const [isLoading, setIsLoading] = useState(false);

  const initialValues: initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email no valido")
      .required("Este campo es obligatorio"),
  });

  const onSubmit = (values: initialValues) => {
    setIsLoading(true);
    const data: Recovery = {
      email: values.email,
      password: undefined,
    };
    sendRecoveryCode(data);
  };

  const sendRecoveryCode = async (data: Recovery) => {
    const email = data!!.email;
    if (email) {
      try {
        const result = await resetPassword({ email });
        if (result.data.status === "success") {
          toastSuccess(
            "Se te ha enviado un código de verificación a tu correo"
          );
          setPasswordRecovery(data);
        }
      } catch (error: any) {
        errorMessage(error.response, undefined, [
          { status: 400, msg: "Email incorrecto" },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form className={styles.EmailForm}>
            <img src={GA_MainLogo} alt="" />
            <div className={styles.title}>
              <TitleH1 text="Restablecer contraseña" weight={"font-bold"} />
            </div>
            <div className={styles.description}>
              <Span
                text={
                  "Introduzca la dirección de correo electrónico vinculada a su cuenta."
                }
                weight={"font-extralight"}
              />
            </div>
            <div className={styles.fields}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.formField}>
                    <Input
                      name={"email"}
                      placeholder={"Email"}
                      type={"email"}
                      icon={User}
                    />
                  </div>
                  <Button
                    text={"RESTABLECER CONTRASEÑA"}
                    height="h-auto"
                    type="submit"
                  />
                  <div className={styles.link}>
                    <Anchor label={"Volver"} link={"/"} />
                  </div>
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailRecoveryForm;
