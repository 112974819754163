import { fetching } from "@entities/Fetching.entity";
import { getHomeStats } from "@service/stats.service";
import { errorMessage } from "@utils/Status";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "@providers/providers";

import { UseStatsResult } from "./types";

const useStats = (): UseStatsResult => {
  const { auth } = useContext(AuthContext);
  const [stats, setStats] = useState<{
    clients: number;
    documents: number;
    pqrs: number;
  } | null>(null);
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getStats = async () => {
    try {
      if (auth) {
        const { data } = await getHomeStats(auth!!.token);
        const result = data.data;
        setStats(result);
        setFetchingState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.response);
      setFetchingState(fetching.Error);
    }
  };

  useEffect(() => {
    if (auth) {
      getStats();
    }
  }, []);
  return [stats, fetchingState];
};

export { useStats };
