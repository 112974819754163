import { Table } from "flowbite-react";

import Excel from "@assets/Icons/Excel.svg";
import FolderNotShared from "@assets/Icons/FolderNotShared.svg";
import FolderShared from "@assets/Icons/FolderShared.svg";
import Pdf from "@assets/Icons/Pdf.svg";

import Span from "@components/atoms/Span/Span";

export const DOCUMENT_TYPES = {
  folderNotShared: {
    title: "Carpeta de archivos",
    icon: FolderNotShared,
  },
  folderShared: {
    title: "Carpeta de archivos",
    icon: FolderShared,
  },
  xlsx: {
    title: "Documento de Excel",
    icon: Excel,
  },
  pdf: {
    title: "Documento PDF",
    icon: Pdf,
  },
};

export interface deletedDocumentRow {
  id: string;
  name: string;
  typeFile: "folderShared" | "folderNotShared" | "pdf" | "xlsx";
  deleterUserId: string;
  deleterUserName: string;
  deleteDate: string;
}

type Props = {
  data: deletedDocumentRow;
  updateData: () => void;
};

const DeletedDocumentRow = ({ data, updateData }: Props) => {
  return (
    <Table.Row className="relative h-[66px] border-y-[1px] border-solid border-gray-300 text-base">
      <Table.Cell className="flex items-center gap-5 h-[66px]">
        <div className="flex gap-3" title={data.name}>
          <img src={DOCUMENT_TYPES[data.typeFile].icon} alt="" />
          <Span
            text={data.name}
            size="text-base"
            align="text-left"
            extraStyles="w-72 truncate"
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <Span
          text={data.deleteDate}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
      <Table.Cell>
        <Span
          text={DOCUMENT_TYPES[data.typeFile].title}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
      <Table.Cell>
        <Span
          text={data.deleterUserName}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default DeletedDocumentRow;
