import { Recovery as RecoveryEntity, User } from "@entities/index";
import { Dispatch, SetStateAction, createContext } from "react";

interface Auth {
  auth: User | undefined;
  setAuth: Dispatch<SetStateAction<User | undefined>>;
}

interface PasswordRecovery {
  passwordRecovery: RecoveryEntity | undefined;
  setPasswordRecovery: Dispatch<SetStateAction<RecoveryEntity | undefined>>;
}

export const AuthContext = createContext<Auth>({
  auth: undefined,
  setAuth: () => {},
});

export const RecoveryPasswordContext = createContext<PasswordRecovery>({
  passwordRecovery: undefined,
  setPasswordRecovery: () => {},
});
