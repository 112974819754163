import { FC } from "react";

import { size, weight } from "./../../../../types/fonts";

interface Props {
  text: string;
  size?: size | string;
  weight?: weight;
  color?: string;
  align?: "text-center" | "text-right" | "text-justify" | "text-left";
}

const Text: FC<Props> = ({
  text,
  size = "text-base",
  weight = "font-light",
  color = "text-black",
  align = "text-center",
}) => {
  return (
    <p className={`${size} ${weight} ${color} ${align} whitespace-pre-wrap`}>
      {text}
    </p>
  );
};

export default Text;
