import { ErrorMessage } from "formik";
import { FC } from "react";

import Button from "@components/atoms/Button/Button";
import Span from "@components/atoms/Span/Span";

import styles from "./InputFile.module.scss";

interface Props {
  label: string;
  value?: string | undefined;
  id: string;
  setValue: (id: string, value: File) => void;
  accept: string;
  error?: string;
  touched?: boolean;
  [prop: string]: any;
}

const InputFile: FC<Props> = ({
  label,
  value,
  id,
  setValue,
  error,
  accept,
  touched,
  ...rest
}) => {
  return (
    <>
      <div className={styles.formFieldInput}>
        <Span
          size={"text-base"}
          weight={"font-bold"}
          text={label}
          align={"text-left"}
        />
        <div className={styles.inputFileContainer}>
          <span className="border bg-gray-50 border-gray-300 rounded-lg w-full p-2 h-10">
            {value}
          </span>
          <div className={styles.inputFile}>
            <Button
              extraS={{ backgroundColor: "#37374d", fontSize: "0.8rem" }}
              text={"Seleccionar archivo"}
              type={"button"}
              padding={"p-0"}
              height={"h-full"}
            />
            <input
              className={"absolute w-full opacity-0 cursor-pointer top-0"}
              type="file"
              name="profilePicture"
              onChange={(e) => setValue(id, e.target.files!![0])}
              accept={accept}
              {...rest}
            />
          </div>
        </div>
        <div className="h-5">
          {touched && error ? (
            <div className="text-red-500 text-sm">{error}</div>
          ) : (
            <ErrorMessage name={id}>
              {(msg: string) => (
                <div className="text-red-500 text-sm">{msg}</div>
              )}
            </ErrorMessage>
          )}
        </div>
      </div>
    </>
  );
};

export default InputFile;
