import { fetching } from "@entities/Fetching.entity";
import { New } from "@entities/News.entity";
import { getCompanyNews, getNews } from "@service/New.service";
import { errorMessage } from "@utils/Status";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { AuthContext } from "@providers/providers";

import { UseNews, UseNewsByUrlResult, UseNewsResult } from "./types";

const useUrlNews = (): UseNewsByUrlResult => {
  const { auth } = useContext(AuthContext);
  const [urlNews, setUrlNews] = useState<New[]>([]);
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getUrlNews = async (page: number = 1) => {
    try {
      if (auth) {
        const { data } = await getNews(auth!!.token, page);
        const result = data.data.map((item: New) => ({
          id: item.id,
          url: item.url,
          title: item.title,
          img: item.img,
          creationDate: `${new Date(item.creationDate).getDate()}/${
            new Date(item.creationDate).getMonth() + 1
          }/${new Date(item.creationDate).getFullYear()}`,
        }));
        setUrlNews(result);
        setFetchingState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.response);
      setFetchingState(fetching.Error);
    }
  };

  useEffect(() => {
    if (auth) {
      getUrlNews();
    }
  }, []);

  const updateUrlData = () => {
    if (auth) getUrlNews();
  };

  return [urlNews, setUrlNews, fetchingState, updateUrlData];
};

const useNewsScroll = (
  urlNews: New[],
  setUrlNews: Dispatch<SetStateAction<New[]>>
): UseNewsResult => {
  const { auth } = useContext(AuthContext);
  const [page, setPage] = useState<number>(2);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const elementRef = useRef(null);

  const getNewsByPage = async () => {
    try {
      if (auth) {
        const { data } = await getNews(auth!!.token, page);
        const result = data.data.map((item: New) => ({
          id: item.id,
          url: item.url,
          title: item.title,
          img: item.img,
          creationDate: `${new Date(item.creationDate).getDate()}/${
            new Date(item.creationDate).getMonth() + 1
          }/${new Date(item.creationDate).getFullYear()}`,
        }));
        if (result.length === 0) {
          setHasMore(false);
        } else {
          setUrlNews((prevNews) => [...prevNews, ...result]);
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  const onIntersection = (entries: any) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting && hasMore) {
      getNewsByPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [urlNews]);

  const updatePaging = () => {
    setPage(2);
    setHasMore(true);
  };

  return [elementRef, hasMore, updatePaging];
};

const useNews = (): UseNews => {
  const { auth } = useContext(AuthContext);
  const [news, setNews] = useState<New[]>([]);
  const [fetchState, setFetchState] = useState<fetching>(fetching.Loading);

  const getNews = async () => {
    try {
      if (auth) {
        const { data } = await getCompanyNews(auth!!.token);
        const result = data.data.map((item: New) => ({
          id: item.id,
          title: item.title,
          img: item.img,
          description: item.description,
          creationDate: `${new Date(item.creationDate).getDate()}/${
            new Date(item.creationDate).getMonth() + 1
          }/${new Date(item.creationDate).getFullYear()}`,
        }));
        setNews(result);
        setFetchState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.message);
      setFetchState(fetching.Error);
    }
  };

  useEffect(() => {
    if (auth) {
      getNews();
    }
  }, []);

  const updateData = () => {
    if (auth) getNews();
  };

  return [news, fetchState, updateData];
};

export { useUrlNews, useNewsScroll, useNews };
