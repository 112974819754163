import React, { FC } from "react";

import styles from "./ImageHalfSize.module.scss";

interface Props {}

const ImageHalfSize: FC<Props> = (Props) => {
  return <div className={styles.ImageHalfSize}></div>;
};

export default ImageHalfSize;
