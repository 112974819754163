import { PqrsResponse } from "@entities/PqrsResponse.entity";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import Textarea from "@components/atoms/InputFormik/Textarea/Textarea";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

import { useAddResponse } from "@hooks/Pqrs/usePqrs";

import styles from "./PQRsResponse.module.scss";

type Props = {
  id: number;
  updateData: () => void;
};

const PQRsResponse = ({ id, updateData }: Props) => {
  const [savePqrsResponse] = useAddResponse();

  const initialValues: Pick<PqrsResponse, "response" | "supportFile"> = {
    response: "",
    supportFile: undefined,
  };

  const validationSchema = Yup.object({
    response: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: any, { resetForm }: any) => {
    const data = {
      response: values.response,
      supportFile: values.supportFile,
    };
    await savePqrsResponse(id!!, data, updateData).finally(() => resetForm());
  };

  return (
    <InfoContainer title={"Responder"} iconName={"headset"}>
      <div className={styles.pqrsResponse}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className={styles.form}>
                <Textarea name={"response"} placeholder={""} />
                <InputFile
                  label={"Subir archivo..."}
                  value={
                    values.supportFile instanceof File && values.supportFile
                      ? values.supportFile.name
                      : ""
                  }
                  setValue={setFieldValue}
                  id={"supportFile"}
                  accept={".jpg, .png, .pdf"}
                />
                <Button
                  text={"Enviar"}
                  type={"submit"}
                  width="w-24"
                  height="h-9"
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </InfoContainer>
  );
};

export default PQRsResponse;
