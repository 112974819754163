import { fetching } from "@entities/Fetching.entity";
import { Pqrs } from "@entities/Pqrs.entity";
import { PqrsResponse } from "@entities/PqrsResponse.entity";

import Excel from "@assets/Icons/Excel.svg";
import Image from "@assets/Icons/Image.svg";
import Pdf from "@assets/Icons/Pdf.svg";

import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Span from "@components/atoms/Span/Span";
import SpanWithStrong from "@components/atoms/SpanWithStrong/SpanWithStrong";
import Text from "@components/atoms/Text/Text";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

import styles from "./PQRsMessage.module.scss";

type Props = {
  pqrs?: Pqrs;
  pqrsResponse?: PqrsResponse;
  fetchingState: fetching;
};

type FileFormat = {
  [format: string]: { title: string; icon: string };
};

const DOCUMENT_TYPES: FileFormat = {
  xlsx: {
    title: "Documento de Excel",
    icon: Excel,
  },
  pdf: {
    title: "Documento PDF",
    icon: Pdf,
  },
  jpg: {
    title: "Imagen JPG",
    icon: Image,
  },
  png: {
    title: "Imagen PNG",
    icon: Image,
  },
};

const PQRsMessage = ({ pqrs, pqrsResponse, fetchingState }: Props) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
  };

  const getTypeFile = (value: string, element: "title" | "icon") => {
    if (value === undefined) return "";
    const val: string | undefined = value.split(".").pop();
    if (val === undefined) return "";
    return DOCUMENT_TYPES[val][element];
  };

  const downloadFile = (data: string) => {
    const aTag = document.createElement("a");
    aTag.href = data;
    aTag.target = "blank";
    aTag.setAttribute("download", data);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  const renderPqrsMessage = (): JSX.Element => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (!pqrs) {
          return (
            <div
              className={"flex justify-center items-center flex-col gap-2 p-36"}
            >
              <Icon icon={"inbox"} size={20} />
              <Span
                text={"No se encontró ninguna PQRS"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <>
            <InfoContainer
              goBack={pqrsResponse ? false : true}
              navigateRoute={"/pqrs"}
              navigateOptions={{ state: { pqrsType: pqrs.type } }}
              title={`${pqrsResponse ? "RE: " : ""}${pqrs.title}`}
              iconName={"headset"}
              RightSideInfo={
                <SpanWithStrong
                  spanText="Contador, "
                  strongText={
                    typeof pqrs.accountantId === "object"
                      ? `${pqrs.accountantId.names} ${pqrs.accountantId.surnames}`
                      : ""
                  }
                  fontSize="text-lg"
                />
              }
            >
              <article className={`${styles.article} shadow-md`}>
                <header className={styles.header}>
                  <div className="flex gap-4">
                    {!pqrsResponse && (
                      <img
                        src={
                          typeof pqrs.clientId === "object"
                            ? (pqrs.clientId.companyLogo as string)
                            : undefined
                        }
                        alt=""
                        className="w-[57px] h-[57px] rounded-full"
                      />
                    )}
                    <div className="flex flex-col justify-end">
                      <Span
                        align={"text-left"}
                        text={
                          pqrsResponse
                            ? pqrsResponse.authorName
                            : typeof pqrs.clientId === "object"
                            ? `${pqrs.clientId.names} ${pqrs.clientId.surnames}`
                            : "Cliente"
                        }
                      />
                      <Span
                        align={"text-left"}
                        text={new Date(
                          pqrsResponse
                            ? pqrsResponse.creationDate
                            : pqrs.creationDate
                        ).toLocaleDateString("es-co", dateOptions)}
                        weight="font-light"
                        size="text-base"
                      />
                    </div>
                  </div>
                  <div className={`${styles.pqrsType} text-xl font-medium`}>
                    <div className="bg-blue-900 border border-white uppercase p-2">
                      {pqrsResponse
                        ? "respuesta"
                        : pqrs.type === "peticion"
                        ? "petición"
                        : pqrs.type}
                    </div>
                    {!pqrsResponse && (
                      <div className="bg-blue-900 border border-white uppercase p-2">
                        {pqrs.id}
                      </div>
                    )}
                  </div>
                </header>
                <div className={styles.description}>
                  <Text
                    text={pqrsResponse ? pqrsResponse.response : pqrs.body}
                    align="text-left"
                    color="text-gray-600"
                  />
                </div>
                {(pqrsResponse?.supportFile || pqrs.supportFile) && (
                  <div
                    className={
                      "flex row gap-1.5 p-3 hover:underline cursor-pointer"
                    }
                    style={{ width: "fit-content" }}
                    onClick={() =>
                      downloadFile(
                        pqrsResponse
                          ? (pqrsResponse.supportFile as string)
                          : (pqrs.supportFile as string)
                      )
                    }
                  >
                    <img
                      src={getTypeFile(
                        pqrsResponse
                          ? (pqrsResponse.supportFile as string)
                          : (pqrs.supportFile as string),
                        "icon"
                      )}
                    />
                    <Span
                      text={getTypeFile(
                        pqrsResponse
                          ? (pqrsResponse.supportFile as string)
                          : (pqrs.supportFile as string),
                        "title"
                      )}
                    />
                  </div>
                )}
              </article>
            </InfoContainer>
          </>
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };
  return renderPqrsMessage();
};

export default PQRsMessage;
