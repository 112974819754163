import { updateNew } from "@service/New.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import Textarea from "@components/atoms/InputFormik/Textarea/Textarea";
import Loader from "@components/atoms/Loader/Loader";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  id: string;
  title: string;
  description: string;
  updateData: () => void;
}

interface EditNewEntity {
  title: string;
  description: string;
  img: File | undefined;
}

const EditNew = ({
  isOpenModal,
  setIsOpenModal,
  id,
  title,
  description,
  updateData,
}: Props) => {
  const { auth } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const initialValues: EditNewEntity = {
    title: title,
    description: description,
    img: undefined,
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Este campo es obligatorio")
      .test(
        "len",
        "Deben ser menos de 100 carácteres",
        (val) => val.length < 100
      ),
    description: Yup.string()
      .required("Este campo es obligatorio")
      .test(
        "len",
        "Deben ser menos de 300 carácteres",
        (val) => val.length < 300
      ),
    img: Yup.string(),
  });

  const onSubmit = async (values: any) => {
    const toast = toastLoading("Actualizando noticia...");
    setLoader(true);
    try {
      const result = await updateNew(id, values, auth!!.token);
      if (result) {
        toastUpdateLoading(toast, "Noticia subida", "success", "bell");
        setIsOpenModal();
        updateData();
      }
    } catch (error: any) {
      errorMessage(error.response, toast, [
        {
          status: 400,
          msg: "Error al subir noticia!",
        },
      ]);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      title="Editar noticia"
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="flex flexcol gap-2">
              {loader ? (
                <div className="w-full h-40 flex justify-center items-center">
                  <Loader />
                </div>
              ) : (
                <div className="w-full h-auto flex flex-col justify-center gap-4">
                  <div className="w-full flex flex-col items-start justify-start">
                    <Span
                      text="Título de la noticia"
                      weight="font-bold"
                      size="text-base"
                    />
                    <Input name={"title"} placeholder={""} type="text" />
                    <Span
                      text="Descripción"
                      weight="font-bold"
                      size="text-base"
                    />
                    <Textarea name={"description"} placeholder={""} />
                    <Span
                      text="Imagen (Opcional)"
                      weight="font-bold"
                      size="text-base"
                    />
                    <InputFile
                      label=""
                      value={values.img?.name}
                      setValue={setFieldValue}
                      id={"img"}
                      accept={".jpg, .png"}
                    />
                  </div>
                  <div className="w-full flex justify-center">
                    <div className="w-auto sm:w-[50%] flex flex-row gap-5">
                      <Button
                        text="Actualizar"
                        type="submit"
                        padding="py-2 px-7"
                        extraStyles="text-sm md:text-base"
                        bgColor="bg-blue-dashboard hover:bg-blue-800"
                      />
                      <Button
                        text="Cancelar"
                        type="button"
                        padding="py-2 px-5"
                        extraStyles="text-sm md:text-base"
                        bgColor="bg-red-800 hover:bg-red-900"
                        onClick={() => {
                          setIsOpenModal();
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditNew;
