import { Event } from "@entities/Event.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/clients`;

export const addEvent = (
  nit: string,
  eventData: Partial<Event>,
  token: string
) => {
  return axios.post(`${baseUrl}/${nit}/calendar/events`, eventData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEventsForCalendar = (
  nit: string,
  startDate: Date,
  endDate: Date,
  token: string
) => {
  return axios.get(`${baseUrl}/${nit}/calendar/events`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      startDate,
      endDate,
    },
  });
};

export const deleteEventById = (
  nit: string,
  eventId: string,
  token: string
) => {
  return axios.delete(`${baseUrl}/${nit}/calendar/events/${eventId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
