// Get the difference of hours with UTC and the local date.
export const getDifferenceTime = (ISOoStringDate: string): Date => {
  const date = new Date(ISOoStringDate);

  const timeDifference = new Date().getTimezoneOffset() / 60;

  date.setHours(date.getHours() - timeDifference);

  return date;
};
