import { getDocumentsForCalendar } from "@service/Calendar.service";
import { getEventsForCalendar } from "@service/Events.service";
import { errorMessage } from "@utils/Status";
import { useContext } from "react";

import { AuthContext } from "@providers/providers";

import { UseCalendarResult } from "./types";

const useCalendar = (nit: string): UseCalendarResult => {
  const { auth } = useContext(AuthContext);
  const getDocumentsCalendar = async (startDate: Date, endDate: Date) => {
    try {
      if (auth) {
        const { data } = await getDocumentsForCalendar(
          nit,
          startDate,
          endDate,
          auth.token
        );

        return data.data.documents;
      }
    } catch (error: any) {
      errorMessage(error.response);
      return [];
    }
  };

  const getEventsCalendar = async (startDate: Date, endDate: Date) => {
    try {
      if (auth) {
        const { data } = await getEventsForCalendar(
          nit,
          startDate,
          endDate,
          auth.token
        );

        return data.data.events;
      }
    } catch (error: any) {
      errorMessage(error.response);
      return [];
    }
  };

  return [getDocumentsCalendar, getEventsCalendar];
};

export { useCalendar };
