import PageHeader from "@components/molecules/PageHeader/PageHeader";

import SideMenu from "@components/organisms/SideMenu/SideMenu";

import styles from "./Layout.module.scss";

const LayoutDashboard = (Component: any) => {
  return (
    <div className={styles.layout}>
      <input type="checkbox" className="sideInputController" />
      <SideMenu />
      <div className={styles.pageContent}>
        <PageHeader />
        <div className={styles.componentContainer}>
          <Component />
        </div>
      </div>
    </div>
  );
};

export default LayoutDashboard;
