import { useContext } from "react";

import Stats from "@components/organisms/Stats/Stats";
import PQRsTable from "@components/organisms/Table/PQRsTable/PQRsTable";

import { AuthContext } from "@providers/providers";

import ClientContent from "./ClientContent";

const DashboardContent = () => {
  const { auth } = useContext(AuthContext);

  return (
    <>
      <div className="flex justify-start items-center flex-col my-0">
        {auth?.type === "admin" && <Stats />}
        {auth?.type !== "client" && <PQRsTable />}
      </div>
      {auth?.type === "client" && <ClientContent nit={auth.nit} />}
    </>
  );
};

export default DashboardContent;
