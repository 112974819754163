import { addNew } from "@service/New.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Loader from "@components/atoms/Loader/Loader";
import Modal from "@components/atoms/Modal/Modal";

import { AuthContext } from "@providers/providers";

import NewDataForm from "./NewDataForm";
import NewUrlForm from "./NewUrlForm";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData: () => void;
  updateDataUrl: () => void;
  updatePaging: () => void;
}

interface AddNewEntity {
  url?: string;
  title?: string;
  description?: string;
  img?: File | undefined;
}

const AddNew = ({
  isOpenModal,
  setIsOpenModal,
  updateData,
  updateDataUrl,
  updatePaging,
}: Props) => {
  const [hasUrl, setHasUrl] = useState(false);

  const { auth } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const initialValues: AddNewEntity = hasUrl
    ? {
        url: "",
      }
    : {
        title: "",
        description: "",
        img: undefined,
      };

  const validationSchema = hasUrl
    ? Yup.object({
        url: Yup.string()
          .url("URL no valido")
          .required("Este campo es obligatorio"),
      })
    : Yup.object({
        title: Yup.string()
          .required("Este campo es obligatorio")
          .test(
            "len",
            "Deben ser menos de 100 carácteres",
            (val) => val.length < 100
          ),
        description: Yup.string()
          .required("Este campo es obligatorio")
          .test(
            "len",
            "Deben ser menos de 300 carácteres",
            (val) => val.length < 300
          ),
        img: Yup.string(),
      });

  const onSubmit = async (values: any) => {
    const toast = toastLoading("Subiendo noticia...");
    setLoader(true);
    try {
      const result = await addNew(values, auth!!.token);
      if (result) {
        toastUpdateLoading(toast, "Noticia subida", "success", "bell");
        setIsOpenModal();
        updateData();
        updateDataUrl();
        updatePaging();
      }
    } catch (error: any) {
      errorMessage(error.response, toast, [
        {
          status: 400,
          msg: "Error al subir noticia!",
        },
      ]);
    } finally {
      setLoader(false);
    }
  };

  const formOption = (values: AddNewEntity, setFieldValue: any) => {
    return hasUrl ? (
      <NewUrlForm />
    ) : (
      <NewDataForm setFieldValue={setFieldValue} values={values} />
    );
  };

  useEffect(() => {
    setHasUrl(false);
  }, [isOpenModal]);

  return (
    <Modal
      title={"Añadir noticia"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="flex flex-col gap-2">
              {loader ? (
                <div className="w-full h-40 flex justify-center items-center">
                  <Loader />
                </div>
              ) : (
                <div className="w-full h-auto flex flex-col justify-center gap-4">
                  <label className="flex flex-row items-center gap-2 text-gray-900 font-semibold">
                    <input
                      className="rounded "
                      type="checkbox"
                      value="has_url"
                      checked={hasUrl}
                      onClick={(e) => {
                        setHasUrl(!hasUrl);
                      }}
                      readOnly
                    />
                    Agregar noticia vía URL
                  </label>

                  {formOption(values, setFieldValue)}

                  <div className="w-full flex justify-center">
                    <div className="w-[80%] sm:w-[50%] flex flex-row gap-6">
                      <Button
                        text="Añadir"
                        type="submit"
                        padding="py-2 px-7"
                        extraStyles="text-sm md:text-base"
                        bgColor="bg-gray-800 hover:bg-gray-900"
                      />
                      <Button
                        text="Cancelar"
                        type="button"
                        padding="py-2 px-5"
                        extraStyles="text-sm md:text-base"
                        bgColor="bg-red-800 hover:bg-red-900"
                        onClick={() => {
                          setIsOpenModal();
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddNew;
