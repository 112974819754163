import { Client } from "@entities/Client.entity";
import { fetching } from "@entities/Fetching.entity";
import { addClient } from "@service/Client.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useCallback, useContext, useState } from "react";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Loader from "@components/atoms/Loader/Loader";
import Modal from "@components/atoms/Modal/Modal";

import { AuthContext } from "@providers/providers";

import { useClients } from "@hooks/Clients/useClients";

import AccountantForm from "./AccountantForm";
import styles from "./AddClient.module.scss";
import CompanyForm from "./CompanyForm";
import ContactForm from "./ContactForm";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
  idAccountant?: number;
}

interface AddClientEntity {
  email: string;
  nit: string;
  names: string;
  surnames: string;
  phoneNumber: string;
  prefix: string;
  companyName: string;
  companyLogo: File | undefined;
  rol: string;
  accId: string;
}

interface BtnProps {
  text: string;
  onPress?: () => void;
  color?: string;
  type: "submit" | "button" | "reset";
}

const Btn = ({
  text,
  onPress,
  color = "bg-gray-800 hover:bg-gray-900",
  type,
}: BtnProps) => {
  return (
    <Button
      text={text}
      type={type}
      onClick={onPress}
      height="h-10"
      bgColor={color}
      padding="px-1 py-1"
      extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
    />
  );
};

const AddClient = ({
  isOpenModal,
  setIsOpenModal,
  updateData,
  idAccountant,
}: Props) => {
  const { auth } = useContext(AuthContext);

  const [selection, setSelection] = useState(0);
  const [loader, setLoader] = useState(false);

  const initialValues: AddClientEntity = {
    email: "",
    nit: "",
    names: "",
    surnames: "",
    phoneNumber: "",
    prefix: "",
    companyName: "",
    companyLogo: undefined,
    rol: "",
    accId: idAccountant ? idAccountant.toString() : "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email no válido")
      .required("Este campo es obligatorio"),
    nit: Yup.string().required("Este campo es obligatorio"),
    names: Yup.string().required("Este campo es obligatorio"),
    surnames: Yup.string().required("Este campo es obligatorio"),
    phoneNumber: Yup.number().required("Este campo es obligatorio"),
    companyName: Yup.string().required("Este campo es obligatorio"),
    companyLogo: Yup.string().required("Este campo es obligatorio"),
    rol: Yup.string().required("Este campo es obligatorio"),
    accId:
      auth?.type === "accountant"
        ? Yup.string()
        : Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: AddClientEntity) => {
    const data: Pick<
      Client,
      | "email"
      | "nit"
      | "names"
      | "surnames"
      | "phoneNumber"
      | "companyName"
      | "companyLogo"
      | "rol"
      | "accountant"
    > = {
      email: values.email,
      nit: values.nit,
      names: values.names,
      surnames: values.surnames,
      phoneNumber: `${values.prefix} ${values.phoneNumber}`,
      companyName: values.companyName,
      companyLogo: values.companyLogo,
      rol: values.rol,
      accountant:
        auth?.type === "accountant" ? auth.id : parseInt(values.accId),
    };

    const idToast = toastLoading("Subiendo información...");
    setLoader(true);
    try {
      const result = await addClient(data, auth!!.token);
      if (result) {
        if (updateData) updateData();
        toastUpdateLoading(
          idToast,
          "Cliente añadido exitosamente",
          "success",
          "bell"
        );
        setSelection(0);
        setIsOpenModal();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        { status: 400, msg: "Error al actualizar al contador!" },
      ]);
    } finally {
      setLoader(false);
    }
  };

  const validateValuesToContinue = (
    values: AddClientEntity
  ): string[] | undefined => {
    if (
      selection === 0 &&
      (values.companyName.length <= 0 || !values.companyLogo)
    ) {
      return ["companyName", "companyLogo"];
    } else if (
      selection === 1 &&
      (values.names.length <= 0 ||
        values.surnames.length <= 0 ||
        values.nit.toString().length <= 0 ||
        values.email.length <= 0 ||
        values.phoneNumber.toString().length <= 0 ||
        values.rol.length <= 0)
    ) {
      return ["names", "surnames", "nit", "email", "phoneNumber", "rol"];
    } else if (selection === 2 && values.accId) {
      return ["accId"];
    }

    return undefined;
  };

  const currentSelection = useCallback(
    (
      values: any,
      errors: any,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void,
      touched: any
    ) => {
      switch (selection) {
        case 0:
          return (
            <CompanyForm
              valueCompanyLogo={values.companyLogo?.name}
              setFieldValue={setFieldValue}
              errors={{
                CNError: errors.companyName,
                CLError: errors.companyLogo,
              }}
              touched={touched}
            />
          );
        case 1:
          return (
            <ContactForm
              setFieldValue={setFieldValue}
              errors={{
                CNError: errors.names,
                CSError: errors.surnames,
                CNTError: errors.nit,
                CEError: errors.email,
                CPNError: errors.phoneNumber,
                CPError: errors.rol,
              }}
              touched={touched}
            />
          );
        case 2:
          return (
            <AccountantForm
              val={values.accId}
              setValue={setFieldValue}
              error={errors.accId}
            />
          );
      }
    },
    [selection, setSelection]
  );

  const currentBtns = useCallback(
    (values: any, validateField: any, setTouched: any) => {
      const BtnAdd = (
        <Btn text={"Añadir"} type={"submit"} color={"bg-blue-dashboard"} />
      );
      const BtnCancel = (
        <Btn
          text={"Cancelar"}
          type={"button"}
          onPress={() => {
            setIsOpenModal();
            setSelection(0);
          }}
          color={"bg-red-800 hover:bg-red-900"}
        />
      );
      const BtnNext = (
        <Btn
          text={"Siguiente"}
          type={"button"}
          onPress={() => {
            const validate = validateValuesToContinue(values);
            if (validate) {
              const object: { [key: string]: boolean } = {};
              validate.forEach((val) => {
                object[val] = true;
                validateField(val);
              });
              setTouched(object);
            } else {
              setSelection(selection + 1);
            }
          }}
        />
      );
      const BtnBack: JSX.Element = (
        <Btn
          text={"Volver"}
          type={"button"}
          onPress={() => {
            selection > 0 && setSelection(selection - 1);
          }}
          color={"bg-red-800 hover:bg-red-900"}
        />
      );

      switch (selection) {
        case 0:
          return (
            <>
              {BtnNext}
              {BtnCancel}
            </>
          );
        case 1:
          if (auth?.type === "accountant" || idAccountant) {
            return (
              <>
                {BtnBack}
                {BtnAdd}
              </>
            );
          }
          return (
            <>
              {BtnNext}
              {BtnBack}
            </>
          );
        case 2:
          return (
            <>
              {BtnBack}
              {BtnAdd}
            </>
          );
      }
    },
    [selection, setSelection]
  );

  return (
    <Modal
      title={"Añadir cliente"}
      secondaryTitle={
        selection === 1
          ? "Persona de contacto"
          : selection === 2
          ? "Asignar contador"
          : undefined
      }
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
          validateField,
          touched,
          setTouched,
        }) => {
          return (
            <Form>
              <div className={styles.formContainer}>
                {currentSelection(values, errors, setFieldValue, touched)}
              </div>
              <div className={styles.btnsContainer}>
                {loader ? (
                  <Loader />
                ) : (
                  currentBtns(values, validateField, setTouched)
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddClient;
