import { CustomFlowbiteTheme } from "flowbite-react";

import Carousel from "@components/atoms/Carousel/Carousel";
import Icon from "@components/atoms/Icons/Icon";

const customTheme: CustomFlowbiteTheme["carousel"] = {
  root: {
    leftControl:
      "hidden md:block absolute top-1/2 -translate-y-1/2 left-0 px-0",
    rightControl:
      "hidden md:block absolute top-1/2 -translate-y-1/2 right-0 px-0",
  },
  indicators: {
    active: {
      off: "md:hidden bg-gray-400",
      on: "md:hidden bg-blue-800",
    },
    wrapper: "absolute -bottom-10 left-1/2 flex -translate-x-1/2 space-x-3",
  },
};

const ButtonCarrousel = (iconName: "caretLeft" | "caretRight") => {
  const styles = iconName === "caretLeft" ? "rounded-r-md" : "rounded-l-md";
  return (
    <div
      className={`w-[38px] h-[52px] flex justify-center items-center bg-blue-dashboard hover:bg-blue-950 group-focus:outline-none group-focus:ring-2 group-focus: ring-slate-950 ring-inset ${styles}`}
    >
      <Icon icon={iconName} color="white" size={7} />
    </div>
  );
};

interface Props {
  children: any;
}

const NewsCarousel = ({ children }: Props) => {
  return (
    <Carousel
      slide={false}
      indicators={true}
      customTheme={customTheme}
      leftControl={ButtonCarrousel("caretLeft")}
      rightControl={ButtonCarrousel("caretRight")}
    >
      {children}
    </Carousel>
  );
};

export default NewsCarousel;
