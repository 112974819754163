import AccountantP from "@components/organisms/Profile/AccountantProfile/AccountantProfile";

import styles from "./AccountantProfile.module.scss";

type Props = {};

const AccountantProfile = (props: Props) => {
  return (
    <div className={styles.accountant}>
      <AccountantP />
    </div>
  );
};

export default AccountantProfile;
