import { addCookie, getCookie, removeCookie } from "@utils/Cookie";

import { renovateToken } from "../service/Auth.service";

let tokenInterval: any;

const getNewToken = async () => {
  try {
    const authCookie = getCookie("auth");
    const { data } = await renovateToken(authCookie.token);
    authCookie.token = data.data.token;
    addCookie("auth", authCookie);
  } catch (error: any) {
    if (error.code === "ERR_BAD_REQUEST") {
      removeCookie("auth", { path: "/" });
    }
  }
};

export const startTokenValidationInterval = (startNewToken: boolean = true) => {
  if (startNewToken) getNewToken();
  tokenInterval = setInterval(() => {
    getNewToken();
    //Interval value must be set to 1740000 more or less 30 min
  }, 1740000);
};

export const stopTokenValidationInterval = () => {
  clearInterval(tokenInterval);
};
