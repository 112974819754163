import { fetching } from "@entities/Fetching.entity";

import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Span from "@components/atoms/Span/Span";

import StatisticCard from "@components/molecules/StatisticCard/StatisticCard";

import { useStats } from "@hooks/Stats/useStats";

import styles from "./Stats.module.scss";

type Props = {};

const Stats = (props: Props) => {
  const [stats, fetchingState] = useStats();

  const renderStatus = () => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="w-full flex justify-center p-4">
            <Loader />
          </div>
        );
      case fetching.Success:
        return (
          <section className={styles.stats}>
            <StatisticCard
              statName={"Clientes Actuales"}
              statValue={`${stats?.clients}`}
              iconColor={"#F98080"}
              borderColor={"border-red-400"}
              iconName={"usersgroup"}
              iconSize={14}
            />
            <StatisticCard
              statName={"Documentos"}
              statValue={`${stats?.documents}`}
              iconColor={"#6875F5"}
              borderColor={"border-indigo-500"}
              iconName={"book"}
              iconSize={14}
            />
            <StatisticCard
              statName={"Nuevos PQRs"}
              statValue={`${stats?.pqrs}`}
              iconColor={"#6B7280"}
              borderColor={"border-gray-500"}
              iconName={"headset"}
              iconSize={14}
            />
          </section>
        );
      case fetching.Error:
        return (
          <div className="flex justify-center items-center flex-row gap-4 p-5">
            <Icon icon={"gear"} size={10} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"lg:text-3xl text-sm"}
              weight={"font-semibold"}
              align={"text-justify"}
            />
          </div>
        );
    }
  };
  return renderStatus();
};

export default Stats;
