import { deleteNew } from "@service/New.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { useContext, useState } from "react";

import Button from "@components/atoms/Button/Button";
import Loader from "@components/atoms/Loader/Loader";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  title: string;
  id: number;
  updateData: () => void;
  updateUrlData: () => void;
  updatePaging: () => void;
}

const DeleteNew = ({
  isOpenModal,
  setIsOpenModal,
  title,
  id,
  updateData,
  updateUrlData,
  updatePaging,
}: Props) => {
  const { auth } = useContext(AuthContext);

  const [loader, setLoader] = useState(false);

  const onDelete = async (id: number) => {
    const toast = toastLoading("Eliminando noticia...");
    setLoader(true);
    try {
      const result = await deleteNew(id, auth!!.token);
      if (result) {
        toastUpdateLoading(toast, "Noticia eliminada", "success", "bell");
        setIsOpenModal();
        updateData();
        updateUrlData();
        updatePaging();
      }
    } catch (error: any) {
      errorMessage(error.response, toast, [
        {
          status: 400,
          msg: "Error al eliminar noticia!",
        },
      ]);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal
      title={"Eliminar noticia"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      {loader ? (
        <div className="w-full h-40 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center gap-5">
            <div>
              <Span
                text={`Estás a punto de eliminar la noticia \xa0`}
                extraStyles="leading-5"
              />

              <q className="font-bold">
                <Span
                  text={`${title}`}
                  weight="font-semibold"
                  extraStyles="leading-5"
                />
              </q>
            </div>
            <div className="w-auto flex flex-row gap-6">
              <Button
                text="Eliminar"
                type="submit"
                padding="py-2 px-10"
                extraStyles="text-sm md:text-base"
                bgColor="bg-blue-dashboard hover:bg-blue-800"
                onClick={() => {
                  onDelete(id);
                }}
              />
              <Button
                text="Cancelar"
                type="button"
                padding="py-2 px-10"
                extraStyles="text-sm md:text-base"
                bgColor="bg-red-800 hover:bg-red-900"
                onClick={() => {
                  setIsOpenModal();
                }}
              />
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DeleteNew;
