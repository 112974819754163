import { fetching } from "@entities/Fetching.entity";
import { MonthTypes } from "@hooks/Accounting/types";
import { useContext } from "react";
import { MdDashboard } from "react-icons/md";

import { Calendar } from "@components/atoms/Calendar/Calendar";
import Card from "@components/atoms/Card/Card";
import Icon from "@components/atoms/Icons/Icon";
import MonthPicker, {
  months,
} from "@components/atoms/InputFormik/MonthPicker/MonthPicker";
import YearPicker from "@components/atoms/InputFormik/YearPicker/YearPicker";
import Loader from "@components/atoms/Loader/Loader";
import Span from "@components/atoms/Span/Span";
import SpanWithStrong from "@components/atoms/SpanWithStrong/SpanWithStrong";

import BarChartBalance from "@components/molecules/DashboardElement/BarChartBalance";
import BarChartResults from "@components/molecules/DashboardElement/BarChartResults";
import CardsForBalance from "@components/molecules/DashboardElement/CardsForBalance";
import CardsForFinancialIndicator from "@components/molecules/DashboardElement/CardsForFinancialIndicator";
import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

import { AuthContext } from "@providers/providers";

import { useAccounting } from "@hooks/Accounting/useAccounting";

interface Props {
  nit: string;
}

const ClientContent = ({ nit }: Props) => {
  const { auth } = useContext(AuthContext);
  const [
    accounting,
    worksheetsNames,
    fetchingState,
    date,
    month,
    setDate,
    setMonth,
    setBalance,
    setResult,
    getAccountingByMonth,
    getFullYearAccountingByBalance,
    getFullYearResultByResult,
  ] = useAccounting(nit);

  const renderContent = (content: any): JSX.Element | null => {
    return content && content.payload.length > 0 ? (
      <div className="bg-white flex flex-col gap-1 p-2 border-gray-500 border rounded">
        <Span
          weight={"font-semibold"}
          size={"text-xl"}
          text={months[content.payload[0].payload.month - 1].name}
          align={"text-left"}
          extraStyles="capitalize"
        />
        <SpanWithStrong
          spanText={
            isNaN(Number(content.payload[0].payload.value))
              ? "No presentado"
              : `${new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                }).format(content.payload[0].payload.value)}`
          }
          strongText={"Valor: "}
          strongLeft={true}
        />
      </div>
    ) : null;
  };

  const renderDashboard = () => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        return accounting === undefined ||
          accounting === null ? null : getAccountingByMonth() ===
          undefined ? null : (
          <>
            <CardsForBalance
              accounting={accounting}
              asset={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.asset
              }
              liabilities={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.liabilities
              }
              equity={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.equity
              }
              lastAsset={getAccountingByMonth()?.last?.asset}
              lastLiabilities={getAccountingByMonth()?.last?.liabilities}
              lastEquity={getAccountingByMonth()?.last?.equity}
              setBalance={setBalance}
            />

            <BarChartBalance
              accounting={accounting}
              balanceInfo={getFullYearAccountingByBalance()}
              month={month}
              toolTipContent={renderContent}
              setMonth={setMonth}
            />

            <CardsForFinancialIndicator
              netIncome={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.netIncome
              }
              debtEquityRatio={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.debtEquityRatio
              }
              indebtedness={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.indebtedness
              }
              netIncomeIndex={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.netIncomeIndex
              }
              returnOnEquity={
                getAccountingByMonth() === null
                  ? null
                  : getAccountingByMonth()!!.current.returnOnEquity
              }
            />

            <BarChartResults
              accounting={accounting}
              resultInfo={getFullYearResultByResult()}
              income={getAccountingByMonth()?.current.income}
              administrativeExpenses={
                getAccountingByMonth()?.current.administrativeExpenses
              }
              salesCost={getAccountingByMonth()?.current.salesCost}
              productionCost={getAccountingByMonth()?.current.productionCost}
              month={month}
              toolTipContent={renderContent}
              setMonth={setMonth}
              setResult={setResult}
            />
          </>
        );
      case fetching.Error:
        return null;
    }
  };

  const renderYearMonthBar = () => {
    if (accounting === undefined || worksheetsNames.length === 0) {
      if (nit === undefined) return null;
      switch (fetchingState) {
        case fetching.Success:
          return (
            <div className="p-36 flex justify-center items-center flex-col gap-5">
              <MdDashboard size={70} />
              <Span
                text="No se encontro información"
                weight={"font-bold"}
                size={"text-xl"}
              />
            </div>
          );
        case fetching.Error:
          return (
            <div className="p-36 flex justify-center items-center flex-col gap-10">
              <Icon icon={"gear"} size={20} />
              <Span
                text={"Ocurrió un error con el servidor, intenta nuevamente"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        default:
          return null;
      }
    } else {
      return (
        <div className="flex md:gap-10 gap-5 md:flex-row flex-col justify-between">
          <Card extraStyles="w-full">
            <div className="flex flex-row gap-2">
              <Span weight={"font-bold"} text="Año seleccionado: " />
              <YearPicker
                date={date}
                dateAvailables={worksheetsNames}
                setDate={(el: number) => setDate(new Date(el, 0))}
              />
            </div>
          </Card>
          <Card extraStyles="w-full">
            <div className="flex flex-row gap-2">
              <Span weight={"font-bold"} text="Mes seleccionado: " />
              <MonthPicker
                month={month}
                setMonth={(el: number) => setMonth(el as MonthTypes)}
              />
            </div>
          </Card>
        </div>
      );
    }
  };

  return (
    <div className="flex gap-[30px] flex-col">
      {renderYearMonthBar()}

      {renderDashboard()}
      {auth && (
        <InfoContainer title={"Calendario documentos"} iconName={"calendar"}>
          <div className="p-7">
            <Calendar nit={nit} />
          </div>
        </InfoContainer>
      )}
    </div>
  );
};

export default ClientContent;
