import { User } from "@entities/User.entity";

import { deletedDocumentRow } from "@components/molecules/Row/DocumentRow/DeletedDocumentRow";
import { documentRow } from "@components/molecules/Row/DocumentRow/DocumentRow";

export const formatFilesToState = (documentsToFormat: any) => {
  let documentsList: documentRow[] = [];

  documentsToFormat["folders"].forEach((folder: any) => {
    folder.documentType = folder.shared ? "folderShared" : "folderNotShared";
    folder.creationDate = new Date(folder.creationDate)
      .toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
    documentsList.push(folder);
  });

  documentsToFormat["documents"].forEach((document: any) => {
    document.documentType = document.fileId.split(".").pop();
    document.creationDate = new Date(document.creationDate)
      .toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
    document.expirationDate = document.dueDate
      ? new Date(document.dueDate)
          .toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "")
      : "";
    document.seen = document.viewed === 1;
    documentsList.push(document);
  });
  return documentsList;
};

export const formatDeletedFilesToState = (documentsToFormat: any) => {
  let deletedDocumentsList: deletedDocumentRow[] = [];

  documentsToFormat.forEach((document: any) => {
    document.typeFile = document.typeFile.split(".")[1];
    document.deleteDate = new Date(document.deleteDate)
      .toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(",", "");
    deletedDocumentsList.push(document);
  });
  return deletedDocumentsList;
};

export const getFolderPathForCurrentLocation = (
  auth: User | undefined,
  path: string[],
  page: number
) => {
  switch (auth?.type) {
    case "admin":
      if (path.length === 3) {
        return `${path[2]}/folders/root?page=${page}`;
      }
      return `${path[2]}/folders/${path.pop()?.split("-")[1]}?page=${page}`;
    case "accountant":
      if (path.length === 3) {
        return `${path[2]}/folders/root?page=${page}`;
      }
      return `${path[2]}/folders/${path.pop()?.split("-")[1]}?page=${page}`;
    case "client":
      if (path.length === 2) {
        return `${auth?.nit}/folders/root?page=${page}`;
      }
      return `${auth?.nit}/folders/${path.pop()?.split("-")[1]}?page=${page}`;
    default:
      return "";
  }
};

export const getFolderPathForNextFolder = (
  auth: User | undefined,
  id: string,
  path: string[]
) => {
  switch (auth?.type) {
    case "admin":
      return `${path[2]}/folders/${id}?page=1`;
    case "accountant":
      return `${path[2]}/folders/${id}?page=1`;
    case "client":
      return `${auth?.nit}/folders/${id}?page=1`;
    default:
      return "";
  }
};

export const getFolderPathToDeleteDocument = (
  auth: User | undefined,
  id: string,
  path: string[]
) => {
  switch (auth?.type) {
    case "admin":
      if (path.length === 3) {
        return `${path[2]}/folders/root/documents/${id}`;
      }
      return `${path[2]}/folders/${path.pop()?.split("-")[1]}/documents/${id}`;
    case "accountant":
      if (path.length === 3) {
        return `${path[2]}/folders/root/documents/${id}`;
      }
      return `${path[2]}/folders/${path.pop()?.split("-")[1]}/documents/${id}`;
    case "client":
      if (path.length === 2) {
        return `${auth?.nit}/folders/root/documents/${id}`;
      }
      return `${auth?.nit}/folders/${
        path.pop()?.split("-")[1]
      }/documents/${id}`;
    default:
      return "";
  }
};

export const getFolderPathToAddDocument = (
  auth: User | undefined,
  path: string[]
) => {
  switch (auth?.type) {
    case "client":
      if (path.length === 2) {
        return `${auth.nit}/folders/root`;
      }
      return `${auth.nit}/folders/${path.pop()?.split("-")[1]}`;
    default:
      if (path.length === 3) {
        return `${path[2]}/folders/root`;
      }
      return `${path[2]}/folders/${path.pop()?.split("-")[1]}`;
  }
};

export const getFolderPathToEditDocument = (path: string[], id: string) => {
  if (path.length === 3) {
    return `${path[2]}/folders/root/documents/${id}`;
  }
  return `${path[2]}/folders/${path.pop()?.split("-")[1]}/documents/${id}`;
};

export const getFolderPathToEditDocumentStatus = (
  path: string[],
  id: string
) => {
  if (path.length === 3) {
    return `${path[2]}/folders/root/documents/status/${id}`;
  }
  return `${path[2]}/folders/${
    path.pop()?.split("-")[1]
  }/documents/status/${id}`;
};

export const getFolderPathToAddFolder = (path: string[]) => {
  if (path.length === 3) {
    return `${path[2]}/folders`;
  }
  return `${path[2]}/folders`;
};

export const getFolderPathToEditFolder = (path: string[], id: string) => {
  if (path.length === 3) {
    return `${path[2]}/folders/${id}`;
  }
  return `${path[2]}/folders/${id}`;
};

export const getFolderPathForDeletedFiles = (path: string[], page: number) => {
  return `${path[2]}/deleted?page=${page}`;
};

export const getFoldersHistory = (auth: User | undefined, path: string[]) => {
  let history: any[] = [];
  if (auth?.type !== "client") {
    history = path.slice(2).map((i, index) => {
      if (index === 0) {
        return { name: "Inicio", route: path.slice(0, 3).join("/") };
      }
      return {
        name: decodeURI(i.split("-")[0]),
        route: path.slice(0, 3 + index).join("/"),
      };
    });
  } else {
    history = path.slice(1).map((i, index) => {
      if (index === 0) {
        return { name: "Inicio", route: path.slice(0, 2).join("/") };
      }
      return {
        name: decodeURI(i.split("-")[0]),
        route: path.slice(0, 2 + index).join("/"),
      };
    });
  }
  return history;
};
