import { Document } from "@entities/Document.entity";
import { Folder } from "@entities/Folder.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/clients`;

export const getFiles = (route: string, token: string) => {
  return axios.get(`${baseUrl}/${route}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getDeletedFiles = (route: string, token: string) => {
  return axios.get(`${baseUrl}/${route}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addFolder = (
  folderPath: string,
  folderData: Partial<Folder>,
  token: string
) => {
  return axios.post(`${baseUrl}/${folderPath}`, folderData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateFolder = (
  folderPath: string,
  folderData: Partial<Folder>,
  token: string
) => {
  return axios.patch(`${baseUrl}/${folderPath}`, folderData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addDocument = (
  folderPath: string,
  documentData: Partial<Document>,
  token: string
) => {
  return axios.post(`${baseUrl}/${folderPath}/documents`, documentData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteFolder = (folderPath: string, data: any, token: string) => {
  return axios.post(`${baseUrl}/${folderPath}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteDocument = (
  documentPath: string,
  data: any,
  token: string
) => {
  return axios.post(`${baseUrl}/${documentPath}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateDocument = (
  documentPath: string,
  data: Partial<Folder>,
  token: string
) => {
  return axios.patch(`${baseUrl}/${documentPath}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateDocumentStatus = (
  documentPath: string,
  data: { status: string },
  token: string
) => {
  return axios.patch(`${baseUrl}/${documentPath}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTotalDocumentsAndFolders = (path: string, token: string) => {
  return axios.get(`${baseUrl}/${path}/total`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getTotalDeletedClientDocuments = (path: string, token: string) => {
  return axios.get(`${baseUrl}/${path}/total`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getDocumentsBySearch = (
  route: string,
  token: string,
  searchParam: string
) => {
  return axios.get(`${baseUrl}/${route}`, {
    params: {
      search: searchParam,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};
