import { FC, useCallback, useEffect, useState } from "react";

import Span from "../Span/Span";

interface Props {
  time: number;
  onTimeExpired?: () => void;
  reset?: boolean;
}

const TimerClock: FC<Props> = ({ time, onTimeExpired, reset }) => {
  const timePassed = time.toString().length > 1 ? time : `0${time}`;
  const [timer, setTimer] = useState(`${timePassed}:00`);
  const [isTimeOver, setIsTimeOver] = useState(false);

  const getTimeDeadLine = useCallback((): Date => {
    const now = Date.now();
    const future = new Date(now + time * 60 * 1000);

    return future;
  }, [time]);

  const getTimeRemaining = (future: Date) => {
    const now = Date.now();

    // Time in miliseconds
    const difference = future.getTime() - now;

    // Miliseconds to hours
    const hours = Math.floor((difference / (1000 * 60 * 60)) & 24);
    // Miliseconds to minutes
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    // Miliseconds to seconds
    const seconds = Math.floor((difference / 1000) % 60);

    return { difference, hours, minutes, seconds };
  };

  const setTheTimer = (timedeadline: Date) => {
    const { difference, hours, minutes, seconds } =
      getTimeRemaining(timedeadline);

    if (difference >= 0) {
      const minutesToPresent = minutes > 9 ? minutes : "0" + minutes;
      const secondsToPresent = seconds > 9 ? seconds : "0" + seconds;
      const hoursToPresent = hours > 9 ? hours : "0" + hours;

      const timePresent =
        time > 60
          ? hoursToPresent + ":" + minutesToPresent + ":" + secondsToPresent
          : minutesToPresent + ":" + secondsToPresent;
      setTimer(timePresent);
    }
  };

  const startTimer = () => {
    const timedeadline = getTimeDeadLine();
    const id = setInterval(() => {
      setTheTimer(timedeadline);
    }, 1000);

    setTimeout(() => {
      onTimeExpired?.();
      setIsTimeOver(true);
      clearInterval(id);
    }, time * 60000);
  };

  useEffect(() => {
    startTimer();
  }, [reset]);

  return (
    <>
      <div>
        <Span weight="font-bold" text={timer} color={"text-black"} />
      </div>
    </>
  );
};

export default TimerClock;
