import AR from "./AR.svg";
import CL from "./CL.svg";
import CO from "./CO.svg";
import US from "./US.svg";

export const flagNames = {
  ar: {
    name: "Argentina",
    svg: AR,
    prefix: "+54",
  },
  cl: {
    name: "Chile",
    svg: CL,
    prefix: "+56",
  },
  co: {
    name: "Colombia",
    svg: CO,
    prefix: "+57",
  },
  us: {
    name: "Estados Unidos",
    svg: US,
    prefix: "+1",
  },
};

interface Props {
  flag: string;
}

const Flags = ({ flag }: Props) => {
  const selectedFlag = Object.entries(flagNames).filter(
    (fl) => fl[0] === flag
  )[0];
  return (
    <>
      <img src={selectedFlag[1].svg} alt={selectedFlag[1].name} />
    </>
  );
};

export default Flags;
