import { MonthTypes } from "@hooks/Accounting/types";
import { Dropdown } from "flowbite-react";

import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

export const months = [
  { id: 0, name: "Enero" },
  { id: 1, name: "Febrero" },
  { id: 2, name: "Marzo" },
  { id: 3, name: "Abril" },
  { id: 4, name: "Mayo" },
  { id: 5, name: "Junio" },
  { id: 6, name: "Julio" },
  { id: 7, name: "Agosto" },
  { id: 8, name: "Septiembre" },
  { id: 9, name: "Octubre" },
  { id: 10, name: "Noviembre" },
  { id: 11, name: "Diciembre" },
];

interface Props {
  month: MonthTypes;
  setMonth: (el: number) => void;
}

const MonthPicker = ({ month, setMonth }: Props) => {
  const styleSelected = {
    color: "red",
    fontWeight: "bold",
  };

  return (
    <Dropdown
      label={""}
      inline
      style={{
        width: "fit-content",
        border: "1px solid #E5E7EB",
        borderRadius: 8,
      }}
      dismissOnClick={true}
      renderTrigger={() => (
        <div
          style={{
            border: "1px solid #E5E7EB",
            borderRadius: 8,
            padding: "4px 10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "fit-content",
            minWidth: "90px",
          }}
        >
          <Span
            weight={"font-medium"}
            size={"text-sm"}
            text={months[month].name}
          />
          <Icon icon={"caretSort"} size={4} />
        </div>
      )}
    >
      {months.map((monthObj) => {
        return (
          <Dropdown.Item
            key={monthObj.id}
            className="justify-center"
            style={monthObj.id === month ? styleSelected : {}}
            onClick={() => setMonth(monthObj.id)}
          >
            {monthObj.name}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
};

export default MonthPicker;
