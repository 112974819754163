import { Client } from "@entities/Client.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/clients`;

export const getTotalClients = (
  active: boolean,
  token: string,
  accId?: string
) => {
  return axios.get(`${baseUrl}/total`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      q: accId,
      active,
    },
  });
};

export const getAllClients = (
  active: boolean,
  token: string,
  pagination: number,
  accId?: string
) => {
  return axios.get(baseUrl, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      q: accId,
      page: pagination,
      active,
    },
  });
};

export const getClientById = (id: string, token: string) => {
  return axios.get(`${baseUrl}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getClientsLikeQueryParams = (param: string, token: string) => {
  return axios.get(`${baseUrl}/search`, {
    params: {
      q: param,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addClient = (client: Partial<Client>, token: string) => {
  return axios.post(baseUrl, client, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateClient = (
  id: string,
  client: Partial<Client>,
  token: string
) => {
  return axios.patch(`${baseUrl}/${id}`, client, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deactiveClient = (id: string, data: any, token: string) => {
  return axios.post(`${baseUrl}/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
