import { Document } from "@entities/Document.entity";
import { logout } from "@service/Auth.service";
import { deleteDocument } from "@service/Documents.service";
import { removeCookie } from "@utils/Cookie";
import { getFolderPathToDeleteDocument } from "@utils/Documents";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastSuccess, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Lock from "@assets/Icons/Lock.svg";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";
import TitleH2 from "@components/atoms/TitleH2/TitleH2";

import { AuthContext } from "@providers/providers";

import styles from "./DeleteDocument.module.scss";

type Props = {
  document: Pick<Document, "id" | "name" | "type" | "creationDate" | "dueDate">;
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
};

interface DeleteFolderEntity {
  password: "";
}

const DeleteDocument = ({
  document,
  isOpenModal,
  setIsOpenModal,
  updateData,
}: Props) => {
  const location = useLocation();
  const { auth, setAuth } = useContext(AuthContext);

  const initialValues: DeleteFolderEntity = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: DeleteFolderEntity) => {
    const idToast = toastLoading("Eliminando documento...");
    try {
      const { status } = await deleteDocument(
        getFolderPathToDeleteDocument(
          auth,
          document.id,
          location.pathname.split("/")
        ),
        values,
        auth!.token
      );
      if (status === 201) {
        toastUpdateLoading(idToast, "Documento eliminado", "success", "bell");
        if (updateData) updateData();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al eliminar el documento",
        },
      ]);
      if (error.response.status === 401) {
        await logout();
        removeCookie("auth", { path: "/" });
        setAuth(undefined);
      }
    } finally {
      setIsOpenModal();
    }
  };

  return (
    <Modal
      title={"Eliminar documento"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.form}>
              <div className={styles.header}>
                <TitleH2 text={document.name} weight="font-bold" />
                <Span text={`Documento ${document.type}`} weight="font-thin" />
                <Span
                  text={`Subido el ${document.creationDate}`}
                  weight="font-thin"
                />
                <Span
                  text={`Fecha de vencimiento: ${document.dueDate}`}
                  weight="font-thin"
                />
              </div>
              <div className={styles.password}>
                <Span
                  text={"Para continuar escribe tu contraseña"}
                  weight="font-medium"
                />
                <div className={styles.field}>
                  <Input
                    name={"password"}
                    placeholder={"Contraseña"}
                    type={"password"}
                    icon={Lock}
                  />
                </div>
              </div>
              <div className={styles.btnsContainer}>
                <Button
                  height="h-10"
                  text={"Aceptar"}
                  type={"submit"}
                  bgColor="bg-gray-800 hover:bg-gray-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
                <Button
                  height="h-10"
                  text={"Cancelar"}
                  onClick={setIsOpenModal}
                  type={"button"}
                  bgColor="bg-red-800 hover:bg-red-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DeleteDocument;
