import { logout } from "@service/Auth.service";
import { removeCookie } from "@utils/Cookie";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
// import { stopTokenValidationInterval } from "@utils/Token";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Span from "@components/atoms/Span/Span";

import RoleBox from "@components/molecules/RoleBox/RoleBox";
import SideMenuOption from "@components/molecules/SideMenuOption/SideMenuOption";

import { AuthContext } from "@providers/providers";

import styles from "./SideMenu.module.scss";
import SideMenuOptionsByRol from "./SideMenuOptions";

type Props = {};

const SideMenu = ({}: Props) => {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const logoutSession = async () => {
    const idToast = toastLoading("Cerrando sesión...");
    try {
      setAuth(undefined);
      removeCookie("auth", { path: "/" });
      await logout();
      toastUpdateLoading(
        idToast,
        "Sesión cerrada exitosamente",
        "success",
        "bell"
      );
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        { status: 400, msg: "Error al cerrar la sesión" },
      ]);
    }
  };

  return (
    <aside className="sideMenu">
      <RoleBox />
      <div className={styles.menus}>
        <nav className={styles.mainMenu}>
          <div className={styles.title}>
            <Span text="Menu" color={"text-white"} weight={"font-light"} />
          </div>
          <ul>
            {auth &&
              SideMenuOptionsByRol[auth.type].map((el, index) => {
                return (
                  <li key={index}>
                    <SideMenuOption
                      link={el.link}
                      iconName={el.iconName}
                      text={el.text}
                      otherOptions={el.otherOptions}
                    />
                  </li>
                );
              })}
          </ul>
        </nav>
        <nav className={styles.secondMenu}>
          <ul>
            <li>
              <SideMenuOption
                link={"/terms"}
                iconName={"fileLines"}
                text={"Nuestros Términos"}
              />
            </li>
            <li>
              <SideMenuOption
                onPress={() => logoutSession()}
                iconName={"arrowRightBracket"}
                text={"Cerrar Sesión"}
              />
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideMenu;
