import { ErrorMessage, Field } from "formik";
import { FC, useEffect, useState } from "react";

import Flags, { flagNames } from "@assets/Flags/Flags";

import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import styles from "./InputPhoneNumber.module.scss";

interface Props {
  prefix: string;
  name: string;
  type: string;
  placeholder?: string;
  validation?: (value: number) => string | null;
  setValue: (id: string, value: string) => void;
  error?: string;
  touched?: boolean;
}

const InputPhoneNumber: FC<Props> = ({
  prefix,
  name,
  type,
  placeholder,
  setValue,
  validation,
  error,
  touched,
}) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [currentFlags, setCurrentFlags] = useState(flagNames.co);

  useEffect(() => {
    setValue(prefix, currentFlags.prefix);
  }, [currentFlags, setCurrentFlags]);

  return (
    <>
      <div>
        <Span weight={"font-bold"} size={"text-base"} text={"Teléfono"} />
      </div>
      <div className={styles.inputPhoneContainer}>
        <div className={styles.inputsContainer}>
          <div
            className={styles.dropdown}
            style={{ borderBottomLeftRadius: isDropdown ? "0" : "0.5rem" }}
          >
            <div className={styles.prefixContainer}>
              <div
                onClick={() => setIsDropdown(!isDropdown)}
                className={styles.dropdown_header}
              >
                <img src={currentFlags.svg} alt={currentFlags.name} />
                <Span
                  text={currentFlags.name}
                  size="text-lg"
                  weight="font-bold"
                />
                <Icon icon={"arrowDown"} size={3} />
              </div>
              <div className={styles.prefix_header}>
                <Span text={currentFlags.prefix} color="text-gray-500" />
              </div>
            </div>
            <div
              className={styles.dropdown_content}
              style={{
                height: isDropdown ? "181px" : "0",
                border: isDropdown ? "1px solid #d1d5db" : "0",
              }}
            >
              <ul>
                {Object.entries(flagNames).map((flag) => {
                  return (
                    <li
                      key={flag[0]}
                      onClick={() => {
                        setCurrentFlags(flag[1]);
                        setIsDropdown(!isDropdown);
                      }}
                    >
                      <Flags flag={flag[0]} />
                      <Span
                        text={flag[1].name}
                        size="text-base"
                        weight="font-bold"
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <Field
            type={type}
            id={name}
            name={name}
            style={{ width: "95%" }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-3.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={placeholder}
            validate={validation}
          />
        </div>
        <div className="h-5 mt-2">
          {touched && error ? (
            <div className="text-red-500 text-sm">{error}</div>
          ) : (
            <ErrorMessage name={name}>
              {(msg: string) => (
                <div className="text-red-500 text-sm">{msg}</div>
              )}
            </ErrorMessage>
          )}
        </div>
      </div>
    </>
  );
};

export default InputPhoneNumber;
