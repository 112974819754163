import Span from "@components/atoms/Span/Span";
import Text from "@components/atoms/Text/Text";
import TitleH3 from "@components/atoms/TitleH3/TitleH3";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

type Props = {};

const TermsSection = (props: Props) => {
  interface Context {
    title: string;
    text?: string;
    subtext?: string[];
    subtext2?: string[];
    end?: string[];
  }
  const Section = ({ title, text, subtext, subtext2, end }: Context) => {
    return (
      <div className="flex flex-col justify-center items-start gap-1">
        {title ? (
          <TitleH3
            text={title}
            color="text-gray-700"
            size="text-base"
            weight="font-semibold"
          />
        ) : null}
        {text && (
          <div className="pl-2">
            <Text
              text={text}
              size="text-sm lg:text-base"
              color="text-gray-700"
              align="text-justify"
            />
          </div>
        )}
        {subtext && (
          <ol className="list-decimal tabular-nums list-inside pl-2 flex flex-col gap-2">
            {subtext.map((value: string, index: number) => (
              <li
                className="text-gray-700 font-semibold text-sm lg:text-base text-justify"
                key={index}
              >
                <Span
                  text={value}
                  size="text-sm lg:text-base"
                  color="text-gray-700"
                  align="text-justify"
                />
              </li>
            ))}
          </ol>
        )}
        {subtext2 && (
          <ul className="list-disc pl-6 flex flex-col gap-2">
            {subtext2.map((value: string, index: number) => (
              <li
                className="text-gray-700 font-semibold text-sm lg:text-base"
                key={index}
              >
                <Text
                  text={value}
                  size="text-sm lg:text-base"
                  color="text-gray-700"
                  align="text-justify"
                />
              </li>
            ))}
          </ul>
        )}
        {end && (
          <div className="w-full flex flex-col justify-center gap-1 pt-3">
            {end.map((value: string, index: number) => (
              <Span key={index} text={value} size="text-sm lg:text-base" />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <InfoContainer
      iconSize={6}
      iconColor={"white"}
      iconName={"fileLines"}
      title={"Términos y Condiciones"}
      titleColor={"text-white"}
      backgroundColor={"#25253f"}
    >
      <div className="w-full flex flex-col p-6 gap-4 pl-4">
        {Section({
          title: "Objeto",
          text: "Establecer los lineamientos que regulan la protección de la información de todos los clientes de GlobalAccounters que hacen uso de esta plataforma, garantizando así el cumplimiento de la ley 1581 del 2012 de protección de datos.",
        })}
        {Section({
          title: "Alcance",
          text: "Aplica para todos los colaboradores y contratistas que, al utilizar los servicios proporcionados por la plataforma, tengan acceso a la información que se debe proteger bajo la ley 1581 del 2012 y lo cual será de obligatorio cumplimiento brindar la debida protección a los intereses y necesidades de los titulares de la información personal tratada por GLOBAL ACCOUNTERS SAS.",
        })}
        {Section({
          title: "Lineamientos",
          subtext: [
            "Es responsabilidad de GLOBAL ACCOUNTERS S.A.S. designar el oficial y/o líder de administrar la protección de datos.",
            "La política global de privacidad y procedimientos asociados son aprobados por Gerencia.",
            "La política global de privacidad deberá ser accesible por todos los stakeholders de modo que todos puedan tener clara la información descrita en ella.",
            "Toda la información recibida por los usuarios deberá contar con el correspondiente consentimiento para la administración y tratamiento de datos.",
            "Es responsabilidad de GLOBAL ACCOUNTERS S.A.S. establecer las líneas y controles de supervisión frente al cumplimiento en la protección de datos, así como la frecuencia de los controles.",
            "Los usuarios de la plataforma de GLOBAL ACCOUNTERS S.A.S. recibirán información y capacitación orientada a la administración y protección de sus datos, así como en la Ley 1581 del 2012, durante su interacción con la plataforma.",
            "Ningún colaborador podrá enviar, entregar y/o publicar información sin la correspondiente autorización de GLOBAL ACCOUNTERS S.A.S. Realizar cualquiera de las anteriores acciones, se considera como una falta grave.",
            "Es responsabilidad de GLOBAL ACCOUNTERS S.A.S. atender los requerimientos e inquietudes tanto de sus colaboradores como de sus contratistas frente al tratamiento de datos, en el menor tiempo posible.",
            "Los cambios en la información deberán tener un procedimiento que involucre y deje registro de la evidencia de las dos partes.",
            "Se deberá aprobar por parte de GLOBAL ACCOUNTERS S.A.S., el procedimiento para la destrucción de datos, así como para aplicar el derecho al olvido, de las personas que han registrado sus datos en nuestra plataforma.",
            "La organización contará con el acompañamiento de un experto en administración y protección de datos.",
          ],
        })}
        {Section({
          title: "Otras Disposiciones",
          subtext2: [
            "Cualquier modificación de la presente política, será aprobada por gerencia.",
            "Las aclaraciones de interpretación sobre la presente política serán resueltas por gerencia.",
            "Entra en vigor la presente política a partir del 26 de enero del 2024",
            "El documento original de la presente política se encuentra suscrito por:",
          ],
        })}
        <div className="pt-6">
          {Section({
            title: "",
            end: [
              "LIZETH PAOLA GUEVARA GONZALEZ",
              "GLOBAL ACCOUNTERS SAS",
              "GERENTE",
            ],
          })}
        </div>
      </div>
    </InfoContainer>
  );
};

export default TermsSection;
