import { fetching } from "@entities/Fetching.entity";
import { FC, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Pagination from "@components/atoms/Pagination/Pagination";
import Span from "@components/atoms/Span/Span";

import CustomTable from "@components/molecules/CustomTable/CustomTable";
import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";
import PQRsRowAccountant from "@components/molecules/Row/PQRsRow/PQRsRowAccountant";
import PQRsRowAdmin from "@components/molecules/Row/PQRsRow/PQRsRowAdmin";
import PQRsRowClient from "@components/molecules/Row/PQRsRow/PQRsRowClient";

import { AuthContext } from "@providers/providers";

import { usePqrs, useTotalPqrs } from "@hooks/Pqrs/usePqrs";

import styles from "./PQRsTable.module.scss";

type Props = {};

const PQRsTable = (props: Props) => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [pqrsTableProps, setPqrsTableProps] = useState<{
    columns: string[];
    row: any;
  }>({
    columns: ["ID", "TÍTULO Y FECHA", "CLIENTE", "CONTADOR", "ESTADO"],
    row: PQRsRowAdmin,
  });

  const [
    pqrs,
    pqrsType,
    setPqrsType,
    fetchingState,
    pagination,
    setPagination,
  ] = usePqrs();

  const [totalPqrs] = useTotalPqrs(pqrsType);

  const renderPqrs = (): JSX.Element => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (pqrs?.length === 0) {
          return (
            <div
              className={"flex justify-center items-center flex-col gap-2 p-36"}
            >
              <Icon icon={"inbox"} size={20} />
              <Span
                text={"No se encontró ninguna PQRS"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <CustomTable
            columns={pqrsTableProps.columns}
            Row={pqrsTableProps.row}
            data={location.pathname === "/pqrs" ? pqrs : pqrs?.slice(0, 5)}
          />
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  const getPqrsTableType = () => {
    let tableProps: { columns: string[]; row: FC<any> } = {
      columns: ["ID", "TÍTULO", "CLIENTE", "CONTADOR", "ESTADO"],
      row: PQRsRowAdmin,
    };
    switch (auth?.type) {
      case "admin":
        tableProps.columns = ["ID", "TÍTULO", "CLIENTE", "CONTADOR", "ESTADO"];
        tableProps.row = PQRsRowAdmin;
        break;
      case "accountant":
        tableProps.columns = ["ID", "TÍTULO", "CLIENTE", "ESTADO"];
        tableProps.row = PQRsRowAccountant;
        break;
      case "client":
        tableProps.columns = ["ID", "TÍTULO", "ESTADO"];
        tableProps.row = PQRsRowClient;
        break;
      default:
        break;
    }
    setPqrsTableProps(tableProps);
  };

  useEffect(() => {
    if (auth) {
      getPqrsTableType();
    }
  }, []);

  return (
    <section className={styles.pqrsTable}>
      <InfoContainer title={"PQRs"} iconName={"headset"}>
        <div className={styles.topBar}>
          <button
            onClick={() => setPqrsType("peticion")}
            style={{
              backgroundColor:
                pqrsType === "peticion" ? "var(--blue-deep)" : "#54546b",
            }}
          >
            PETICIONES
          </button>
          <button 
            onClick={() => setPqrsType("queja")}
            style={{
              backgroundColor:
                pqrsType === "queja" ? "var(--blue-deep)" : "#54546b",
            }}
          >
            QUEJAS
          </button>
          <button
            onClick={() => setPqrsType("reclamo")}
            style={{
              backgroundColor:
                pqrsType === "reclamo" ? "var(--blue-deep)" : "#54546b",
            }}
          >
            RECLAMOS
          </button>
          <button
            onClick={() => setPqrsType("sugerencia")}
            style={{
              backgroundColor:
                pqrsType === "sugerencia" ? "var(--blue-deep)" : "#54546b",
            }}
          >
            SUGERENCIAS
          </button>
        </div>
        {renderPqrs()}
        {location.pathname === "/pqrs" && totalPqrs > 50 && (
          <Pagination
            currentPage={pagination}
            setCurrentPage={setPagination}
            totalPages={Math.ceil(totalPqrs / 50)}
          />
        )}
      </InfoContainer>
      {location.pathname !== "/pqrs" ? (
        <Button
          width="w-28"
          height="h-10"
          text={"Ver todas"}
          type={"submit"}
          textColor="text-blue-900 hover:text-white"
          bgColor="bg-white hover:bg-blue-900"
          border="border-2 border-solid border-blue-900 rounded-xl"
          onClick={() => navigate("/pqrs", { state: { pqrsType } })}
          extraStyles="mx-auto my-10"
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default PQRsTable;
