import { deleteEventById } from "@service/Events.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import React, { useContext } from "react";

import Button from "@components/atoms/Button/Button";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

interface Props {
  docInfo: any;
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateEvents: () => void;
}

const EventView = ({
  docInfo,
  isOpenModal,
  setIsOpenModal,
  updateEvents,
}: Props) => {
  const { auth } = useContext(AuthContext);

  const onDelete = async () => {
    const idToast = toastLoading("Eliminando evento...");
    try {
      const result = await deleteEventById(
        docInfo.clientId,
        docInfo.eventId,
        auth!!.token
      );
      if (result) {
        toastUpdateLoading(idToast, "Evento eliminado", "success", "bell");
        await updateEvents();
        setIsOpenModal();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al eliminar evento",
        },
      ]);
    }
  };

  return (
    <Modal
      title={docInfo ? docInfo.name : ""}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      bgColor="#009688"
      textColor={"white"}
    >
      <p>{docInfo ? docInfo.description : ""}</p>
      <Span text={"Duración: "} weight={"font-bold"} />
      <Span text={`${docInfo ? docInfo.hours : ""} hora/s`} />
      <div className="flex justify-center items-center">
        <Button
          onClick={onDelete}
          text={"Eliminar"}
          type={"button"}
          width={"w-[170px]"}
          padding={"p-2"}
          bgColor="bg-red-800 hover:bg-red-900"
        />
      </div>
    </Modal>
  );
};

export default EventView;
