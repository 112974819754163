import { Pqrs } from "@entities/Pqrs.entity";
import { Table } from "flowbite-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

interface Props {
  data: Pqrs;
}

const PQRsRowClient: FC<Props> = ({ data }: Props) => {
  const navigate = useNavigate();

  const redirectToDetails = (id: string) => {
    navigate(`/pqrs/${id}`);
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
  };

  return (
    <Table.Row className="lg:h-[120px] h-[100px] bg-white dark:border-gray-700 dark:bg-gray-800 font-inter text-black border-y-[1px] border-solid border-gray-300 w-6">
      <Table.Cell className="w-[2%] font-light text-center">
        {data.id}
      </Table.Cell>
      <Table.Cell className="w-[440px] md:w-[610px] lg:w-[590px]">
        <Span
          text={data.title}
          weight="font-normal"
          color="text-blue-600"
          size="text-base"
          extraStyles="line-clamp-1"
          align={"text-left"}
        />
        <Span
          text={new Date(data.creationDate).toLocaleDateString(
            "es-co",
            dateOptions
          )}
          weight={"font-light"}
          size="text-base"
        />
      </Table.Cell>
      <Table.Cell className="w-[20%] font-normal">
        <div className="flex justify-center items-center text-base gap-12">
          <div className="w-[30%] flex flex-col gap-5 justify-center items-center">
            <div
              className={`${
                data.status === "abierta" ? "bg-teal-200" : "bg-red-200"
              } p-2 rounded-lg capitalize text-center w-[100px]`}
            >
              <Span
                text={data.status ? data.status : "abierta"}
                weight={"font-bold"}
                size="text-base"
                color={
                  data.status === "abierta" ? "text-blue-600" : "text-red-600"
                }
              />
            </div>
            <Button
              text={"Ver"}
              type={"button"}
              width="w-auto"
              padding="py-1.5"
              extraStyles="rounded-[7px] text-xs px-5"
              onClick={() => redirectToDetails(data.id.toString())}
            />
          </div>
          <div onClick={() => redirectToDetails(data.id.toString())}>
            {data.updatedByAccountant ? (
              <div className="relative">
                <div className="absolute w-2.5 h-2.5 rounded-full bg-red-600 top-0 right-0.5"></div>
                <Icon icon={"bellOutline"} size={7} />
              </div>
            ) : (
              <Icon icon={"bellOutline"} color="#9CA3AF" size={7} />
            )}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default PQRsRowClient;
