import { Table } from "flowbite-react";
import React from "react";

import Span from "@components/atoms/Span/Span";

import { DOCUMENT_TYPES, documentRow } from "./DocumentRow";

type Props = {
  data: documentRow;
};

const MinifiedDocumentRow = ({ data }: Props) => {
  return (
    <Table.Row className="relative h-[66px] border-y-[1px] border-solid border-gray-300 text-base">
      <Table.Cell className="flex items-center gap-5 h-[66px]">
        <div
          className="flex gap-3 hover:underline cursor-pointer"
          title={data.name}
        >
          <img src={DOCUMENT_TYPES[data.documentType].icon} alt="" />
          <Span
            text={data.name}
            size="text-base"
            align="text-left"
            extraStyles="w-72 truncate"
          />
        </div>
      </Table.Cell>
      <Table.Cell className="">
        <Span
          text={DOCUMENT_TYPES[data.documentType].title}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
      <Table.Cell className="">
        <Span
          text={data.expirationDate}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default MinifiedDocumentRow;
