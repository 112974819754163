import { Accountant } from "@entities/Accountant.entity";
import { fetching } from "@entities/Fetching.entity";
import { FC, useState } from "react";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Pagination from "@components/atoms/Pagination/Pagination";
import Span from "@components/atoms/Span/Span";

import AccountantCard from "@components/molecules/AccountantCard/AccountantCard";
import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";
import SearchBar from "@components/molecules/SearchBar/SearchBar";

import {
  useAccountants,
  useAccountantsByParams,
} from "@hooks/Accountants/useAccountants";

import AddAccountant from "../Add/AddAccountant/AddAccountant";
import styles from "./AccountantsCollection.module.scss";

interface Props {}

const AccountantsCollection: FC<Props> = () => {
  const [
    accountants,
    totalAccountants,
    active,
    fetchingState,
    pagination,
    setPagination,
    updateData,
    setActive,
  ] = useAccountants();
  const [accsFound, accToSearch, setAccToSearch, getSearchAccountants] =
    useAccountantsByParams();
  const [openModal, setOpenModal] = useState(false);

  const renderAccountants = (): JSX.Element | JSX.Element[] => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        let accs: Accountant[] = [];
        if (accsFound) {
          accs = accsFound;
        } else if (accountants) {
          accs = accountants;
        }

        if (accs.length > 0) {
          return accs.map((acc) => {
            return (
              <AccountantCard
                active={acc.active}
                key={acc.id}
                id={acc.id as number}
                img={acc.profilePicture}
                name={`${acc.names} ${acc.surnames}`}
                email={acc.email}
                phoneNumber={acc.phoneNumber}
                updateData={updateData}
              />
            );
          });
        } else {
          return (
            <div className={`${styles.accountantInfo} p-36`}>
              <Icon icon={"userCicle"} size={20} />
              <Span
                text={"No se encontró a ningún contador"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <InfoContainer
          iconSize={6}
          iconColor={"white"}
          iconName={"user"}
          title={"Contadores"}
          titleColor={"text-white"}
          backgroundColor={"#25253f"}
        >
          <div style={{ padding: "5px 0" }}>
            <SearchBar
              searchValue={accToSearch}
              setSearchValue={setAccToSearch}
              handleSearch={getSearchAccountants}
              setOpenModal={setOpenModal}
              openModal={openModal}
              buttonIcon={"addUser"}
              buttonText={"Añadir contador"}
            />
            <div className="flex justify-center md:justify-end pr-[0px] md:pr-[15px]">
              <Button
                text={`Ver ${!active ? "habilitados " : "deshabilitados"}`}
                type={"button"}
                onClick={() => setActive(!active)}
                iconName={!active ? "eye" : "eyeSlash"}
                iconColor={"blue-600"}
                iconSize={4}
                width="w-[170px]"
                height="h-9"
                textColor="text-gray-800"
                bgColor={`${
                  !active
                    ? "bg-gray-800 hover:bg-gray-900"
                    : "bg-white hover:bg-gray-50"
                }`}
                padding="px-3 py-1"
                extraStyles={`${
                  !active
                    ? "border-0 text-white"
                    : "text-gray-600 border-2 border-gray-600 border-solid"
                } text-xs rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300`}
              />
            </div>
          </div>

          <div className={styles.accountantCards}>{renderAccountants()}</div>
          {((accsFound && accsFound.length > 20) || totalAccountants > 20) && (
            <Pagination
              currentPage={pagination}
              setCurrentPage={setPagination}
              totalPages={Math.ceil(
                accsFound ? accsFound.length / 20 : totalAccountants / 20
              )}
            />
          )}
        </InfoContainer>
      </div>
      <AddAccountant
        isOpenModal={openModal}
        setIsOpenModal={() => setOpenModal(!openModal)}
        updateData={updateData}
      />
    </>
  );
};

export default AccountantsCollection;
