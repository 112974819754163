import { IWorksheetsNames } from "@hooks/Accounting/types";
import { Dropdown } from "flowbite-react";
import { useCallback, useState } from "react";

import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import styles from "./YearPicker.module.scss";

interface Props {
  date: Date;
  dateAvailables?: IWorksheetsNames[];
  setDate: (el: number) => void;
}

const YearPicker = ({ date, dateAvailables, setDate }: Props) => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [dismiss, setDismiss] = useState<boolean>(true);

  const calculateYears = useCallback((): number[] => {
    const dates = [];
    if (dateAvailables) {
      dates.push(...dateAvailables.map((el) => el.year));
    } else {
      for (let i = -3; i <= 3; i++) {
        dates.push(year + i);
      }
    }

    return dates;
  }, [year, setYear]);

  const styleSelected = {
    color: "red",
    fontWeight: "bold",
  };

  return (
    <Dropdown
      label={""}
      inline
      style={{
        width: "fit-content",
        border: "1px solid #E5E7EB",
        borderRadius: 8,
      }}
      dismissOnClick={dismiss}
      renderTrigger={() => (
        <div
          style={{
            border: "1px solid #E5E7EB",
            borderRadius: 8,
            padding: "4px 10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "fit-content",
            minWidth: "90px",
          }}
        >
          <Span
            weight={"font-medium"}
            size={"text-sm"}
            text={`${date.getFullYear()}`}
          />
          <Icon icon={"caretSort"} size={4} />
        </div>
      )}
    >
      {calculateYears().length >= 7 && (
        <>
          <div className={styles.iconContainer}>
            <Dropdown.Item
              style={{ padding: "4px 7px" }}
              onMouseOver={() => setDismiss(false)}
              onMouseOut={() => setDismiss(true)}
              onClick={() => setYear(year - 7)}
            >
              <Icon icon="arrowLeft" size={3} />
            </Dropdown.Item>
            <Dropdown.Item
              style={{ padding: "4px 7px" }}
              onMouseOver={() => setDismiss(false)}
              onMouseOut={() => setDismiss(true)}
              onClick={() => setYear(year + 7)}
            >
              <Icon icon="arrowRight" size={3} />
            </Dropdown.Item>
          </div>
          <Dropdown.Divider />
        </>
      )}

      {calculateYears().map((el, index) => {
        return (
          <Dropdown.Item
            key={index}
            className="justify-center"
            style={date.getFullYear() === el ? styleSelected : {}}
            onClick={() => setDate(el)}
          >
            {el}
          </Dropdown.Item>
        );
      })}
    </Dropdown>
  );
};

export default YearPicker;
