import SearchAccountantCard from "@components/molecules/SearchAccountantCard/SearchAccountantCard";

interface Props {
  val: string;
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  error: string | undefined;
}

const AccountantForm = ({ val, setValue, error }: Props) => {
  const setFieldValue = (value: number | string) => {
    setValue("accId", value);
  };

  return (
    <>
      <div className="w-full">
        <SearchAccountantCard
          accountantId={parseInt(val)}
          setValue={setFieldValue}
          error={error}
          onlySearchBar={false}
        />
      </div>
    </>
  );
};

export default AccountantForm;
