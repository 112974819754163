const ValidationError = {
  CC: {
    es: "La C.C. ingresada no es de Colombia",
    en: "C.C. is not from Colombia",
  },
  NIT: {
    es: "El NIT ingresado no es de Colombia",
    en: "NIT is not from colombia",
  },
  Email: {
    es: "El email ingresado no es del dominio de GA",
    en: "Email is not GA domain!",
  },
};

export const getTranslation = (msg: string, language: "es" | "en") => {
  return Object.entries(ValidationError).filter(
    ([key, val]) => msg.includes(key) && val
  )[0][1][language];
};
