import Input from "@components/atoms/InputFormik/Input/Input";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import Textarea from "@components/atoms/InputFormik/Textarea/Textarea";
import Span from "@components/atoms/Span/Span";
import TitleH3 from "@components/atoms/TitleH3/TitleH3";

interface AddNewEntity {
  url?: string;
  title?: string;
  description?: string;
  img?: File | undefined;
}

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: AddNewEntity;
}

const NewDataForm = ({ setFieldValue, values }: Props) => {
  return (
    <div className="w-full flex flex-col items-start justify-start">
      <Span text="Título de la noticia" weight="font-bold" size="text-base" />
      <Input name={"title"} placeholder={""} type="text" />
      <Span text="Descripción" weight="font-bold" size="text-base" />
      <Textarea name={"description"} placeholder={""} />
      <div>
        <Span text="Foto " weight="font-bold" size="text-base" />
        <Span text="(Opcional)" weight="font-light" size="text-base" />
      </div>
      <InputFile
        label=""
        value={values.img?.name}
        setValue={setFieldValue}
        id={"img"}
        accept={".jpg, .png"}
      />
    </div>
  );
};

export default NewDataForm;
