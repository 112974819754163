import Icon, { IconNames } from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import styles from "./TableTitle.module.scss";

type Props = {
  icon: IconNames;
  title: string;
};

const TableTitle = ({ icon, title }: Props) => {
  return (
    <div className={styles.tableTitle}>
      <Icon icon={icon} color="white" />
      <Span text={title} color="text-white" />
    </div>
  );
};

export default TableTitle;
