import { FC } from "react";

import InputRecovery from "@components/atoms/InputFormik/InputRecovery/InputRecovery";
import Span from "@components/atoms/Span/Span";

import styles from "./RecoveryField.module.scss";

interface Props {
  initialValues: object;
  setFieldValue: (field: string, val: any) => Promise<void | any>;
  error: string | undefined;
}

const RecoveryField: FC<Props> = ({ initialValues, error, setFieldValue }) => {
  return (
    <>
      <div className={styles.RecoveryFieldContainer}>
        <div className={styles.RecoveryField}>
          {Object.keys(initialValues).map((key) => {
            const setValue = (value: string) => {
              setFieldValue(key, value);
              const nextIndex = Object.keys(initialValues).indexOf(key) + 1;
              const previousIndex = Object.keys(initialValues).indexOf(key) - 1;
              if (value.length === 1)
                document
                  .getElementById(Object.keys(initialValues)[nextIndex])
                  ?.focus();
              else if (value.length === 0)
                document
                  .getElementById(Object.keys(initialValues)[previousIndex])
                  ?.focus();
            };

            return (
              <InputRecovery
                isError={false}
                key={key}
                name={key}
                type={"number"}
                placeholder={"0"}
                onChange={setValue}
              />
            );
          })}
        </div>
        <Span
          size={"text-sm"}
          color={"text-red-600"}
          weight={"font-bold"}
          text={error ? error : ""}
        />
      </div>
    </>
  );
};

export default RecoveryField;
