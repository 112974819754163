import React from "react";

import ClientsTemplate from "@templates/Clients/Clients";

type Props = {};

const Clients = (props: Props) => {
  return <ClientsTemplate />;
};

export default Clients;
