import { memo } from "react";

import Button from "@components/atoms/Button/Button";
import { IconNames } from "@components/atoms/Icons/Icon";
import SearchInput from "@components/atoms/SearchInput/SearchInput";

import styles from "./SearchBar.module.scss";

type Props = {
  searchValue: string;
  setSearchValue: (val: string) => void;
  handleSearch: () => void;
  setOpenModal: (modal: boolean) => void;
  openModal: boolean;
  buttonIcon: IconNames;
  buttonText?: string;
  searchBarBtn?: boolean;
};

const SearchBar = ({
  searchValue,
  setSearchValue,
  handleSearch,
  setOpenModal,
  openModal,
  buttonIcon,
  buttonText,
  searchBarBtn = true,
}: Props) => {
  return (
    <div
      className={`${styles.searchBarContainer} flex-col gap-3 md:flex-row w-full`}
    >
      <div className={`w-full ${buttonText ? "md:w-[70%]" : ""}`}>
        <SearchInput
          value={searchValue}
          setValue={setSearchValue}
          handleSearch={handleSearch}
          withBtn={searchBarBtn}
        />
      </div>
      {buttonText && (
        <div className={styles.addBtn}>
          <Button
            text={buttonText}
            type={"button"}
            onClick={() => setOpenModal(!openModal)}
            iconName={buttonIcon}
            iconColor={"white"}
            iconSize={4}
            width="w-18"
            height="h-9"
            bgColor="bg-gray-800 hover:bg-gray-900"
            padding="px-3 py-1"
            extraStyles="text-xs rounded-[6px] font-extralight focus:ring-4 focus:outline-none focus:ring-gray-300"
          />
        </div>
      )}
    </div>
  );
};

export default memo(SearchBar);
