import Icon, { IconNames } from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import styles from "./StatisticCard.module.scss";

interface Props {
  statName: string;
  statValue: string;
  borderColor: string;
  iconColor?: string;
  iconSize?: number;
  iconName: IconNames;
}

const StatisticCard = ({
  statName,
  statValue,
  borderColor,
  iconColor,
  iconName,
  iconSize,
}: Props) => {
  return (
    <div
      className={`${styles.statisticCard} ${borderColor} border-l-[6px] border-solid drop-shadow`}
    >
      <div className={styles.data}>
        <div className={styles.statName}>
        <Span text={statName} weight={"font-normal"} size="text-xl" />
        </div>
        <div className={styles.statValue}>
        <Span
          text={statValue}
          weight={"font-bold"}
          color={"black-full"}
          size="text-4xl"
        />
        </div>
      </div>

      <div className={styles.image}>
        <Icon icon={iconName} color={iconColor} size={iconSize} />
      </div>
    </div>
  );
};

export default StatisticCard;
