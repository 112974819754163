import {
  validateEmailDomain,
  validateLength,
  validateNIT,
} from "@utils/Validations";

import Input from "@components/atoms/InputFormik/Input/Input";
import InputPhoneNumber from "@components/atoms/InputFormik/InputPhoneNumber/InputPhoneNumber";
import Span from "@components/atoms/Span/Span";

import styles from "./AddClient.module.scss";

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  errors: {
    CNError: string | undefined;
    CSError: string | undefined;
    CNTError: string | undefined;
    CEError: string | undefined;
    CPNError: string | undefined;
    CPError: string | undefined;
  };
  touched: any;
}

const ContactForm = ({ setFieldValue, errors, touched }: Props) => {
  return (
    <>
      <div className={styles.formField}>
        <Span weight={"font-bold"} size={"text-base"} text={"Nombres"} />
        <Input
          name={"names"}
          type={"text"}
          error={errors.CNError}
          touched={touched.names}
        />
      </div>
      <div className={styles.formField}>
        <Span weight={"font-bold"} size={"text-base"} text={"Apellidos"} />
        <Input
          name={"surnames"}
          type={"text"}
          error={errors.CSError}
          touched={touched.surnames}
        />
      </div>
      <div className={styles.formField}>
        <Span weight={"font-bold"} size={"text-base"} text={"NIT"} />
        <Input
          name={"nit"}
          type={"text"}
          error={errors.CNTError}
          validation={validateNIT}
          touched={touched.nit}
        />
      </div>
      <div className={styles.formField}>
        <Span weight={"font-bold"} size={"text-base"} text={"Email"} />
        <Input
          name={"email"}
          type={"email"}
          error={errors.CEError}
          touched={touched.email}
        />
      </div>
      <InputPhoneNumber
        prefix={"prefix"}
        name={"phoneNumber"}
        type={"number"}
        setValue={setFieldValue}
        validation={validateLength}
        error={errors.CPNError}
        touched={touched.phoneNumber}
      />
      <div className={styles.formField}>
        <Span weight={"font-bold"} size={"text-base"} text={"Cargo"} />
        <Input
          name={"rol"}
          type={"text"}
          error={errors.CPError}
          touched={touched.rol}
        />
      </div>
    </>
  );
};

export default ContactForm;
