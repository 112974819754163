import { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  link?: string;
  handleFunction?: () => void;
}

const Anchor: FC<Props> = ({ label, link = "/", handleFunction }) => {
  return (
    <Link
      to={link}
      onClick={handleFunction}
      className="text-base font-extralight text-blue-900 dark:text-blue-900 hover:underline"
    >
      {label}
    </Link>
  );
};

export default Anchor;
