import { MonthTypes } from "@hooks/Accounting/types";
import { useState } from "react";
import {
  Bar,
  BarChart as BarC,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface Props {
  data: object[];
  indexHover?: MonthTypes;
  width?: number;
  height?: number;
  contentTooltip?: (content: any) => JSX.Element | null;
  onPressCell?: (content: any) => void;
}

const BarChart = ({
  data,
  indexHover,
  width = 1000,
  height = 500,
  contentTooltip,
  onPressCell,
}: Props) => {
  const [focusBar, setFocusBar] = useState<number | null | undefined>(null);

  return (
    <ResponsiveContainer height={height} width={"100%"}>
      <BarC
        width={width}
        height={height}
        data={data}
        onClick={onPressCell}
        style={{ padding: "6px 0" }}
      >
        <CartesianGrid strokeDasharray="1" vertical={false} />
        <XAxis dataKey="month" />
        <YAxis
          tickFormatter={(value) => {
            return new Intl.NumberFormat("es-CO", {
              notation: "compact",
              compactDisplay: "short",
            }).format(value);
          }}
        />
        <Tooltip
          cursor={false}
          content={focusBar === undefined ? <></> : contentTooltip}
        />
        <Bar dataKey="value">
          {data.map((_, index) => {
            return (
              <Cell
                key={`cell-${index}`}
                onMouseOver={() => setFocusBar(index)}
                onMouseOut={() => setFocusBar(null)}
                style={{ cursor: "pointer" }}
                fill={
                  focusBar
                    ? indexHover === index
                      ? "#27d7f9"
                      : focusBar === index
                      ? "#42389D"
                      : "#25253f"
                    : indexHover === index
                    ? "#27d7f9"
                    : "#25253f"
                }
              />
            );
          })}
        </Bar>
      </BarC>
    </ResponsiveContainer>
  );
};

export default BarChart;
