import { toastSuccess } from "@utils/Toast";
import { CSSProperties } from "react";

import Button from "@components/atoms/Button/Button";
import { IconNames } from "@components/atoms/Icons/Icon";

import styles from "./FloatMenu.module.scss";

export interface ButtonInfo {
  icon: IconNames;
  text: string;
  extraStyles: string | undefined;
  onPress: () => void;
}

type Props = {
  isOpen: boolean;
  optionsFM?: ButtonInfo[];
  extraS?: CSSProperties;
};

const FloatMenu = ({ isOpen, optionsFM, extraS }: Props) => {
  const options: ButtonInfo[] = optionsFM
    ? optionsFM
    : [
        {
          icon: "editDoc",
          text: "Editar",
          extraStyles: undefined,
          onPress: () => toastSuccess("Funciona", "eye"),
        },
        {
          icon: "trash",
          text: "Eliminar",
          extraStyles: "hover:bg-red-200 hover:rounded-sm",
          onPress: () => toastSuccess("Funciona", "eye"),
        },
      ];

  return (
    <div
      className={`${styles.floatMenu} ${
        isOpen ? styles.modalOpen : styles.modalClosed
      }
      w-48 text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white p-1 absolute right-0`}
      style={{
        ...extraS,
      }}
    >
      {options.map((i: ButtonInfo, index: number) => {
        return (
          <Button
            key={index}
            text={i.text}
            type={"button"}
            iconName={i.icon}
            iconSize={5}
            textColor={"text-gray-900"}
            padding={"p-0 px-3 py-1"}
            bgColor={"bg-white"}
            onClick={i.onPress}
            justifyContent={"justify-start"}
            extraStyles={
              i.extraStyles
                ? i.extraStyles
                : "hover:bg-gray-100 hover:rounded-sm"
            }
          />
        );
      })}
    </div>
  );
};

export default FloatMenu;
