import ImageHalfSize from "@components/atoms/ImageHalfSize/ImageHalfSize";

import RecoveryForm from "@components/organisms/Recovery/RecoveryForm/RecoveryForm";

import styles from "./Recovery.module.scss";

const Recovery = () => {
  return (
    <div className={styles.Recovery}>
      <div className={styles.imageContainer}>
        <ImageHalfSize />
      </div>
      <RecoveryForm />
    </div>
  );
};

export default Recovery;
