import { useContext, useState } from "react";

import Icon, { IconNames } from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import DeleteNew from "@components/organisms/Delete/DeleteNew/DeleteNew";
import EditNew from "@components/organisms/Edit/EditNew/EditNew";

import { AuthContext } from "@providers/providers";

import FloatMenu from "../FloatMenu/FloatMenu";

interface Props {
  id: number;
  title: string;
  description: string;
  img: string;
  date: string;
  updateData: () => void;
  updateUrlData: () => void;
  updatePaging: () => void;
}

interface ButtonInfo {
  icon: IconNames;
  text: string;
  extraStyles: string | undefined;
  onPress: () => void;
}

const New = ({
  id,
  title,
  img,
  description,
  date,
  updateData,
  updateUrlData,
  updatePaging,
}: Props) => {
  const { auth } = useContext(AuthContext);
  const [openOptions, setOpenOptions] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const width = img ? "w-full w-[60%]" : "w-full";
  const height = img ? "h-[60%] lg:h-full" : "h-full";
  const padding = img ? "md:px-10 lg:pr-10" : "px-5 sm:px-10";
  const fontSize = img
    ? "text-xs sm:text-base xl:text-xl"
    : "text-base xl:text-xl";
  const align = img ? "" : "h-full justify-center";

  const options: ButtonInfo[] = [
    {
      icon: "fileEdit",
      text: "Editar",
      extraStyles: undefined,
      onPress: () => {
        setOpenEditModal(true);
      },
    },
    {
      icon: "trash",
      text: "Eliminar",
      extraStyles: "hover:bg-red-200 hover:rounded-sm",
      onPress: () => {
        setOpenDeleteModal(true);
      },
    },
  ];

  return (
    <>
      <div className={`relative w-full h-full flex flex-col lg:flex-row`}>
        {img && (
          <img
            className="w-full lg:w-[40%] h-[40%] lg:h-full object-cover"
            src={img}
            alt={title}
          />
        )}
        <div className={`${width} ${height} flex flex-col justify-between p-4`}>
          <div className={`flex flex-col gap-2 ${padding} ${align}`}>
            <Span
              text={title}
              weight="font-bold"
              size={`${fontSize}`}
              align="text-left"
            />
            <Span
              text={description}
              weight="font-normal"
              size={`${fontSize}`}
              align="text-justify"
            />
          </div>
          <div className={`${padding}`}>
            <Span text={date} size="text-base" color="text-gray-500" />
          </div>
        </div>
        {auth!!.type === "admin" && (
          <div
            className="absolute bottom-2 right-5 lg:right-4 lg:top-2"
            onBlur={() => {
              setOpenOptions(false);
            }}
          >
            <button onClick={() => setOpenOptions(!openOptions)}>
              <Icon icon={"dotsHorizontal"} size={7} />
            </button>
            <div className="absolute right-8 -top-10 lg:top-2">
              <FloatMenu isOpen={openOptions} optionsFM={options} />
            </div>
          </div>
        )}
      </div>

      <EditNew
        isOpenModal={openEditModal}
        setIsOpenModal={() => setOpenEditModal(!openEditModal)}
        updateData={updateData}
        id={id.toString()}
        title={title}
        description={description}
      />

      <DeleteNew
        id={id}
        title={title}
        isOpenModal={openDeleteModal}
        setIsOpenModal={() => setOpenDeleteModal(!openDeleteModal)}
        updateData={updateData}
        updatePaging={updatePaging}
        updateUrlData={updateUrlData}
      />
    </>
  );
};

export default New;
