import PqrsDetailsTemplate from "@templates/Pqrs/PqrsDetails/PqrsDetails";

type Props = {};

const PqrsDetails = (props: Props) => {
  return (
    <>
      <PqrsDetailsTemplate />
    </>
  );
};

export default PqrsDetails;
