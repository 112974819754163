import { Id } from "react-toastify";
import {
  ErrorStatus,
  SuccessStatus,
  TErrorStatus,
  TSuccessStatus,
} from "types/status";

import { toastError, toastUpdateLoading } from "./Toast";
import { getTranslation } from "./translations/ValidationError";

interface SuccessResponse {
  config: Object;
  data: {
    data: any;
    status: string;
    timestamp: string;
  };
  headers: any;
  request: any;
  status: SuccessStatus;
  statusText: string;
}

interface ErrorResponse {
  config: Object;
  data: {
    data: any;
    status: string;
    timestamp: string;
  };
  headers: any;
  request: any;
  status: ErrorStatus;
  statusText: string;
}
interface DetailsResponse {
  value: string;
  property: string;
  children: string[];
  constraints: object;
}

interface customMsg {
  status: ErrorStatus;
  msg: string;
}

const successMessages: TSuccessStatus = {
  200: "",
  201: "",
  204: "",
};

const errorMessages: TErrorStatus = {
  400: "La petición no fue interpretada correctamente por el servidor.",
  401: "Usuario o contraseña incorrectos.",
  403: "No cuenta con los permisos suficientes para realizar la acción.",
  404: "Recurso no encontrado.",
  405: "La petición ha sido deshabilitada y no puede ser utilizada.",
  409: "Algunos datos ya se encuentran registrados.",
  413: "El tamaño del archivo supera el límite permitido.",
  415: "Archivo ingresado no soportado.", // Pendiente de actualización por parte del backend
  500: "Ocurrió un error con el servidor, inténtelo nuevamente.",
  501: "El servidor no soporto la funcionalidad necesaria para satisfacer la solicitud.",
  502: "El servidor recibió una respuesta inválida desde el servidor entrante.",
};

export const successMessage = (successReponse: SuccessResponse) => {
  const { status } = successReponse;
  return successMessages[status];
};

export const errorMessage = (
  errorResponse: ErrorResponse,
  idToast?: Id,
  customMsg?: customMsg[]
) => {
  const { status, data } = errorResponse || {};

  const CM = customMsg?.filter((val) => val.status === status)[0];

  const errorMToShow = CM ? CM.msg : errorMessages[status];

  idToast
    ? toastUpdateLoading(idToast, errorMToShow, "error")
    : toastError(errorMToShow);

  if (status === 409) {
    const errorDetails: Array<DetailsResponse> = data.data.details;
    errorDetails.forEach((el) => {
      const translation = getTranslation(
        Object.values(el.constraints)[0],
        "es"
      );
      toastError(translation);
    });
    return;
  }
};
