import { FC } from "react";

import RecoveryTemplate from "../../templates/Recovery/Recovery";

interface Props {}

const Recovery: FC<Props> = () => {
  return (
    <>
      <RecoveryTemplate />
    </>
  );
};

export default Recovery;
