import React, { FC } from "react";

import LoginTemplate from "../../templates/Login/Login";

interface Props {}

const Login: FC<Props> = (props) => {
  return (
    <>
      <LoginTemplate />
    </>
  );
};

export default Login;
