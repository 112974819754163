import { Document } from "@entities/index";
import { addDocument } from "@service/Documents.service";
import { getFolderPathToAddDocument } from "@utils/Documents";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Datepicker from "@components/atoms/InputFormik/Datepicker/Datepicker";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import Modal from "@components/atoms/Modal/Modal";

import { AuthContext } from "@providers/providers";

import styles from "./AddDocument.module.scss";

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
};

const AddDocument = ({ isOpenModal, setIsOpenModal, updateData }: Props) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const [hasDueDate, setHasDueDate] = useState(false);

  const getInitialDueDate = () => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate.toISOString();
  };

  const initialValues: Partial<Document> = {
    document: undefined,
    dueDate: getInitialDueDate(),
  };

  const validationSchema = Yup.object({
    document: Yup.string().required("Este campo es obligatorio"),
    dueDate: Yup.string().nullable(),
  });

  const onSubmit = async ({ document, dueDate }: Partial<Document>) => {
    const path = location.pathname.split("/");
    const folderPath = getFolderPathToAddDocument(auth, path);
    let expirationDate = null;
    if (dueDate) {
      expirationDate = new Date(dueDate);
      expirationDate.setHours(23);
      expirationDate.setMinutes(59);
      expirationDate.setSeconds(59);
    }
    const documentData: Partial<Document> = {
      name: document?.name.split(".").slice(0, -1).join("."),
      document: document,
      dueDate: hasDueDate ? expirationDate?.toISOString() : null,
    };
    const idToast = toastLoading("Subiendo documento...");
    try {
      const result = await addDocument(folderPath, documentData, auth!!.token);
      if (result) {
        toastUpdateLoading(idToast, "Documento subido", "success", "bell");
        if (updateData) updateData();
        setIsOpenModal();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al subir el documento",
        },
      ]);
    }
  };

  useEffect(() => {
    setHasDueDate(false);
  }, [isOpenModal]);

  return (
    <Modal
      title={"Nuevo documento"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.form}>
              <div
                className={`${styles.fields} ${
                  hasDueDate ? "items-end" : "items-center"
                }`}
              >
                <div className="w-[48%]">
                  <InputFile
                    label={"Archivo"}
                    value={values.document?.name}
                    setValue={setFieldValue}
                    id={"document"}
                    accept={".xlsx, .pdf"}
                  />
                </div>
                <div className="w-[48%]">
                  <label
                    className={`${styles.hasDueDate} ${
                      hasDueDate ? "mb-5" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      value="has_duedate"
                      defaultChecked={hasDueDate}
                      onClick={(e) => setHasDueDate(!hasDueDate)}
                    />
                    Tiene fecha de vencimiento?
                  </label>
                  {hasDueDate ? (
                    <Datepicker
                      label={"Fecha de Vencimiento"}
                      id={"dueDate"}
                      value={new Date(values.dueDate || "").toLocaleDateString(
                        "en-US",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                      setValue={setFieldValue}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className={styles.btnsContainer}>
                <Button
                  height="h-10"
                  text={"Añadir"}
                  type={"submit"}
                  bgColor="bg-gray-800 hover:bg-gray-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
                <Button
                  height="h-10"
                  text={"Cancelar"}
                  onClick={setIsOpenModal}
                  type={"button"}
                  bgColor="bg-red-800 hover:bg-red-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddDocument;
