import { Accountant } from "@entities/Accountant.entity";
import { getAccountantById } from "@service/Accountant.service";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import { IconNames } from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";

import { AuthContext } from "@providers/providers";

import { useAccountantsByParams } from "@hooks/Accountants/useAccountants";

import { errorMessage } from "../../../../utils/Status";
import AccountantCard from "../AccountantCard/AccountantCard";

interface BtnProps {
  text: string;
  onPress?: () => void;
  iconName: IconNames;
  color?: string;
}

const Btn = ({
  text,
  onPress,
  iconName,
  color = "bg-gray-800 hover:bg-gray-900",
}: BtnProps) => {
  return (
    <Button
      text={text}
      type={"button"}
      onClick={onPress}
      iconName={iconName}
      iconColor={"white"}
      iconSize={4}
      width="w-full"
      height="h-9"
      bgColor={color}
      padding="px-3 py-1"
      extraStyles="text-xs rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
    />
  );
};

interface Props {
  accountantId: number | null;
  onlySearchBar?: boolean;
  setValue?: (value: number | string) => void;
  error?: string;
  onPress?: (val: number) => void;
  changeAcc?: boolean;
}

const SearchAccountantCard = ({
  accountantId,
  onlySearchBar = true,
  setValue,
  error,
  onPress,
  changeAcc = true,
}: Props) => {
  const navigate = useNavigate();

  const [openSearchBar, setOpenSearchBar] = useState(false);

  const [accountant, setAccountant] = useState<Accountant | undefined | null>(
    undefined
  );

  const [
    accsFound,
    accToSearch,
    setAccToSearch,
    getSearchAccountants,
    setAccsFound,
  ] = useAccountantsByParams();

  const [accToShow, setAccToShow] = useState<Accountant | undefined | null>();

  const { auth, setAuth } = useContext(AuthContext);

  const getAccounantId = async () => {
    if (accountantId) {
      try {
        const { status, data } = await getAccountantById(
          accountantId.toString(),
          auth!!.token
        );
        if (status === 200) {
          setAccountant(data.data);
        }
      } catch (error: any) {
        errorMessage(error.response);
      }
    } else {
      setAccountant(null);
    }
  };

  useEffect(() => {
    getAccounantId();
  }, [auth, setAuth]);

  useEffect(() => {
    setAccToShow(
      accsFound ? (accsFound[0] === undefined ? null : accsFound[0]) : undefined
    );
  }, [accsFound]);

  return (
    <>
      {accountant !== undefined ? (
        <AccountantCard
          active={accToShow ? accToShow?.active : accountant?.active}
          id={
            accToShow
              ? (accToShow.id as number)
              : accountant === null
              ? 0
              : (accountant.id as number)
          }
          searchValue={accToSearch}
          setSearchValue={setAccToSearch}
          openSearch={onlySearchBar ? onlySearchBar : openSearchBar}
          img={
            accToShow
              ? accToShow.profilePicture
              : accountant === null
              ? ""
              : accountant.profilePicture
          }
          name={
            accToShow
              ? `${accToShow.names} ${accToShow.surnames}`
              : accountant === null
              ? ""
              : `${accountant.names} ${accountant.surnames}`
          }
          email={
            accToShow
              ? accToShow.email
              : accountant === null
              ? ""
              : accountant.email
          }
          phoneNumber={
            accToShow
              ? accToShow.phoneNumber
              : accountant === null
              ? ""
              : accountant.phoneNumber
          }
          label={accToShow ? "Nuevo contador" : "Mi contador"}
          cardEmpty={
            accountantId === null
              ? "No tiene asignado a ningún contador"
              : accToShow === null
              ? "No se encontro a ningún contador"
              : undefined
          }
          error={error}
        >
          {!onlySearchBar ? (
            openSearchBar ? (
              <>
                {accToShow ? (
                  <>
                    {accToShow.active && (
                      <Btn
                        text={"Aceptar"}
                        iconName={"checkCircle"}
                        onPress={() => {
                          if (setValue) setValue(accToShow.id);
                          onPress?.(Number(accToShow.id));
                          setAccountant(accToShow);
                          setAccsFound(undefined);
                          setOpenSearchBar(false);
                        }}
                      />
                    )}

                    <Btn
                      text={"Volver"}
                      iconName={"closeCircle"}
                      color={"bg-red-700"}
                      onPress={() => {
                        setAccsFound(undefined);
                        setAccToSearch("");
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Btn
                      text={"Buscar"}
                      iconName={"searchloop"}
                      onPress={getSearchAccountants}
                    />
                    <Btn
                      text={"Cancelar"}
                      color={"bg-red-800 hover:bg-red-900"}
                      iconName={"closeCircle"}
                      onPress={() => {
                        setAccsFound(undefined);
                        setOpenSearchBar(false);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {!setValue && accountantId !== null && (
                  <Btn
                    text={"Ver perfil"}
                    iconName={"eye"}
                    onPress={() => {
                      const routeToNavigate =
                        accountant && auth?.type === "accountant"
                          ? "/profile"
                          : `/profile-acc/${accountant?.id}`;
                      navigate(routeToNavigate);
                    }}
                  />
                )}
                {auth?.type === "admin" && changeAcc ? (
                  <Btn
                    text={"Cambiar contador"}
                    iconName={"userEdit"}
                    onPress={() => setOpenSearchBar(true)}
                  />
                ) : null}
              </>
            )
          ) : (
            <div></div>
          )}
        </AccountantCard>
      ) : (
        <div className="flex justify-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default SearchAccountantCard;
