import { Pagination as PG } from "flowbite-react";
import { Dispatch, memo } from "react";

interface Props {
  currentPage: number;
  setCurrentPage: Dispatch<React.SetStateAction<number>>;
  totalPages?: number;
}

const Pagination = ({
  currentPage,
  setCurrentPage,
  totalPages = 100,
}: Props) => {
  return (
    <div className="flex overflow-x-auto sm:justify-center full p-5">
      <PG
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        showIcons={true}
      />
    </div>
  );
};

export default memo(Pagination);
