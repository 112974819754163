import { Event } from "@entities/Event.entity";
import { addEvent } from "@service/Events.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Datepicker from "@components/atoms/InputFormik/Datepicker/Datepicker";
import Input from "@components/atoms/InputFormik/Input/Input";
import Textarea from "@components/atoms/InputFormik/Textarea/Textarea";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

import styles from "./AddEvent.module.scss";

type Props = {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
};

const AddEvent = ({ isOpenModal, setIsOpenModal, updateData }: Props) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  const getInitialDueDate = () => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate.toISOString();
  };

  const initialValues = {
    name: "",
    description: "",
    dueDate: getInitialDueDate(),
    hours: "1",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Este campo es obligatorio"),
    description: Yup.string()
      .max(255, "Máximo 255 carácteres")
      .required("Este campo es obligatorio"),
    dueDate: Yup.string()
    .required("Este campo es obligatorio"),
    hours: Yup.number()
      .min(1, "La duración mínima es de 1 hora")
      .required("Este campo es obligatorio"),
  });

  const getMinDateForDatepicker = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  };

  const onSubmit = async (values: Partial<Event>) => {
    const path = location.pathname.split("/");
    const clientId = path[2];
    const idToast = toastLoading("Agregando evento...");
    let dueDate: any = new Date().toISOString();
    if (values.dueDate) {
      dueDate = new Date(values.dueDate);
      dueDate.setHours(23);
      dueDate.setMinutes(59);
      dueDate.setSeconds(59);
    }
    const eventData: Partial<Event> = {
      name: values.name,
      description: values.description,
      dueDate: dueDate,
      hours: values.hours?.toString(),
    };
    try {
      const result = await addEvent(clientId, eventData, auth!!.token);
      if (result) {
        toastUpdateLoading(idToast, "Evento agregado", "success", "bell");
        if (updateData) updateData();
        setIsOpenModal();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al agregar evento",
        },
      ]);
    }
  };

  return (
    <Modal
      title={"Nuevo documento"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.form}>
              <div>
                <Span
                  text="Título del Evento"
                  weight="font-bold"
                  size="text-base"
                />
                <Input name={"name"} placeholder={""} type="text" />
                <Span text="Descripción" weight="font-bold" size="text-base" />
                <Textarea name={"description"} placeholder={""} />
                <div className={styles.dateTimePicker}>
                  <div className={styles.datePicker}>
                    <Datepicker
                      label={"Fecha de visita"}
                      id={"dueDate"}
                      value={new Date(values.dueDate || "").toLocaleDateString(
                        "en-US",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                      minDate={getMinDateForDatepicker()}
                      setValue={setFieldValue}
                    />
                  </div>
                  <div className={styles.timePicker}>
                    <Span
                      text="Duración(Horas)"
                      weight="font-bold"
                      size="text-base"
                    />
                    <Input name={"hours"} placeholder={""} type="number" />
                  </div>
                </div>
              </div>
              <div className={styles.btnsContainer}>
                <Button
                  height="h-10"
                  text={"Añadir"}
                  type={"submit"}
                  bgColor="bg-gray-800 hover:bg-gray-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
                <Button
                  height="h-10"
                  text={"Cancelar"}
                  onClick={setIsOpenModal}
                  type={"button"}
                  bgColor="bg-red-800 hover:bg-red-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEvent;
