import { Card as FlowBiteCard } from "flowbite-react";

import styles from "./Card.module.scss";

interface Props {
  children: React.ReactElement;
  style?: React.CSSProperties;
  extraStyles?: string;
  onPress?: () => void;
}

const Card = ({ children, style, extraStyles, onPress }: Props) => {
  return (
    <FlowBiteCard
      onClick={onPress}
      style={style}
      className={`p-6 ${styles.container} ${extraStyles}`}
    >
      {children}
    </FlowBiteCard>
  );
};

export default Card;
