import { fetching } from "@entities/Fetching.entity";
import { Folder } from "@entities/Folder.entity";
import { logout } from "@service/Auth.service";
import { deleteFolder } from "@service/Documents.service";
import { removeCookie } from "@utils/Cookie";
import { getFolderPathForNextFolder } from "@utils/Documents";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Form, Formik } from "formik";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Lock from "@assets/Icons/Lock.svg";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Input from "@components/atoms/InputFormik/Input/Input";
import Loader from "@components/atoms/Loader/Loader";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";
import TitleH2 from "@components/atoms/TitleH2/TitleH2";

import CustomTable from "@components/molecules/CustomTable/CustomTable";
import MinifiedDocumentRow from "@components/molecules/Row/DocumentRow/MinifiedDocumentRow";

import { AuthContext } from "@providers/providers";

import { useUserDocuments } from "@hooks/Documents/useDocuments";

import styles from "./DeleteFolder.module.scss";

type Props = {
  folder: Pick<Folder, "id" | "name">;
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
};

interface DeleteFolderEntity {
  password: "";
}

const DeleteFolder = ({
  folder,
  isOpenModal,
  setIsOpenModal,
  updateData,
}: Props) => {
  const { auth, setAuth } = useContext(AuthContext);
  const location = useLocation();
  const [
    userDocuments,
    userDocumentsFetchingState,
    userDocumentsCurrentPage,
    setUserDocumentsCurrentPage,
    totalUserDocuments,
    getUserDocuments,
  ] = useUserDocuments();

  const folderPath = getFolderPathForNextFolder(
    auth,
    folder.id,
    location.pathname.split("/")
  );

  const initialValues: DeleteFolderEntity = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: DeleteFolderEntity) => {
    const idToast = toastLoading("Eliminando carpeta...");
    try {
      const { status } = await deleteFolder(
        getFolderPathForNextFolder(
          auth,
          folder.id,
          location.pathname.split("/")
        ),
        values,
        auth!.token
      );
      if (status === 201) {
        toastUpdateLoading(
          idToast,
          "Carpeta eliminada exitosamente",
          "success",
          "bell"
        );
        if (updateData) updateData();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast);
      if (error.response.status === 401) {
        await logout();
        removeCookie("auth", { path: "/" });
        setAuth(undefined);
      }
    } finally {
      setIsOpenModal();
    }
  };

  const renderDocumentsList = () => {
    switch (userDocumentsFetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (userDocuments?.length === 0) {
          return (
            <div className="p-20 flex justify-center items-center flex-col gap-10">
              <Icon icon={"file"} size={20} />
              <Span
                text={"Carpeta vacía"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <div
            className={`${styles.table} gap-y-5 flex flex-col items-start justify-center w-full`}
          >
            <div className="w-full">
              <CustomTable
                columns={[]}
                Row={MinifiedDocumentRow}
                data={userDocuments.slice(0, 4)}
              />
            </div>
            {userDocuments.length === 20 && (
              <Span
                text="Más de 20 documentos"
                color="text-gray-500"
                size="text-base"
              />
            )}
          </div>
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      getUserDocuments(folderPath);
    }
  }, [isOpenModal]);

  return (
    <Modal
      title={"Eliminar carpeta"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      onPress={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className={styles.form}>
              <div className={styles.header}>
                <TitleH2 text={folder.name} weight="font-bold" />
                <Span text={"Carpeta de archivos"} weight="font-thin" />
              </div>
              {renderDocumentsList()}
              <div className={styles.password}>
                <Span
                  text={"Para continuar escribe tu contraseña"}
                  weight="font-medium"
                />
                <div className={styles.field}>
                  <Input
                    name={"password"}
                    placeholder={"Contraseña"}
                    type={"password"}
                    icon={Lock}
                  />
                </div>
              </div>
              <div className={styles.btnsContainer}>
                <Button
                  height="h-10"
                  text={"Aceptar"}
                  type={"submit"}
                  bgColor="bg-gray-800 hover:bg-gray-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
                <Button
                  height="h-10"
                  text={"Cancelar"}
                  onClick={setIsOpenModal}
                  type={"button"}
                  bgColor="bg-red-800 hover:bg-red-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DeleteFolder;
