import { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Icon, { IconNames } from "@components/atoms/Icons/Icon";
import TitleH3 from "@components/atoms/TitleH3/TitleH3";

import styles from "./SideMenuOption.module.scss";

interface Props {
  iconName: IconNames;
  link?: string;
  text: string;
  onPress?: () => Promise<void>;
  otherOptions?: string[];
}

const SideMenuOption: FC<Props> = ({
  link = "#",
  text,
  iconName,
  onPress,
  otherOptions,
}) => {
  const location = useLocation();

  const [isHover, setIsHover] = useState<boolean>(false);

  const getActive = () => {
    if (otherOptions) {
      return (
        otherOptions.filter((el) => location.pathname.startsWith(`/${el}`))
          .length > 0
      );
    }
  };

  const ACTIVE = getActive() || location.pathname.startsWith(link);

  return (
    <Link
      to={link}
      onClick={onPress}
      className={styles.option}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{
        borderLeft:
          isHover || ACTIVE
            ? " 8px solid var(--cyan)"
            : "8px solid transparent",
        backgroundColor:
          isHover || ACTIVE ? "var(--blue-clear)" : "transparent",
      }}
    >
      <Icon
        icon={iconName}
        color={isHover || ACTIVE ? "white" : "#6B7280"}
        size={5}
      />
      <TitleH3
        text={text}
        color={isHover || ACTIVE ? "text-white" : "text-gray-clear"}
        weight={"font-medium"}
      />
    </Link>
  );
};

export default SideMenuOption;
