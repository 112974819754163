import { New } from "@entities/News.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/news`;


export const addNew = (value:Partial<New>, token: string) =>{
  return axios.post(baseUrl, value, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    }
  })
}

export const getNews = (token: string, amount: number)=>{
  return axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params:{
      amount: amount
    }
  });
};

export const getCompanyNews = (token: string) =>{
  return axios.get(`${baseUrl}/company-news`, {headers:{
    Authorization: `Bearer ${token}`
  }})
}

export const updateNew = (
  id: string,
  values: Partial<New>,
  token: string
) => {
  return axios.patch(`${baseUrl}/${id}`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteNew = (id: number, token: string)=>{
  return axios.delete(`${baseUrl}/${id}`,{
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
};

