import { Form, Formik } from "formik";
import * as Yup from "yup";

import Lock from "@assets/Icons/Lock.svg";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";
import TitleH2 from "@components/atoms/TitleH2/TitleH2";

import styles from "./DeactivateUser.module.scss";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  deactiveUserFunction: (data: any) => void;
  name: string;
  type: "cliente" | "contador";
  id: string;
  isDisabled?: boolean;
}

interface DeactivateUser {
  password: "";
}

const DeactivateUser = ({
  isOpenModal,
  setIsOpenModal,
  deactiveUserFunction,
  name,
  type,
  id,
  isDisabled = true,
}: Props) => {
  const initialValues: DeactivateUser = {
    password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = (values: DeactivateUser) => {
    deactiveUserFunction(values);
  };

  return (
    <Modal
      title={`${isDisabled ? "Deshabilitar" : "Habilitar"} usuario`}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({}) => {
          return (
            <Form className={styles.form}>
              <div className={styles.header}>
                <TitleH2
                  text={`¿Está seguro de ${
                    isDisabled ? "deshabilitar" : "habilitar"
                  } al siguiente ${type}?`}
                  weight="font-bold"
                />
                <Span
                  text={`${type === "cliente" ? "NIT" : "ID"}: ${id}`}
                  weight="font-thin"
                  extraStyles="mt-5"
                />
                <Span text={`${name}`} weight="font-thin" />
              </div>
              <div className={styles.password}>
                <Span
                  text={"Para continuar escribe tu contraseña"}
                  weight="font-medium"
                />
                <div className={styles.field}>
                  <Input
                    name={"password"}
                    placeholder={"Contraseña"}
                    type={"password"}
                    icon={Lock}
                  />
                </div>
              </div>
              <div className={styles.btnsContainer}>
                <Button
                  height="h-10"
                  text={isDisabled ? "Deshabilitar" : "Habilitar"}
                  type={"submit"}
                  bgColor="bg-gray-800 hover:bg-gray-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
                <Button
                  height="h-10"
                  text={"Cancelar"}
                  onClick={setIsOpenModal}
                  type={"button"}
                  bgColor="bg-red-800 hover:bg-red-900"
                  extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                  padding="px-1 py-1"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DeactivateUser;
