import type { CustomFlowbiteTheme } from "flowbite-react";
import { Carousel as CarouselFlowbite } from "flowbite-react";

interface Props {
  customTheme: CustomFlowbiteTheme["carousel"];
  leftControl?: any;
  rightControl?: any;
  slide?: boolean;
  indicators?: boolean;
  children: any;
}

const Carousel = ({
  customTheme,
  leftControl,
  rightControl,
  slide,
  indicators,
  children,
}: Props) => {
  return (
    <CarouselFlowbite
      theme={customTheme}
      slide={slide}
      indicators={indicators}
      leftControl={leftControl}
      rightControl={rightControl}
    >
      {children}
    </CarouselFlowbite>
  );
};

export default Carousel;
