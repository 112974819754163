import PqrsTemplate from "@templates/Pqrs/Pqrs";

type Props = {};

const Pqrs = (props: Props) => {
  return (
    <>
      <PqrsTemplate />
    </>
  );
};

export default Pqrs;
