type Props = {
  color?: string;
  style: string;
};

const FileExport = ({ color = "black", style }: Props) => {
  return (
    <svg
      className={style}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 17"
      style={{
        color,
      }}
    >
      <path
        d="M6 5.16634V1.91967C5.6747 2.00966 5.3779 2.18158 5.138 2.41901L3.25267 4.30434C3.01525 4.54424 2.84332 4.84103 2.75334 5.16634H6Z"
        fill="white"
      />
      <path
        d="M11.8453 12.1497C11.7196 12.2711 11.5512 12.3383 11.3764 12.3368C11.2016 12.3353 11.0344 12.2652 10.9108 12.1416C10.7872 12.0179 10.7171 11.8507 10.7156 11.6759C10.714 11.5011 10.7812 11.3327 10.9027 11.207L11.6093 10.4997H7.33334C7.15653 10.4997 6.98696 10.4294 6.86193 10.3044C6.73691 10.1794 6.66667 10.0098 6.66667 9.83301C6.66667 9.6562 6.73691 9.48663 6.86193 9.3616C6.98696 9.23658 7.15653 9.16634 7.33334 9.16634H11.6093L10.9027 8.45901C10.839 8.39751 10.7882 8.32395 10.7533 8.24261C10.7183 8.16128 10.6999 8.07379 10.6992 7.98528C10.6984 7.89676 10.7153 7.80897 10.7488 7.72704C10.7823 7.64511 10.8318 7.57067 10.8944 7.50808C10.957 7.44548 11.0314 7.39598 11.1134 7.36246C11.1953 7.32894 11.2831 7.31207 11.3716 7.31284C11.4601 7.31361 11.5476 7.332 11.6289 7.36694C11.7103 7.40188 11.7838 7.45267 11.8453 7.51634L13.3333 9.00434V3.16634C13.3389 2.81876 13.2063 2.48317 12.9647 2.23321C12.7231 1.98324 12.3922 1.83932 12.0447 1.83301H7.33334V5.16634C7.33334 5.51996 7.19286 5.8591 6.94281 6.10915C6.69276 6.3592 6.35363 6.49967 6 6.49967H2.66667V13.833C2.66114 14.1806 2.79371 14.5162 3.0353 14.7661C3.27689 15.0161 3.60776 15.16 3.95534 15.1663H12C12.3536 15.1663 12.6928 15.0259 12.9428 14.7758C13.1929 14.5258 13.3333 14.1866 13.3333 13.833V10.6617L11.8453 12.1497Z"
        fill="white"
      />
    </svg>
  );
};

export default FileExport;
