import {
  Calendar as CalendarEntity,
  documentStatus,
} from "@entities/Calendar.entity";
import {
  type EventClickArg,
  type EventInput,
  type EventSourceFuncArg,
} from "@fullcalendar/core";
import { createRef } from "@fullcalendar/core/preact";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/react";
import { getDifferenceTime } from "@utils/Times";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import AddEvent from "@components/organisms/Add/AddEvent/AddEvent";
import DocumentView from "@components/organisms/CalendarContent/DocumentView";
import EventView from "@components/organisms/CalendarContent/EventView";

import { AuthContext } from "@providers/providers";

import { useCalendar } from "@hooks/Calendar/useCalendar";

import Button from "../Button/Button";
import Span from "../Span/Span";
import "./Calendar.scss";

type viewType = "dayGridMonth" | "multiMonthYear" | "listWeek";

interface Props {
  nit: string;
}

const Calendar = ({ nit }: Props) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const calendarRef = createRef();
  const [openEventModal, setOpenFileModal] = useState(false);
  const [getDocumentsCalendar, getEventsCalendar] = useCalendar(nit);

  const [documentEventModal, setDocumentEventModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [documentEventSelected, setDocumentEventSelected] = useState<
    CalendarEntity | undefined
  >(undefined);
  const [eventSelected, setEventSelected] = useState(undefined);

  const [viewType, setViewType] = useState<viewType>("dayGridMonth");

  const docStatus: documentStatus[] = ["pago", "vigente", "vencido"];

  useEffect(() => {
    if (calendarRef.current.calendar) {
      setViewType(calendarRef.current.calendar.currentData.currentViewType);
    }
  }, [calendarRef]);

  const getColorByStatus = (status: documentStatus) => {
    switch (status) {
      case "pago":
        return "blue";
      case "vencido":
        return "red";
      case "vigente":
        return "green";
    }
  };

  const onEventClick = (e: EventClickArg) => {
    if (e.event.extendedProps.eventType === "documents") {
      const eventData: CalendarEntity = {
        fileId: e.event.extendedProps.fileId,
        typeFile: e.event.extendedProps.typeFile,
        name: e.event.extendedProps.name,
        dueDate: e.event.extendedProps.dueDate,
        viewDate: e.event.extendedProps.viewDate,
        id: e.event.extendedProps.idDoc,
        creationDate: e.event.extendedProps.creationDate,
        updateDate: e.event.extendedProps.updateDate,
        deleteDate: e.event.extendedProps.deleteDate,
        status: e.event.extendedProps.status.name,
      };
      setDocumentEventSelected(eventData);
      setDocumentEventModal(true);
    }
    if (e.event.extendedProps.eventType === "events") {
      const eventData: any = {
        name: e.event.extendedProps.name,
        description: e.event.extendedProps.description,
        dueDate: e.event.extendedProps.dueDate,
        hours: e.event.extendedProps.hours,
        eventId: e.event.extendedProps.eventId,
        clientId: e.event.extendedProps.clientId,
      };
      setEventSelected(eventData);
      setEventModal(true);
    }
  };

  const getEvents = useCallback(
    async (
      info: EventSourceFuncArg,
      successCallback: (eventInputs: EventInput[]) => void,
      failureCallback: (error: Error) => void
    ) => {
      try {
        const documents = await getDocumentsCalendar(info.start, info.end);
        const events = await getEventsCalendar(info.start, info.end);
        documents.map((i) => (i.eventType = "documents"));
        events.map((i) => (i.eventType = "events"));
        const fullListOfEvents = [...documents, ...events];
        successCallback(
          fullListOfEvents.map((e: any) => {
            const randomNumber = Math.floor(Math.random() * 3);
            if (e.eventType === "documents") {
              return {
                title: e.name,
                start: getDifferenceTime(new Date(e.dueDate).toISOString()),
                extendedProps: {
                  eventType: "documents",
                  fileId: e.fileId,
                  typeFile: e.typeFile,
                  name: e.name,
                  dueDate: new Date(e.dueDate),
                  idDoc: e.id,
                  viewDate: e.viewDate,
                  creationDate: new Date(e.creationDate),
                  updateDate: new Date(e.updateDate),
                  deleteDate: e.deleteDate,
                  status: e.status ? e.status : docStatus[randomNumber],
                },
              };
            }
            if (e.eventType === "events") {
              return {
                title: e.name,
                start: getDifferenceTime(new Date(e.dueDate).toISOString()),
                extendedProps: {
                  eventType: "events",
                  name: e.name,
                  description: e.description,
                  dueDate: new Date(e.dueDate),
                  hours: e.hours,
                  eventId: e.id,
                  clientId: location.pathname.split("/")[2],
                },
              };
            }
            return {};
          })
        );
      } catch (error: any) {
        failureCallback(error);
      }
    },
    [calendarRef, viewType]
  );

  const renderEventContent = (eventInfo: any) => {
    const status = eventInfo.event.extendedProps.status;
    const color = status ? getColorByStatus(status.name) : "#009688";

    const date = new Date(eventInfo.event.extendedProps.dueDate);

    return (
      <>
        {eventInfo.view.type === "multiMonthYear" ? (
          <div className="event-container">
            <div
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: color,
              }}
            />
            <Span
              extraS={{ display: "none" }}
              text={eventInfo.event.title}
              size={"text-xs"}
              weight={"font-bold"}
            />
          </div>
        ) : (
          <div
            className="event-container"
            style={{
              cursor: "pointer",
              backgroundColor:
                eventInfo.view.type === "dayGridMonth" ? color : "transparent",
              width: "100%",
              padding: "3px 4px",
              borderRadius: "8px",
              display: eventInfo.view.type === "listWeek" ? "flex" : "initial",
              flexDirection:
                eventInfo.view.type === "listWeek" ? "row" : "initial",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {eventInfo.view.type === "listWeek" && (
              <Span
                text={new Intl.DateTimeFormat(undefined, {
                  timeStyle: "short",
                }).format(date)}
                size={
                  eventInfo.view.type === "dayGridMonth" ? "text-xs" : "text-sm"
                }
                color="text-black"
              />
            )}

            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: color,
                display:
                  eventInfo.view.type === "listWeek" ? "initial" : "none",
              }}
            />
            <Span
              text={eventInfo.event.title}
              size={
                eventInfo.view.type === "dayGridMonth" ? "text-xs" : "text-sm"
              }
              weight={"font-bold"}
              color={
                eventInfo.view.type === "dayGridMonth"
                  ? "text-white"
                  : undefined
              }
            />
          </div>
        )}
      </>
    );
  };

  const changeView = (day: Date) => {
    if (viewType === "multiMonthYear") {
      calendarRef.current.getApi().changeView("listWeek", day);
    }
  };

  const renderAddEventButton = () => {
    if (auth?.type !== "client") {
      return (
        <div className="flex justify-end items-center py-10">
          <Button
            onClick={() => setOpenFileModal(!openEventModal)}
            text={"Agregar evento"}
            type={"button"}
            iconName={"addDate"}
            iconColor={"white"}
            iconSize={5}
            width="w-[149px]"
            height="h-9"
            bgColor="bg-gray-800 hover:bg-gray-900"
            padding="px-0 py-0"
            extraStyles="text-xs rounded-[6px] font-extralight focus:ring-4 focus:outline-none focus:ring-gray-300"
          />
        </div>
      );
    }
  };

  return (
    <>
      {renderAddEventButton()}
      <FullCalendar
        ref={calendarRef}
        views={{
          multiMonthYear: {
            type: "multiMonthYear",
            duration: { months: 4 },
          },
        }}
        progressiveEventRendering={true}
        plugins={[
          multiMonthPlugin,
          dayGridPlugin,
          interactionPlugin,
          listPlugin,
        ]}
        initialView={"dayGridMonth"}
        buttonText={{
          today: "Hoy",
          month: "Mes",
          list: "Semana",
        }}
        headerToolbar={{
          left: "today",
          center: "prev title next",
          right: "dayGridMonth,listWeek",
        }}
        locale={"es-co"}
        selectable={true}
        dayHeaderDidMount={(e) => setViewType(e.view.type as viewType)}
        dayMaxEvents={viewType === "multiMonthYear" ? 5 : 2}
        events={getEvents}
        eventDidMount={(info) => {
          if (info.event) {
            const status: documentStatus = info.event.extendedProps.status;
            const dotEl = info.el.querySelector(
              ".fc-list-event-dot"
            ) as HTMLElement;
            if (dotEl instanceof HTMLElement) {
              dotEl.style.borderColor = getColorByStatus(status);
            }
          }
        }}
        eventContent={renderEventContent}
        rerenderDelay={1000}
        eventClick={onEventClick}
        displayEventTime={false}
        navLinkDayClick={changeView}
        navLinks={true}
      />
      <AddEvent
        isOpenModal={openEventModal}
        setIsOpenModal={() => setOpenFileModal(!openEventModal)}
        updateData={() => {}}
      />
      <DocumentView
        docInfo={documentEventSelected}
        isOpenModal={documentEventModal}
        setIsOpenModal={() => setDocumentEventModal(!documentEventModal)}
      />
      <EventView
        docInfo={eventSelected}
        isOpenModal={eventModal}
        setIsOpenModal={() => setEventModal(!eventModal)}
        updateEvents={() => getEvents}
      />
    </>
  );
};

export { Calendar };
