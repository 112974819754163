interface Props {
  children: React.ReactElement | null;
  color?: string;
  extraStyles?: string;
}

const Badge = ({ children, color = "bg-gray-200", extraStyles }: Props) => {
  return (
    children && (
      <div
        className={`rounded-[8px] items-center ${color} px-2.5 py-0.5 rounded dark:${color} dark:text-gray-900 flex flex-row justify-evenly gap-[10px] ${extraStyles}`}
      >
        {children}
      </div>
    )
  );
};

export default Badge;
