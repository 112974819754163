import { FC, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Bg_P1 from "../../../assets/Icons/Bg_P1.svg";
import Bg_P2 from "../../../assets/Icons/Bg_P2.svg";
import Whatsapp from "../../../assets/Icons/Whatsapp.svg";

import { AuthContext } from "@providers/providers";

import Icon from "../Icons/Icon";
import Span from "../Span/Span";
import ContactClientCard from "./ContactClientCard";
import styles from "./ProfileUser.module.scss";

interface Props {
  isImgProfile?: boolean;
  id?: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
  profileP?: string;
  nit?: string;
  updateProfilePFunction?: (pP: File | undefined) => void;
  isClientContact?: boolean;
  rol?: string;
  isEditablePicture?: boolean;
}

const ProfileUser: FC<Props> = ({
  isImgProfile = true,
  id,
  email,
  phoneNumber,
  name,
  nit,
  profileP,
  updateProfilePFunction,
  isClientContact = false,
  rol,
  isEditablePicture = true,
}) => {
  const [profilePicture, setProfilePicture] = useState<File | undefined>();

  const { auth } = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    if (profilePicture && updateProfilePFunction)
      updateProfilePFunction(profilePicture);
  }, [profilePicture, setProfilePicture]);

  const getRol = () => {
    const route = location.pathname.split("/")[1];
    if (auth) {
      switch (route) {
        case "clients":
          return "Cliente";
        case "accountants":
          return "Contador";
        case "profile":
          return auth!.type === "client" ? "Cliente" : "Contador";
        case "profile-acc":
          return "Contador";
        default:
          return "Usuario";
      }
    }
    return "";
  };

  const width = isImgProfile ? "20rem" : "36.5rem";
  return (
    <>
      <div
        className={styles.container}
        style={{
          width: width,
          backgroundColor: isClientContact ? "#61a9bf" : undefined,
        }}
      >
        {isImgProfile ? (
          <>
            <div className={styles.backgroundP1}>
              <img src={Bg_P1} alt="background" />
              <img src={Bg_P2} alt="background" />
            </div>
            <div className={styles.imgContainer}>
              {isEditablePicture && (
                <div className={styles.borderIcon}>
                  <div>
                    <input
                      type="file"
                      onChange={(e) => setProfilePicture(e.target.files!![0])}
                      accept=".jpg, .png"
                    />
                    <Icon icon={"cameraDevice"} color={"white"} />
                  </div>
                </div>
              )}

              <div className={styles.border}>
                {profileP ? (
                  <img alt="profilePicture" src={profileP} />
                ) : (
                  <div />
                )}
              </div>
            </div>
            <div className={styles.infoContainer}>
              {name && (
                <>
                  <Span
                    size={"text-xl sm:text-2xl"}
                    color={"text-blue-dashboard"}
                    weight={"font-semibold"}
                    text={name}
                  />
                  <Span
                    size={"sm:text-xl"}
                    color={"text-blue-dashboard"}
                    text={getRol()}
                  />
                </>
              )}
            </div>
          </>
        ) : isClientContact ? (
          <ContactClientCard
            name={name}
            rol={rol}
            nit={nit}
            email={email}
            phoneNumber={phoneNumber}
          />
        ) : (
          <>
            <div className={styles.background}>
              <img src={Bg_P2} alt="background" />
            </div>
            <div className={styles.iconContainer}>
              <Icon icon={"user"} color={"#374151"} size={11} />
            </div>
            <div className={styles.infoDetailsContainer}>
              {id && email && phoneNumber && (
                <>
                  <div className={styles.textInfoContainer}>
                    <Icon icon={"profileCard"} color={"#374151"} size={6} />
                    <Span
                      weight={"font-medium"}
                      color={"text-gray-700"}
                      size={"text-xl"}
                      text={id}
                    />
                  </div>
                  <div className={styles.textInfoContainer}>
                    <Icon icon={"envelope"} color={"#374151"} size={6} />
                    <div className={styles.email}>
                      <Span
                        weight={"font-medium"}
                        color={"text-gray-700"}
                        size={"text-xl"}
                        text={email}
                      />
                    </div>
                  </div>
                  <div className={styles.textInfoContainer}>
                    <div className={styles.Whatsapplink}>
                      <a
                        href={`https://wa.me/${phoneNumber.replace(
                          /[^0-9]/g,
                          ""
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Whatsapp} className={"relative"} />
                        <Span
                          weight={"font-medium"}
                          size={"text-xl"}
                          color={"text-green-bright"}
                          text={phoneNumber}
                        />
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProfileUser;
