import { useState } from "react";
import { Cell, Label, Pie, PieChart as PieC } from "recharts";

import Span from "@components/atoms/Span/Span";

interface Props {
  data: object[];
  width?: number;
  height?: number;
  label?: boolean;
  labelValue?: string;
}

const PieChart = ({
  data,
  width = 160,
  height = 160,
  label = false,
  labelValue,
}: Props) => {
  const RenderLabel = () => {
    return (
      <g>
        <foreignObject x={50} y={50} width={100} height={100}>
          <div className="flex justify-center items-center h-full">
            <Span
              text={labelValue ? labelValue : ""}
              weight={"font-bold"}
              size={"text-2xl"}
            />
          </div>
        </foreignObject>
      </g>
    );
  };

  return (
    <PieC width={width} height={height}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={width / 2 - 30}
        outerRadius={width / 2}
        fill={"#25253f"}
      >
        {data.map((_, index) => (
          <Cell key={`cell-${index}`} />
        ))}
        {label && labelValue && (
          <Label position={"center"} value={"50%"} content={<RenderLabel />} />
        )}
      </Pie>
    </PieC>
  );
};

export default PieChart;
