import { FC } from "react";

import Span from "@components/atoms/Span/Span";
import TitleH1 from "@components/atoms/TitleH1/TitleH1";

interface Props {
  errorTitle: string;
  errorContent: string;
}

const ErrorInfo: FC<Props> = ({ errorTitle, errorContent }) => {
  return (
    <div className="flex flex-col gap-3">
      <TitleH1
        text={errorTitle}
        color="text-gray-900"
        size="text-3xl"
        weight="font-semibold"
      />
      <Span
        text={errorContent}
        size="text-md sm:text-lg md:text-xl"
        align="text-justify"
        extraStyles="block leading-5 sm:leading-6"
      />
    </div>
  );
};

export default ErrorInfo;
