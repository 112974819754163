import { logout } from "@service/Auth.service";
import { deactiveClient } from "@service/Client.service";
import { removeCookie } from "@utils/Cookie";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Table } from "flowbite-react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import FloatMenu, {
  ButtonInfo,
} from "@components/molecules/FloatMenu/FloatMenu";

import DeactivateUser from "@components/organisms/Deactivate/DeactivateUser/DeactivateUser";

import { AuthContext } from "@providers/providers";

import styles from "./ClientRow.module.scss";

interface clientRow {
  nit: string;
  names: string;
  phoneNumber: string;
  email: string;
  companyLogo: string;
  companyName: string;
  accountantID: string;
  rol: string;
  active: boolean;
}

type Props = {
  data: clientRow;
  updateData: () => void;
};

const ClientRow = ({ data, updateData }: Props) => {
  const navigate = useNavigate();

  const { auth, setAuth } = useContext(AuthContext);

  const [optionsVisible, setOptionsVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const deactiveAnClient = async (dataValues: any) => {
    const idToast = toastLoading(
      `${data.active ? "Deshabilitando" : "Habilitando"} información...`
    );
    try {
      if (auth) {
        const result = await deactiveClient(data.nit, dataValues, auth!!.token);
        if (result) {
          if (updateData) updateData();
          toastUpdateLoading(
            idToast,
            `Cliente ${
              data.active ? "deshabilitado" : "habilitado"
            } exitosamente`,
            "success",
            "bell"
          );
        }
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 403,
          msg: `Error al ${
            data.active ? "deshabilitar" : "habilitar"
          } los datos del contador!`,
        },
      ]);

      if (error.response.status === 401) {
        await logout();
        removeCookie("auth", { path: "/" });
        setAuth(undefined);
      }
    } finally {
      setOptionsVisible(false);
      setOpenModal(false);
    }
  };

  const options: ButtonInfo[] = [
    {
      icon: "userEdit",
      text: "Ver perfil",
      extraStyles: undefined,
      onPress: () => navigate(`/clients/${data.nit}`),
    },
    {
      icon: data.active ? "eyeSlash" : "eye",
      text: `${data.active ? "Deshabilitar" : "Habilitar"} usuario`,
      extraStyles: `hover:bg-${
        data.active ? "red" : "green"
      }-200 hover:rounded-sm`,
      onPress: () => setOpenModal(true),
    },
  ];

  return (
    <>
      <Table.Row
        className={`relative h-[66px] border-y-[1px] border-solid border-gray-300 last:border-0 text-base text-left ${
          !data.active && "bg-gray-50 border-gray-200 opacity-70"
        }`}
      >
        <Table.Cell
          onClick={() => navigate(`/clients/${data.nit}`)}
          className={styles.nameContainer}
        >
          <div className="w-[90%] flex gap-10 justify-between">
            <div className="flex justify-start items-center text-base gap-3">
              <div className={styles.imgContainer}>
                <img
                  src={data.companyLogo}
                  alt=""
                  className="w-[57px] h-[57px] rounded-full"
                />
              </div>
              <div className={styles.Client}>
                <Span
                  text={data.companyName}
                  size="text-sm"
                  weight="font-normal"
                  align={"text-left"}
                />
              </div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell className=" flex flex-col items-start">
          <div className={styles.Contact}>
            <Span text={data.names} size={"text-sm"} align={"text-left"} />
            <Span
              text={data.rol}
              weight={"font-normal"}
              color="text-gray-600"
              size="text-sm"
            />
          </div>
        </Table.Cell>
        <Table.Cell>
          <Span text={data.phoneNumber} size="text-sm" />
        </Table.Cell>
        <Table.Cell>
          <div className={styles.Email}>
            <Span text={data.email} size="text-sm" />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div
            onBlur={() => setOptionsVisible(false)}
            className="flex items-center"
          >
            <button onClick={() => setOptionsVisible(!optionsVisible)}>
              <Icon icon={"dots"} size={5} />
            </button>
            <FloatMenu
              isOpen={optionsVisible}
              optionsFM={auth?.type === "admin" ? options : [options[0]]}
              extraS={{ width: "224px" }}
            />
          </div>
        </Table.Cell>
      </Table.Row>
      <DeactivateUser
        isDisabled={data.active}
        isOpenModal={openModal}
        setIsOpenModal={() => setOpenModal(!openModal)}
        name={data.names}
        id={data.nit}
        type={"cliente"}
        deactiveUserFunction={deactiveAnClient}
      />
    </>
  );
};

export default ClientRow;
