import { Recovery as RecoveryEntity, User } from "@entities/index";
import { appCookies, getCookie } from "@utils/Cookie";
import {
  startTokenValidationInterval,
  stopTokenValidationInterval,
} from "@utils/Token";
import { useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CookieChangeOptions } from "universal-cookie";

// import Cookies, { CookieChangeOptions } from "universal-cookie";
import ClientProfile from "@components/organisms/Profile/ClientProfile/ClientProfile";

import LayoutDashboard from "../presentation/templates/Layouts/LayoutDashboard";

import Accountant from "@pages/Accountant/Accountant";
import AccountantProfile from "@pages/Accountant/AccountantProfile/AccountantProfile";
import Clients from "@pages/Clients/Clients";
import Dashboard from "@pages/Dashboard/Dashboard";
import Documents from "@pages/Documents/Documents";
import EmailRecovery from "@pages/EmailRecovery/EmailRecovery";
import Error404 from "@pages/Errors/Error404/Error404";
import Login from "@pages/Login/Login";
import News from "@pages/News/News";
import Pqrs from "@pages/Pqrs/Pqrs";
import PqrsDetails from "@pages/Pqrs/PqrsDetails/PqrsDetails";
import Recovery from "@pages/Recovery/Recovery";
import RecoveryPassword from "@pages/RecoveryPassword/RecoveryPassword";
import Terms from "@pages/Terms/Terms";

import { AuthContext, RecoveryPasswordContext } from "@providers/providers";

import styles from "./App.module.scss";
import {
  Authenticated,
  IsAuthenticated,
  ProtectEmailRecovery,
  ProtectPassword,
  ProtectRecovery,
} from "./ProtectedRoutes";
import "./colors.variables.css";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const [auth, setAuth] = useState<User | undefined>(undefined);
  const [passwordRecovery, setPasswordRecovery] = useState<
    RecoveryEntity | undefined
  >(undefined);
  const navigate = useNavigate();
  const authCookie = getCookie("auth");

  useEffect(() => {
    const handleCookieChange = (cookie: CookieChangeOptions) => {
      setAuth(cookie.value);
    };
    appCookies.addChangeListener(handleCookieChange);
    return () => {
      appCookies.removeChangeListener(handleCookieChange);
    };
  }, []);

  useEffect(() => {
    if (authCookie) {
      stopTokenValidationInterval();
      startTokenValidationInterval();
      setAuth(authCookie);
    }
  }, []);

  useEffect(() => {
    if (passwordRecovery?.email && !passwordRecovery?.password) {
      return navigate("/recoverycode");
    } else if (passwordRecovery?.password) {
      return navigate("/newPassword");
    }
  }, [passwordRecovery, setPasswordRecovery]);

  const RecoveryProvider = () => {
    return (
      <RecoveryPasswordContext.Provider
        value={{ passwordRecovery, setPasswordRecovery }}
      >
        <Outlet />
      </RecoveryPasswordContext.Provider>
    );
  };

  const AuthProvider = () => {
    return (
      <AuthContext.Provider
        value={{
          auth,
          setAuth,
        }}
      >
        <Outlet />
      </AuthContext.Provider>
    );
  };

  return (
    <>
      <div className={styles.App}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          toastStyle={{
            backgroundColor: "var(--blue-deep)",
            color: "white",
          }}
        />
        <Routes>
          <Route element={<AuthProvider />}>
            <Route element={IsAuthenticated(authCookie)}>
              <Route path="/" element={<Login />} />
            </Route>
            <Route element={Authenticated(authCookie, auth)}>
              <Route path="/dashboard" element={LayoutDashboard(Dashboard)} />
              <Route path="/news" element={LayoutDashboard(News)} />
              <Route
                path="/accountants"
                element={LayoutDashboard(Accountant)}
              />
              <Route
                path="/accountants/:id"
                element={LayoutDashboard(AccountantProfile)}
              />
              <Route path="/pqrs" element={LayoutDashboard(Pqrs)} />
              <Route path="/pqrs/:id" element={LayoutDashboard(PqrsDetails)} />
              <Route path="/clients" element={LayoutDashboard(Clients)} />
              <Route
                path="/clients/:clientId/*"
                element={LayoutDashboard(ClientProfile)}
              />
              <Route path="/documents" element={LayoutDashboard(Documents)} />

              <Route
                path="/profile/*"
                element={LayoutDashboard(
                  auth?.type === "client" ? ClientProfile : AccountantProfile
                )}
              />
              <Route
                path="/profile-acc/:id"
                element={LayoutDashboard(AccountantProfile)}
              />
              <Route path="/terms" element={LayoutDashboard(Terms)} />
            </Route>
          </Route>
          <Route element={<RecoveryProvider />}>
            <Route element={IsAuthenticated(authCookie)}>
              <Route element={ProtectEmailRecovery(auth)}>
                <Route path="/recovery" element={<EmailRecovery />} />
              </Route>
              <Route element={ProtectRecovery(passwordRecovery)}>
                <Route path="/recoverycode" element={<Recovery />} />
              </Route>
              <Route element={ProtectPassword(passwordRecovery)}>
                <Route path="/newPassword" element={<RecoveryPassword />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Error404 />} />
        </Routes>
      </div>
    </>
  );
}

export { App as default, appCookies };
