import { Field } from "formik";
import { ChangeEvent, FC } from "react";

import styles from "./InputRecovery.module.scss";

interface Props {
  isError?: boolean;
  name: string;
  onChange?: (val: string) => void;
  placeholder?: string;
  type: string;
}

const InputRecovery: FC<Props> = ({
  isError,
  name,
  type,
  placeholder,
  onChange,
}) => {
  const errorStyle = isError
    ? {
        borderColor: "red",
        borderWidth: 2,
      }
    : {};

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 1) {
      onChange?.(value);
    }
  };

  return (
    <>
      <div className={styles.InputRecoveryContainer}>
        <div>
          <Field
            className={styles.InputRecovery}
            id={name}
            min={0}
            max={9}
            maxLength={1}
            name={name}
            onChange={handleChange}
            placeholder={placeholder}
            style={errorStyle}
            type={type}
          />
        </div>
      </div>
    </>
  );
};

export default InputRecovery;
