import { fetching } from "@entities/Fetching.entity";
import { getFoldersHistory } from "@utils/Documents";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Pagination from "@components/atoms/Pagination/Pagination";
import Span from "@components/atoms/Span/Span";

import CustomTable from "@components/molecules/CustomTable/CustomTable";
import DeletedDocumentRow from "@components/molecules/Row/DocumentRow/DeletedDocumentRow";
import DocumentRow from "@components/molecules/Row/DocumentRow/DocumentRow";
import SearchBar from "@components/molecules/SearchBar/SearchBar";
import TableTitle from "@components/molecules/TableTitle/TableTitle";

import AddDocument from "@components/organisms/Add/AddDocument/AddDocument";
import AddFolder from "@components/organisms/Add/AddFolder/AddFolder";

import { AuthContext } from "@providers/providers";

import {
  useDeletedDocuments,
  useMatchingDocuments,
  useUserDocuments,
} from "@hooks/Documents/useDocuments";

import styles from "./DocumentsTable.module.scss";

type Props = {
  uploadDoc?: boolean;
  createFolder?: boolean;
};

const DocumentsTable = ({ uploadDoc = true, createFolder = true }: Props) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const folderHistory = getFoldersHistory(auth, location.pathname.split("/"));
  const [openFileModal, setOpenFileModal] = useState(false);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [showDeletedFiles, setShowDeletedFiles] = useState(false);
  const [
    userDocuments,
    userDocumentsFetchingState,
    userDocumentsCurrentPage,
    setUserDocumentsCurrentPage,
    totalUserDocuments,
    getUserDocuments,
  ] = useUserDocuments();
  const [
    deletedDocuments,
    deletedDocumentsFetchingState,
    deletedDocumentsCurrentPage,
    setDeletedDocumentsCurrentPage,
    totalDeletedDocuments,
    getDeletedDocuments,
  ] = useDeletedDocuments();
  const [
    matchingDocuments,
    matchingDocumentsFetchingState,
    searchParam,
    setSearchParam,
    getMatchingDocuments,
  ] = useMatchingDocuments();

  const renderCurrentDocumentsList = () => {
    if (!showDeletedFiles && !matchingDocuments) {
      return renderUserDocumentsList();
    } else if (!showDeletedFiles && matchingDocuments) {
      return renderMatchingDocumentsList();
    } else if (showDeletedFiles) {
      return renderDeletedDocumentsList();
    }
  };

  const renderUserDocumentsList = () => {
    switch (userDocumentsFetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (userDocuments?.length === 0) {
          return (
            <div className="p-36 flex justify-center items-center flex-col gap-10">
              <Icon icon={"file"} size={20} />
              <Span
                text={"Carpeta vacía"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <CustomTable
            columns={[
              "NOMBRE",
              "FECHA DE CREACIÓN",
              "TIPO",
              "FECHA DE VENCIMIENTO",
              "ESTADO",
              "VISTO/NO",
            ]}
            Row={DocumentRow}
            data={matchingDocuments ?? userDocuments}
            updateData={getUserDocuments}
          />
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  const renderDeletedDocumentsList = () => {
    switch (deletedDocumentsFetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (deletedDocuments?.length === 0) {
          return (
            <div className="p-36 flex justify-center items-center flex-col gap-10">
              <Icon icon={"file"} size={20} />
              <Span
                text={"No hay archivos eliminados"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <CustomTable
            columns={[
              "NOMBRE",
              "FECHA DE ELIMINACION",
              "TIPO",
              "ELIMINADO POR",
            ]}
            Row={DeletedDocumentRow}
            data={deletedDocuments}
            updateData={() => getDeletedDocuments()}
          />
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  const renderMatchingDocumentsList = () => {
    switch (matchingDocumentsFetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (matchingDocuments?.length === 0) {
          return (
            <div className="p-36 flex justify-center items-center flex-col gap-10">
              <Icon icon={"file"} size={20} />
              <Span
                text={"No se encontraron documentos"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <CustomTable
            columns={[
              "NOMBRE",
              "FECHA DE CREACIÓN",
              "TIPO",
              "FECHA DE VENCIMIENTO",
              "ESTADO",
              "VISTO/NO",
            ]}
            Row={DocumentRow}
            data={matchingDocuments}
            updateData={getMatchingDocuments}
          />
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  const renderFoldersHistory = () => {
    if (!showDeletedFiles && !matchingDocuments) {
      return (
        <div
          className={`${styles.historyContainer} flex justify-center items-center`}
        >
          {folderHistory.map(
            (folder: { name: string; route: string }, index: number) => (
              <div className="flex justify-center items-center" key={index}>
                {index > 0 && (
                  <div className="mx-4">
                    <Icon icon={"angleRight"} size={3} />
                  </div>
                )}
                <Link
                  to={folder.route}
                  className={`w-max text-lg ${
                    index === folderHistory.length - 1
                      ? "font-semibold"
                      : "font-normal hover:underline"
                  }`}
                >
                  {decodeURI(folder.name)}
                </Link>
              </div>
            )
          )}
        </div>
      );
    } else if (!showDeletedFiles && matchingDocuments) {
      return (
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center">
            <Link
              to={location.pathname.split("/").slice(0, 3).join("/")}
              className="text-lg font-normal hover:underline"
            >
              Inicio
            </Link>
            <div className="mx-4">
              <Icon icon={"angleRight"} size={3} />
            </div>
            <Span
              text={"Resultados"}
              size="text-lg"
              weight="font-semibold"
              extraStyles="cursor-pointer"
            />
          </div>
        </div>
      );
    } else if (showDeletedFiles) {
      return (
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center">
            <Link
              to={location.pathname.split("/").slice(0, 3).join("/")}
              className="text-lg font-normal hover:underline"
            >
              Inicio
            </Link>
            <div className="mx-4">
              <Icon icon={"angleRight"} size={3} />
            </div>
            <Span
              text={"Eliminados"}
              size="text-lg"
              weight="font-semibold"
              extraStyles="cursor-pointer"
            />
          </div>
        </div>
      );
    }
  };

  const renderPaginationBar = () => {
    if (!showDeletedFiles && !matchingDocuments && totalUserDocuments > 20) {
      return (
        <Pagination
          currentPage={userDocumentsCurrentPage}
          setCurrentPage={setUserDocumentsCurrentPage}
          totalPages={Math.ceil(totalUserDocuments / 20)}
        />
      );
    } else if (!showDeletedFiles && matchingDocuments) {
      return "";
    } else if (showDeletedFiles && totalDeletedDocuments > 20) {
      return (
        <Pagination
          currentPage={deletedDocumentsCurrentPage}
          setCurrentPage={setDeletedDocumentsCurrentPage}
          totalPages={Math.ceil(totalDeletedDocuments / 20)}
        />
      );
    }
  };

  useEffect(() => {
    if (auth) {
      getUserDocuments();
    }
  }, [auth, userDocumentsCurrentPage]);

  useEffect(() => {
    if (showDeletedFiles) {
      getDeletedDocuments();
    }
  }, [showDeletedFiles, deletedDocumentsCurrentPage]);

  return (
    <div className="bg-white mb-10 rounded-lg">
      <TableTitle icon={"file"} title={"Documentos"} />
      <div className="my-4">
        <SearchBar
          searchValue={searchParam}
          setSearchValue={setSearchParam}
          handleSearch={() => {
            getMatchingDocuments();
            setShowDeletedFiles(false);
          }}
          setOpenModal={setOpenFileModal}
          openModal={openFileModal}
          buttonIcon={"addFile"}
        />

        <div className={`${styles.btns} w-full px-[16px] py-[8.5px]`}>
          <div className={`${styles.docsBtns} flex flex-row gap-3`}>
            {uploadDoc && (
              <Button
                text={"Subir documento"}
                type={"button"}
                onClick={() => setOpenFileModal(!openFileModal)}
                iconName={"addFile"}
                iconColor={"white"}
                iconSize={4}
                width="w-[149px]"
                height="h-9"
                bgColor="bg-gray-800 hover:bg-gray-900"
                padding="px-0 py-0"
                extraStyles="text-xs rounded-[6px] font-extralight focus:ring-4 focus:outline-none focus:ring-gray-300"
              />
            )}

            {createFolder && (
              <Button
                text={"Nueva Carpeta"}
                type={"button"}
                onClick={() => setOpenFolderModal(!openFolderModal)}
                iconName={"addFolder"}
                iconColor={"gray-800"}
                iconSize={4}
                width="w-[149px]"
                height="h-9"
                textColor="text-gray-800"
                bgColor="bg-gray-200 hover:bg-gray-300"
                padding="px-3 py-1"
                extraStyles="text-xs rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
              />
            )}
          </div>
          <div className={styles.historyContainer}>
            {renderFoldersHistory()}
          </div>
          {auth?.type === "admin" && (
            <Button
              text={"Ver eliminados"}
              type={"button"}
              onClick={() => setShowDeletedFiles(!showDeletedFiles)}
              iconName={"trash"}
              iconColor={"red-600"}
              iconSize={4}
              width="w-[149px]"
              height="h-9"
              textColor="text-gray-800"
              bgColor={`${
                showDeletedFiles
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-white hover:bg-gray-50"
              }`}
              padding="px-3 py-1"
              extraStyles={`${styles.btnDelete} ${
                showDeletedFiles ? "text-white" : "text-red-600"
              } text-xs rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300 border-red-600 border-2 border-solid`}
            />
          )}
        </div>
      </div>
      {renderCurrentDocumentsList()}
      {renderPaginationBar()}
      <AddFolder
        isOpenModal={openFolderModal}
        setIsOpenModal={() => setOpenFolderModal(!openFolderModal)}
        updateData={getUserDocuments}
      />
      <AddDocument
        isOpenModal={openFileModal}
        setIsOpenModal={() => setOpenFileModal(!openFileModal)}
        updateData={getUserDocuments}
      />
    </div>
  );
};

export default DocumentsTable;
