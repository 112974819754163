import { fetching } from "@entities/Fetching.entity";
import { Banner } from "flowbite-react";
import { FC, useCallback, useContext, useState } from "react";
import { MdAnnouncement } from "react-icons/md";
import { useParams } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import ProfileUser from "@components/atoms/ProfileUser/ProfileUser";
import Span from "@components/atoms/Span/Span";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

import AddPqrs from "@components/organisms/Add/AddPqrs/AddPqrs";
import ClientsTable from "@components/organisms/Table/ClientsTable/ClientsTable";

import { AuthContext } from "@providers/providers";

import { useAccountantById } from "@hooks/Accountants/useAccountants";

import EditAccountant from "../../Edit/EditAccountant/EditAccountant";
import styles from "./AccountantProfile.module.scss";

interface Props {}

const EditUserButton = ({ onClick }: any) => {
  return (
    <Button
      text={"Editar Perfil"}
      type={"button"}
      iconName="edit"
      iconColor="white"
      iconSize={4}
      width="w-18"
      height="h-9"
      padding="px-3 py-1"
      bgColor="bg-gray-800 hover:bg-gray-900"
      extraStyles="font-medium text-xs d-flex align-center"
      onClick={onClick}
    />
  );
};

const AccountantProfile: FC<Props> = () => {
  const { id } = useParams();
  const { auth } = useContext(AuthContext);

  const idUser = auth?.type !== "accountant" ? id : auth?.id.toString();

  const [
    accountant,
    fetchingState,
    updateProfilePicture,
    updateProfileInfo,
    updateData,
  ] = useAccountantById(idUser!!);
  const [openModal, setOpenModal] = useState(false);

  const renderAccountantProfile = useCallback((): JSX.Element => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        return (
          <>
            {!accountant?.active && (
              <Banner>
                <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                  <div className="mx-auto flex items-center">
                    <MdAnnouncement className="mr-4 h-4 w-4" color="#4B5563" />
                    <Span
                      text="Este perfil se encuentra deshabilitado"
                      size={"text-sm"}
                      extraStyles={"text-gray-500 dark:text-gray-400"}
                    />
                  </div>
                </div>
              </Banner>
            )}
            <div className={styles.profileContainer}>
              <ProfileUser
                name={`${accountant?.names} ${accountant?.surnames}`}
                profileP={accountant?.profilePicture as string}
                updateProfilePFunction={updateProfilePicture}
                isEditablePicture={
                  auth?.type !== "client" && accountant?.active
                }
              />
              <ProfileUser
                id={accountant?.id?.toString()}
                email={accountant?.email}
                phoneNumber={accountant?.phoneNumber}
                isImgProfile={false}
              />
            </div>
            {auth?.type !== "client" && (
              <ClientsTable
                canAddClient={accountant ? accountant?.active : true}
                searchByAccId={auth?.type === "admin" ? id : undefined}
                idAccountant={idUser ? parseInt(idUser) : undefined}
              />
            )}
            {auth?.type === "client" && accountant?.active && (
              <div className={"p-8"}>
                <AddPqrs
                  clientId={auth.nit}
                  accountantId={parseInt(idUser!!)}
                />
              </div>
            )}
          </>
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  }, [fetchingState, updateProfilePicture, updateProfileInfo]);

  return (
    <>
      <InfoContainer
        iconName={"user"}
        title={"Perfil contador"}
        RightSideInfo={
          auth?.type !== "client" &&
          accountant?.active && <EditUserButton onClick={setOpenModal} />
        }
        goBack={auth?.type === "accountant" ? false : true}
      >
        {renderAccountantProfile()}
      </InfoContainer>
      {auth?.type !== "client" && accountant && (
        <EditAccountant
          editProfileInfo={updateProfileInfo}
          updateData={updateData}
          isOpenModal={openModal}
          setIsOpenModal={() => {
            setOpenModal(!openModal);
          }}
          names={accountant.names}
          surnames={accountant.surnames}
          phoneNumber={accountant.phoneNumber.split(" ")[1]}
          prefix={accountant.phoneNumber.split(" ")[0]}
        />
      )}
    </>
  );
};

export default AccountantProfile;
