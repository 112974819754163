import { deactiveAccountant } from "@service/Accountant.service";
import { logout } from "@service/Auth.service";
import { removeCookie } from "@utils/Cookie";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { FC, memo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Icon, { IconNames } from "@components/atoms/Icons/Icon";
import SearchInput from "@components/atoms/SearchInput/SearchInput";
import Span from "@components/atoms/Span/Span";

import DeactivateUser from "@components/organisms/Deactivate/DeactivateUser/DeactivateUser";

import { AuthContext } from "@providers/providers";

import FloatMenu from "../FloatMenu/FloatMenu";
import styles from "./AccountantCard.module.scss";

interface Props {
  openSearch?: boolean;
  error?: string;
  id: number;
  searchValue?: string;
  setSearchValue?: (val: string) => void;
  img: any;
  name: string;
  email: string;
  phoneNumber: string;
  children?: React.ReactNode;
  label?: string;
  openOptions?: boolean;
  updateData?: () => void;
  cardEmpty?: string;
  active?: boolean;
}

interface ButtonInfo {
  icon: IconNames;
  text: string;
  extraStyles: string | undefined;
  onPress: () => void;
}

const AccountantCard: FC<Props> = ({
  openSearch = false,
  error,
  searchValue,
  setSearchValue,
  id,
  img,
  name,
  email,
  phoneNumber,
  children,
  label,
  updateData,
  cardEmpty,
  active = true,
}) => {
  const { auth, setAuth } = useContext(AuthContext);

  const [openOptions, setOpenOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const deactiveAnAccountant = async (data: any) => {
    const idToast = toastLoading(
      `${active ? "Deshabilitando" : "Habilitando"} información...`
    );
    try {
      if (auth) {
        const result = await deactiveAccountant(
          id.toString(),
          data,
          auth!!.token
        );
        if (result) {
          if (updateData) updateData();
          toastUpdateLoading(
            idToast,
            `Contador ${active ? "deshabilitado" : "habilitado"} exitosamente`,
            "success",
            "bell"
          );
        }
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 403,
          msg: `Error al ${
            active ? "deshabilitar" : "habilitar"
          } los datos del contador!`,
        },
      ]);
      if (error.response.status === 401) {
        await logout();
        removeCookie("auth", { path: "/" });
        setAuth(undefined);
      }
    } finally {
      setOpenOptions(false);
      setOpenModal(false);
    }
  };

  const options: ButtonInfo[] = [
    {
      icon: "userEdit",
      text: "Editar perfil",
      extraStyles: undefined,
      onPress: () => navigate(`/accountants/${id}`),
    },
    {
      icon: active ? "eyeSlash" : "eye",
      text: `${active ? "Deshabilitar" : "Habilitar"} usuario`,
      extraStyles: `hover:bg-${active ? "red" : "green"}-200 hover:rounded-sm`,
      onPress: () => setOpenModal(true),
    },
  ];

  return (
    <>
      <div
        className={styles.container}
        style={{
          width: label ? "100%" : "470px",
          height: label ? "100%" : "134px",
          padding: label ? "25px" : "11px",
          backgroundColor: active ? "white" : "rgb(248 242 242)",
        }}
      >
        <div className={styles.cardContainer}>
          <div
            className={styles.imgContainer}
            style={{ opacity: active ? 1 : 0.7 }}
          >
            {cardEmpty ? (
              <div />
            ) : img ? (
              <img
                src={img}
                alt={name}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/accountants/${id}`)}
              />
            ) : (
              <div />
            )}
          </div>
          <div
            className={`${styles.infoContainer} whitespace-nowrap sm:whitespace-normal`}
            style={{ opacity: active ? 1 : 0.7 }}
          >
            {cardEmpty ? (
              <Span text={cardEmpty} />
            ) : (
              <>
                {label && <Span text={label} />}
                <Span
                  size={"text-xl sm:text-2xl"}
                  weight={"font-normal"}
                  color={"text-gray-900"}
                  text={name}
                  align={"text-left"}
                  withTextOverflow={false}
                />
                <Span
                  size={"sm:text-sm"}
                  weight={"font-light"}
                  color={"text-gray-700"}
                  text={email}
                  align={"text-left"}
                  extraStyles={styles.span}
                />
                <Span
                  size={"sm:text-sm"}
                  color={"text-blue-800"}
                  weight={"font-normal"}
                  text={phoneNumber}
                  align={"text-left"}
                  withTextOverflow={false}
                />
              </>
            )}
          </div>
          <div
            className={styles.childrenContainer}
            style={{
              width: !children ? "10%" : undefined,
              justifyContent: !children ? "initial" : "center",
            }}
          >
            {children ? (
              children
            ) : (
              <div onBlur={() => setOpenOptions(false)}>
                <button
                  className={styles.iconContainer}
                  onClick={() => setOpenOptions(!openOptions)}
                >
                  <Icon icon={"dotsHorizontal"} />
                </button>
                <FloatMenu
                  isOpen={openOptions}
                  optionsFM={options}
                  extraS={{ top: 25, width: "224px" }}
                />
              </div>
            )}
          </div>
        </div>
        {openSearch && (
          <div className="flex flex-col gap-2">
            <SearchInput
              value={searchValue!!}
              setValue={setSearchValue!!}
              handleSearch={() => setSearchValue}
              withBtn={false}
            />
          </div>
        )}
        {error && <div className="w-full text-red-500 text-sm">{error}</div>}
      </div>
      {
        <DeactivateUser
          isDisabled={active}
          isOpenModal={openModal}
          setIsOpenModal={() => setOpenModal(!openModal)}
          name={name}
          id={id.toString()}
          type={"contador"}
          deactiveUserFunction={deactiveAnAccountant}
        />
      }
    </>
  );
};

export default memo(AccountantCard);
