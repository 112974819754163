import { Pqrs } from "@entities/Pqrs.entity";
import { Table } from "flowbite-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

interface Props {
  data: Pqrs;
}

const PQRsRowAccountant: FC<Props> = ({ data }: Props) => {
  const navigate = useNavigate();

  const redirectToDetails = (id: string) => {
    navigate(`/pqrs/${id}`);
  };

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
  };

  return (
    <Table.Row className="h-[120px] bg-white dark:border-gray-700 dark:bg-gray-800 font-inter text-black border-y-[1px] border-solid border-gray-300">
      <Table.Cell className="w-[2%] font-light">{data.id}</Table.Cell>
      <Table.Cell className="w-[380px]">
        <Span
          text={data.title}
          weight="font-normal"
          color="text-blue-600"
          size="text-base"
          align="text-left"
          extraStyles="line-clamp-1"
        />
        <Span
          text={new Date(data.creationDate).toLocaleDateString(
            "es-co",
            dateOptions
          )}
          weight={"font-light"}
          size="text-sm"
        />
      </Table.Cell>
      <Table.Cell className="w-[300px] font-normal">
        <div className="flex justify-start items-center text-base gap-3">
          <img
            src={
              typeof data.clientId === "object"
                ? (data.clientId.companyLogo as string)
                : undefined
            }
            alt=""
            className="w-[57px] h-[57px] rounded-full"
          />
          {typeof data.clientId === "object"
            ? `${data.clientId.names} ${data.clientId.surnames}`
            : undefined}
        </div>
      </Table.Cell>
      <Table.Cell className="w-[10%] font-normal">
        <div className="flex justify-center lg:justify-start items-center text-base gap-8">
          <div className="w-auto flex flex-col items-center gap-2">
            <div
              className={`${
                data.status === "abierta" ? "bg-teal-200" : "bg-red-200"
              } py-1 px-4 rounded-lg capitalize text-center`}
            >
              <Span
                text={data.status ? data.status : "abierta"}
                weight={"font-bold"}
                size="text-xs sm:text-base"
                color={
                  data.status === "abierta" ? "text-blue-600" : "text-red-600"
                }
              />
            </div>
            <Button
              text={"Ver"}
              type={"button"}
              width="w-auto"
              padding="py-1.5"
              extraStyles="rounded-[5px] text-xs px-5"
              onClick={() => redirectToDetails(data.id.toString())}
            />
          </div>
          <div onClick={() => redirectToDetails(data.id.toString())}>
            {data.updatedByClient ? (
              <div className="relative">
                <div className="absolute w-2.5 h-2.5 rounded-full bg-red-600 top-0 right-0.5"></div>
                <Icon icon={"bellOutline"} size={7} />
              </div>
            ) : (
              <Icon icon={"bellOutline"} color="#9CA3AF" size={7} />
            )}
          </div>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default PQRsRowAccountant;
