import React from "react";

import Image from "@components/atoms/Image/Image";

import ErrorCode from "@components/molecules/ErrorCode/ErrorCode";
import ErrorInfo from "@components/molecules/ErrorInfo/ErrorInfo";

type Props = {
  code: string;
  title: string;
  text: string;
  img: string;
};

const Error = ({ title, text, img, code }: Props) => {
  return (
    <div className="w-[100%] flex justify-center overflow-y-hidden">
      <div className="px-6 xl:px-72 lg:px-56 md:px-24 pb-48 relative h-screen flex flex-col justify-center items-center xl:w-[80%] md:pb-64">
        <ErrorCode error={code} />
        <ErrorInfo errorTitle={title} errorContent={text} />
        <Image
          width="w-[70%] sm:w-[60%] md:w-[45%]"
          alt="Robot"
          extraStyles="absolute bottom-20 lg:-bottom-10"
          source={img}
        />
      </div>
    </div>
  );
};

export default Error;
