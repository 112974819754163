import { FC } from "react";
import { align, size, weight } from "types/fonts";

interface Props {
  text: string;
  size?: size;
  weight?: weight;
  color?: string;
  align?: align;
}

const TitleH2: FC<Props> = ({
  text,
  size = "text-2xl",
  weight = "font-normal",
  color = "text-black",
  align = "text-center",
}) => {
  return <h2 className={`${size} ${weight} ${color} ${align}`}>{text}</h2>;
};

export default TitleH2;
