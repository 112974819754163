import RecoveryPassword from "@components/organisms/Recovery/RecoveryPassword/RecoveryPassword";

import styles from "./RecoveryPassword.module.scss";

const RecoveryPasswordTemplate = () => {
  return (
    <>
      <div className={styles.RecoveryPassword}>
        <RecoveryPassword />
      </div>
    </>
  );
};

export default RecoveryPasswordTemplate;
