import { Accountant } from "@entities/Accountant.entity";
import { addAccountant } from "@service/Accountant.service";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import {
  validateDocument,
  validateEmailDomain,
  validateLength,
} from "@utils/Validations";
import { Form, Formik } from "formik";
import { FC, useContext } from "react";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import InputPhoneNumber from "@components/atoms/InputFormik/InputPhoneNumber/InputPhoneNumber";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

import { errorMessage } from "../../../../../utils/Status";
import styles from "./AddAccountant.module.scss";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  updateData?: () => void;
}

interface AddAccountantEntity {
  email: string;
  id: string;
  names: string;
  surnames: string;
  phoneNumber: string;
  prefix: string;
  profilePicture: File | undefined;
}

const AddAccountant: FC<Props> = ({
  isOpenModal,
  setIsOpenModal,
  updateData,
}) => {
  const { auth } = useContext(AuthContext);

  const initialValues: AddAccountantEntity = {
    email: "",
    id: "",
    names: "",
    surnames: "",
    phoneNumber: "",
    prefix: "",
    profilePicture: undefined,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email no válido")
      .required("Este campo es obligatorio"),
    id: Yup.number().required("Este campo es obligatorio"),
    names: Yup.string().required("Este campo es obligatorio"),
    surnames: Yup.string().required("Este campo es obligatorio"),
    phoneNumber: Yup.number().required("Este campo es obligatorio"),
    profilePicture: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: AddAccountantEntity) => {
    const data: Pick<
      Accountant,
      "id" | "email" | "names" | "phoneNumber" | "surnames" | "profilePicture"
    > = {
      id: values.id,
      email: values.email,
      names: values.names,
      surnames: values.surnames,
      profilePicture: values.profilePicture,
      phoneNumber: `${values.prefix} ${values.phoneNumber}`,
    };
    const idToast = toastLoading("Subiendo información...");
    try {
      const result = await addAccountant(data, auth!!.token);
      if (result) {
        if (updateData) updateData();
        toastUpdateLoading(
          idToast,
          "Contador añadido exitosamente",
          "success",
          "bell"
        );
        setIsOpenModal();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al añadir al contador!",
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        title={"Añadir contador"}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      >
        <Formik
          className={styles.formik}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className={styles.formContainer}>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Nombres"}
                    />
                    <Input name={"names"} type={"text"} />
                  </div>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Apellidos"}
                    />
                    <Input name={"surnames"} type={"text"} />
                  </div>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Email"}
                    />
                    <Input
                      name={"email"}
                      type={"email"}
                      validation={validateEmailDomain}
                    />
                  </div>
                  <div className={styles.formField}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Número de documento"}
                    />
                    <Input
                      name={"id"}
                      type={"number"}
                      validation={validateDocument}
                    />
                  </div>
                </div>
                <InputPhoneNumber
                  prefix={"prefix"}
                  name={"phoneNumber"}
                  type={"number"}
                  setValue={setFieldValue}
                  validation={validateLength}
                />
                <InputFile
                  label={"Foto"}
                  value={values.profilePicture?.name}
                  setValue={setFieldValue}
                  id={"profilePicture"}
                  accept={".jpg, .png"}
                />
                <div className={styles.btnsContainer}>
                  <Button
                    height="h-10"
                    text={"Añadir"}
                    type={"submit"}
                    bgColor="bg-gray-800 hover:bg-gray-900"
                    extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                    padding="px-1 py-1"
                  />
                  <Button
                    height="h-10"
                    text={"Cancelar"}
                    onClick={setIsOpenModal}
                    type={"button"}
                    bgColor="bg-red-800 hover:bg-red-900"
                    extraStyles="text-sm rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
                    padding="px-1 py-1"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddAccountant;
