import AddDate from "./AddDate";
import AddFile from "./AddFile";
import AddFolder from "./AddFolder";
import AddUser from "./AddUser";
import AngleRight from "./AngleRight";
import ArrowCompleteDown from "./ArrowCompleteDown";
import ArrowCompleteUp from "./ArrowCompleteUp";
import ArrowDown from "./ArrowDown";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import ArrowRightBracket from "./ArrowRightBracket";
import Bars from "./Bars";
import Bell from "./Bell";
import BellOutline from "./BellOutline";
import Book from "./Book";
import Calendar from "./Calendar";
import CameraDevice from "./CameraDevice";
import CaretLeft from "./CaretLeft";
import CaretRight from "./CaretRight";
import CaretSort from "./CaretSort";
import CheckCircle from "./CheckCircle";
import Close from "./Close";
import CloseCircle from "./CloseCircle";
import Dots from "./Dots";
import DotsHorizontal from "./DotsHorizontal";
import Edit from "./Edit";
import EditDocument from "./EditDocument";
import Envelope from "./Envelope";
import Eye from "./Eye";
import EyeSlash from "./EyeSlash";
import File from "./File";
import FileCopy from "./FileCopy";
import FileEdit from "./FileEdit";
import FileExport from "./FileExport";
import FileLines from "./FileLines";
import Gear from "./Gear";
import GridPlus from "./GridPlus";
import Headset from "./Headset";
import Home from "./Home";
import Inbox from "./Inbox";
import Minus from "./Minus";
import Newspaper from "./Newspaper";
import Phone from "./Phone";
import ProfileCard from "./ProfileCard";
import searchLoop from "./SearchLoop";
import Settings from "./Settings";
import Trash from "./Trash";
import TrashBin from "./TrashBin";
import User from "./User";
import UserCicle from "./UserCicle";
import UserEdit from "./UserEdit";
import Users from "./Users";
import UsersGroup from "./UsersGroup";

const listIcons = {
  addFolder: AddFolder,
  addUser: AddUser,
  arrowCompleteUp: ArrowCompleteUp,
  arrowCompleteDown: ArrowCompleteDown,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowRightBracket: ArrowRightBracket,
  bars: Bars,
  bell: Bell,
  book: Book,
  calendar: Calendar,
  cameraDevice: CameraDevice,
  caretSort: CaretSort,
  checkCircle: CheckCircle,
  close: Close,
  closeCircle: CloseCircle,
  dotsHorizontal: DotsHorizontal,
  envelope: Envelope,
  eye: Eye,
  eyeSlash: EyeSlash,
  fileCopy: FileCopy,
  headset: Headset,
  home: Home,
  inbox: Inbox,
  minus: Minus,
  phone: Phone,
  profileCard: ProfileCard,
  searchloop: searchLoop,
  settings: Settings,
  trash: Trash,
  user: User,
  userEdit: UserEdit,
  userCicle: UserCicle,
  users: Users,
  usersgroup: UsersGroup,
  editDoc: EditDocument,
  edit: Edit,
  file: File,
  addFile: AddFile,
  dots: Dots,
  fileEdit: FileEdit,
  fileExport: FileExport,
  gear: Gear,
  angleRight: AngleRight,
  bellOutline: BellOutline,
  newsPaper: Newspaper,
  gridPlus: GridPlus,
  trashBin: TrashBin,
  fileLines: FileLines,
  caretLeft: CaretLeft,
  caretRight: CaretRight,
  addDate: AddDate,
};

export type IconNames = keyof typeof listIcons;

interface Props {
  color?: string;
  size?: number;
  icon: IconNames;
}

const Icon = ({ color = "black", size = 6, icon }: Props) => {
  const IconRender = listIcons[icon];
  const stylesFW = `w-${size} h-${size}`;
  return (
    <>
      <IconRender style={stylesFW} color={color} />
    </>
  );
};

export default Icon;
