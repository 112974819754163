import React, { FC, memo } from "react";

import Icon, { IconNames } from "../Icons/Icon";

interface Props {
  iconName?: IconNames;
  iconColor?: string;
  iconSize?: number;
  text: string;
  type: "submit" | "button" | "reset";
  onClick?: () => void;
  weight?: string;
  width?: string;
  height?: string;
  textColor?: string;
  border?: string;
  bgColor?: string;
  padding?: string;
  justifyContent?: string;
  extraStyles?: string;
  extraS?: React.CSSProperties;
  iconLeft?: boolean;
}

const Button: FC<Props> = ({
  iconName,
  iconColor,
  iconSize,
  text,
  type = "submit",
  onClick,
  weight = "font-semibold",
  width = "w-full",
  height,
  textColor = "text-white",
  border,
  bgColor = "bg-blue-800 hover:bg-blue-900",
  padding = "py-3",
  justifyContent = "justify-center",
  extraStyles = "rounded-xl",
  extraS,
  iconLeft = true,
}) => {
  return (
    <button
      type={type}
      className={`${weight} ${width} ${height} ${textColor} ${bgColor} ${padding} ${extraStyles} ${border} rounded-xl font-inter flex ${justifyContent} items-center`}
      onClick={onClick}
      style={{
        gap: 10,
        ...extraS,
      }}
    >
      {iconLeft && iconName && (
        <Icon icon={iconName} color={iconColor} size={iconSize} />
      )}
      {text}
      {!iconLeft && iconName && (
        <Icon icon={iconName} color={iconColor} size={iconSize} />
      )}
    </button>
  );
};

export default memo(Button);
