import { Modal as ModalFW } from "flowbite-react";
import { FC } from "react";

import Icon from "../Icons/Icon";
import Span from "../Span/Span";
import styles from "./Modal.module.scss";

interface Props {
  children: React.ReactNode;
  title: string;
  secondaryTitle?: string;
  isOpenModal: boolean;
  setIsOpenModal: () => void;
  onPress?: () => void;
  bgColor?: string;
  textColor?: string;
}

const Modal: FC<Props> = ({
  children,
  title,
  isOpenModal,
  setIsOpenModal,
  secondaryTitle,
  onPress,
  bgColor,
  textColor,
}) => {
  return (
    <>
      <ModalFW
        dismissible
        show={isOpenModal}
        size={"4xl"}
        popup
        onClose={setIsOpenModal}
      >
        <div className={styles.header} style={{ backgroundColor: bgColor }}>
          <h3
            className="text-xl font-medium text-gray-900 dark:text-white"
            style={{ color: textColor }}
          >
            {title}
          </h3>
          <div className="flex flex-row items-center gap-[10px]">
            {secondaryTitle && (
              <Span
                text={secondaryTitle}
                size={"text-xl"}
                weight={"font-thin"}
              />
            )}
            <button onClick={setIsOpenModal}>
              <Icon icon={"close"} color={textColor} size={4} />
            </button>
          </div>
        </div>
        <hr />
        <ModalFW.Body className={styles.body}>
          <div className="space-y-6 pt-4">{children}</div>
        </ModalFW.Body>
      </ModalFW>
    </>
  );
};

export default Modal;
