import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import DeleteNew from "@components/organisms/Delete/DeleteNew/DeleteNew";

import { AuthContext } from "@providers/providers";

type Props = {
  id: number;
  title: string;
  img: string;
  url: string;
  date: string;
  updateData: () => void;
  updateUrlData: () => void;
  updatePaging: () => void;
};

const UrlNew = ({
  id,
  title,
  img,
  url,
  date,
  updateData,
  updateUrlData,
  updatePaging,
}: Props) => {
  const { auth } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="xl:w-[30%] sm:w-[45%] w-full h-[335px] rounded-xl shadow-xl">
      <div className="w-full h-[40%] overflow-hidden rounded-t-xl">
        <Link to={url} target="_blank">
          <img className="w-full h-full object-cover" alt={title} src={img} />
        </Link>
      </div>

      <div className="w-full h-[60%] flex flex-col p-3 justify-between">
        <Link to={url} target="_blank" className="leading-5">
          <Span
            text={title}
            size="text-sm sm:text-md  lg:text-base"
            align="text-left"
            extraStyles="hover:underline"
          />
        </Link>
        <div className="flex flex-row justify-between items-end">
          <Span
            text={date}
            size="text-lg"
            align="text-left"
            color="text-gray-600"
          />
          {auth?.type === "admin" && (
            <div
              className="w-9 h-9 flex justify-center items-center rounded-full hover:bg-gray-200"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <div className="group hover:text-red-600">
                <Icon icon="trashBin" size={6} color="hover:fill-red" />
              </div>
            </div>
          )}
        </div>
      </div>
      <DeleteNew
        id={id}
        title={title}
        isOpenModal={openModal}
        setIsOpenModal={() => setOpenModal(!openModal)}
        updateData={updateData}
        updatePaging={updatePaging}
        updateUrlData={updateUrlData}
      />
    </div>
  );
};

export default UrlNew;
