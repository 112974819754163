import { Pqrs, PqrsType } from "@entities/Pqrs.entity";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import InputSelect from "@components/atoms/InputFormik/InputSelect/InputSelect";
import Textarea from "@components/atoms/InputFormik/Textarea/Textarea";
import Span from "@components/atoms/Span/Span";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

import { useAddPqrs } from "@hooks/Pqrs/usePqrs";

interface AddPqrsEntity {
  title: string;
  body: string;
  type: PqrsType;
  supportFile: File | undefined;
}

interface Props {
  clientId: string;
  accountantId: number;
}

const AddPqrs = ({ clientId, accountantId }: Props): JSX.Element => {
  const [savePqrs] = useAddPqrs();

  const initialValues: AddPqrsEntity = {
    title: "",
    body: "",
    type: "peticion",
    supportFile: undefined,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Este campo es obligatorio"),
    body: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = (values: any) => {
    const data: Pick<
      Pqrs,
      | "title"
      | "body"
      | "supportFile"
      | "updatedByClient"
      | "updatedByAccountant"
      | "clientId"
      | "accountantId"
      | "type"
      | "status"
    > = {
      title: values.title,
      body: values.body,
      type: values.type,
      supportFile: values.supportFile,
      updatedByClient: true,
      updatedByAccountant: false,
      clientId,
      accountantId,
      status: "abierta",
    };
    savePqrs(data);
  };

  return (
    <>
      <InfoContainer
        title={"¿Tienes alguna pregunta, inquietud o sugerencia?"}
        iconName={undefined}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className={"p-[20px]"}>
                <div className={"flex justify-between gap-[40px]"}>
                  <div className={"w-full"}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Título"}
                    />
                    <Input name={"title"} type={"text"} />
                  </div>
                  <div className={"w-full"}>
                    <Span
                      weight={"font-bold"}
                      size={"text-base"}
                      text={"Tipo de Ticket"}
                    />
                    <InputSelect
                      name={"type"}
                      options={["peticion", "queja", "reclamo", "sugerencia"]}
                    />
                  </div>
                </div>
                <div>
                  <Span
                    weight={"font-bold"}
                    size={"text-base"}
                    text={"Descripción del problema"}
                  />
                  <Textarea name={"body"} placeholder={""} />
                </div>
                <InputFile
                  label={"Archivos soporte"}
                  id={"supportFile"}
                  value={values.supportFile?.name}
                  setValue={setFieldValue}
                  accept={".jpg, .png, .pdf"}
                />
                <Button
                  text={"Enviar"}
                  type={"submit"}
                  extraStyles={"w-[90px]"}
                  extraS={{ backgroundColor: "#34344c" }}
                />
              </Form>
            );
          }}
        </Formik>
      </InfoContainer>
    </>
  );
};

export default AddPqrs;
