import { ErrorMessage, Field } from "formik";
import React from "react";

type Props = {
  name: string;
  placeholder: string;
};

const Textarea = ({ name, placeholder }: Props) => {
  return (
    <div className="w-full">
      <Field
        as="textarea"
        id={name}
        name={name}
        className="h-40 resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder={placeholder}
      />
      <div className="h-5 mt-2">
        <ErrorMessage name={name}>
          {(msg: string) => <div className="text-red-500 text-sm">{msg}</div>}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default Textarea;
