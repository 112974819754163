import { FC } from "react";

import Span from "@components/atoms/Span/Span";

interface Props {
  error: string;
}

const ErrorCode: FC<Props> = ({ error }) => {
  return (
    <div className="flex  flex-col">
      <Span
        text={error}
        weight="font-bold"
        color="text-gray-900"
        size="text-[160px] md:text-[220px]"
        extraStyles="-rotate-[10deg] opacity-75 z-10"
      />
      <Span
        text={error}
        weight="font-bold"
        color="text-cyan-400"
        size="text-[160px] md:text-[220px]"
        extraStyles="rotate-[10deg] z-0  mt-[-200px]  md:mt-[-280px]"
      />
    </div>
  );
};

export default ErrorCode;
