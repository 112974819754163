import { fetching } from "@entities/Fetching.entity";
import { useState } from "react";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Pagination from "@components/atoms/Pagination/Pagination";
import Span from "@components/atoms/Span/Span";

import CustomTable from "@components/molecules/CustomTable/CustomTable";
import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";
import ClientRow from "@components/molecules/Row/ClientRow/ClientRow";
import SearchBar from "@components/molecules/SearchBar/SearchBar";

import { useClients, useClientsByParams } from "@hooks/Clients/useClients";

import AddClient from "../../Add/AddClient/AddClient";

type Props = {
  canAddClient: boolean;
  searchByAccId?: string;
  idAccountant?: number;
};

const ClientsTable = ({ canAddClient, searchByAccId, idAccountant }: Props) => {
  const [
    clients,
    totalClients,
    active,
    fetchingState,
    pagination,
    setPagination,
    updateData,
    setActive,
  ] = useClients(searchByAccId);
  const [clientsFound, clientToSearch, setClientToSearch, getSearchClients] =
    useClientsByParams();
  const [openModal, setOpenModal] = useState(false);
  const COLUMNS = ["CLIENTE", "PERSONA DE CONTACTO", "TELÉFONO", "EMAIL", ""];

  const renderClients = () => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (
          (clientsFound && clientsFound.length === 0) ||
          clients?.length === 0
        ) {
          return (
            <div
              className={
                "flex justify-center items-center flex-col gap-1.5 p-36"
              }
            >
              <Icon icon={"userCicle"} size={20} />
              <Span
                text={"No se encontró a ningún cliente"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <CustomTable
            columns={COLUMNS}
            Row={ClientRow}
            data={clientsFound ? clientsFound : clients}
            updateData={updateData}
          />
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  return (
    <div className="">
      <InfoContainer
        iconSize={6}
        iconColor={"white"}
        iconName={"users"}
        title={"Clientes"}
        titleColor={"text-white"}
        backgroundColor={"#25253f"}
      >
        <div className="my-4">
          <SearchBar
            searchValue={clientToSearch}
            setSearchValue={setClientToSearch}
            handleSearch={getSearchClients}
            setOpenModal={setOpenModal}
            openModal={openModal}
            buttonIcon="addUser"
            buttonText={canAddClient ? "Añadir cliente" : undefined}
          />
          <div className="flex justify-center md:justify-end pr-[0px] md:pr-[15px] ">
            <Button
              text={`Ver ${!active ? "habilitados " : "deshabilitados"}`}
              type={"button"}
              onClick={() => setActive(!active)}
              iconName={!active ? "eye" : "eyeSlash"}
              iconColor={"blue-600"}
              iconSize={4}
              width="w-[170px]"
              height="h-9"
              textColor="text-gray-800"
              bgColor={`${
                !active
                  ? "bg-gray-800 hover:bg-gray-900"
                  : "bg-white hover:bg-gray-50"
              }`}
              padding="px-3 py-1"
              extraStyles={`${
                !active
                  ? "border-0 text-white"
                  : "text-gray-600 border-2 border-gray-600 border-solid"
              } text-xs rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300`}
            />
          </div>
        </div>
        {renderClients()}
        {((clientsFound && clientsFound.length > 20) || totalClients > 20) && (
          <Pagination
            currentPage={pagination}
            setCurrentPage={setPagination}
            totalPages={Math.ceil(
              clientsFound ? clientsFound.length / 20 : totalClients / 20
            )}
          />
        )}
      </InfoContainer>
      <AddClient
        isOpenModal={openModal}
        setIsOpenModal={() => setOpenModal(!openModal)}
        updateData={updateData}
        idAccountant={idAccountant}
      />
    </div>
  );
};

export default ClientsTable;
