import ImageHalfSize from "@components/atoms/ImageHalfSize/ImageHalfSize";

import LoginForm from "@components/organisms/LoginForm/LoginForm";

import styles from "./Login.module.scss";

type Props = {};

const Login = (props: Props) => {
  return (
    <div className={styles.Login}>
      <div className={styles.imageContainer}>
        <ImageHalfSize />
      </div>
      <LoginForm />
    </div>
  );
};

export default Login;
