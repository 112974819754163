import { Calendar, documentStatus } from "@entities/Calendar.entity";
import { Document } from "@entities/Document.entity";
import { updateDocument } from "@service/Documents.service";
import { errorMessage } from "@utils/Status";
import { useContext } from "react";

import Button from "@components/atoms/Button/Button";
import Modal from "@components/atoms/Modal/Modal";
import Span from "@components/atoms/Span/Span";

import { AuthContext } from "@providers/providers";

interface Props {
  docInfo: Calendar | undefined;
  isOpenModal: boolean;
  setIsOpenModal: () => void;
}

const DocumentView = ({ docInfo, isOpenModal, setIsOpenModal }: Props) => {
  const { auth } = useContext(AuthContext);

  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "numeric",
  };

  const getColorByStatus = (status: documentStatus) => {
    switch (status) {
      case "pago":
        return "blue";
      case "vencido":
        return "red";
      case "vigente":
        return "green";
    }
  };

  const downloadFile = async (id: string, name: string, docType: string) => {
    const aTag = document.createElement("a");
    aTag.href = id;
    aTag.target = "blank";
    aTag.setAttribute("download", `${name}.${docType}`);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();

    const documentData: Partial<Document> = {
      viewed: true,
    };

    const folderPath = `${auth?.nit}/folders/root/documents/${docInfo?.id}`;

    try {
      await updateDocument(folderPath, documentData, auth!!.token);
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  return (
    <Modal
      title={docInfo ? docInfo.name : "Impuestos Diciembre"}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      bgColor={getColorByStatus(
        docInfo && docInfo.status ? docInfo.status : "pago"
      )}
      textColor={"white"}
    >
      <div className="flex flex-col gap-2">
        {docInfo && (
          <>
            <div className="flex gap-2">
              <Span text={"Estado: "} weight={"font-bold"} />
              <Span
                text={docInfo.status ? docInfo.status : "A la espera"}
                extraS={{ textTransform: "capitalize" }}
              />
            </div>
            <div className="flex gap-2">
              <Span text={"Fecha de creación: "} weight={"font-bold"} />
              <Span
                text={new Date(docInfo.creationDate).toLocaleDateString(
                  "es-co",
                  dateOptions
                )}
              />
            </div>
            <div className="flex gap-2">
              <Span text={"Fecha de vencimiento: "} weight={"font-bold"} />
              <Span
                text={new Date(docInfo.dueDate).toLocaleDateString(
                  "es-co",
                  dateOptions
                )}
              />
            </div>
            <div className="flex grid place-items-center pt-3">
              <Button
                onClick={() =>
                  downloadFile(docInfo.fileId, docInfo.name, docInfo.typeFile)
                }
                text={
                  docInfo.typeFile === "application/pdf" ? "Ver" : "Descargar"
                }
                type={"button"}
                width={"w-[170px]"}
                padding={"p-2"}
                iconName={"fileExport"}
                iconColor={"white"}
                iconLeft={false}
                extraS={{ backgroundColor: "#25253f" }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DocumentView;
