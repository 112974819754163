import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/accounting`;

export const getAccountingByNit = (
  nit: string,
  year: number,
  token: string
) => {
  return axios.get(`${baseUrl}/${nit}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      year,
    },
  });
};
