import { Id, ToastOptions, toast } from "react-toastify";

import Icon, { IconNames } from "@components/atoms/Icons/Icon";

type typeInfoToast = "info" | "success" | "warning" | "error" | "default";

const toastOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  draggable: true,
  progress: undefined,
};

export const toastLoading = (message: string): Id => {
  return toast.loading(message);
};

export const toastUpdateLoading = (
  id: Id,
  message: string,
  type: typeInfoToast,
  icon?: IconNames
) => {
  const options = icon
    ? { ...toastOptions, icon: Icon({ icon: icon, color: "white" }) }
    : { ...toastOptions };
  toast.update(id, {
    ...options,
    render: message,
    type: type,
    isLoading: false,
  });
};

export const toastSuccess = (message: string, icon?: IconNames) => {
  const options = icon
    ? { ...toastOptions, icon: Icon({ icon: icon, color: "white" }) }
    : { ...toastOptions };
  toast.success(message, options);
};

export const toastError = (message: string, icon?: IconNames) => {
  const options = icon
    ? { ...toastOptions, icon: Icon({ icon: icon, color: "white" }) }
    : { ...toastOptions };
  toast.error(message, options);
};

export const toastWarning = (message: string, icon?: IconNames) => {
  const options = icon
    ? { ...toastOptions, icon: Icon({ icon: icon, color: "white" }) }
    : { ...toastOptions };
  toast.warning(message, options);
};
