import DashboardTemplate from "../../templates/Dashboard/Dashboard";

type Props = {};

const Dashboard = (props: Props) => {
  return (
    <>
      <DashboardTemplate />
    </>
  );
};

export default Dashboard;
