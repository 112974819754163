import { Accounting } from "@entities/Accounting.entity";

import Badge from "@components/atoms/Badge/Badge";
import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

interface Props {
  accounting: Accounting[] | null | undefined;
  last: number | null;
  percentage: number;
  iconRight?: boolean;
}

const BalanceBadge = ({
  accounting,
  last,
  percentage,
  iconRight = false,
}: Props): JSX.Element => {
  const style: number = percentage > 0 ? 0 : percentage < 0 ? 1 : 2;
  const balanceColorStyles: any[] = [
    {
      color: "bg-green-200",
      iconName: "arrowCompleteUp",
      iconColor: "#046c4e",
      signal: "+",
      textColor: "text-green-700",
    },
    {
      color: "bg-red-200",
      iconName: "arrowCompleteDown",
      iconColor: "#C81E1E",
      textColor: "text-red-700",
    },
    {
      color: undefined,
      iconName: "minus",
      iconColor: "#374151",
      textColor: "text-gray-700",
    },
  ];

  const percentageToPresent = parseFloat(percentage.toFixed(1));

  return (
    <Badge
      color={balanceColorStyles[style].color}
      extraStyles={iconRight ? "w-[56px] h-[56px] rounded-full m-auto" : ""}
    >
      {iconRight ? (
        <Icon
          icon={
            accounting === null ? "minus" : balanceColorStyles[style].iconName
          }
          size={7}
          color={balanceColorStyles[style].iconColor}
        />
      ) : last !== undefined && last !== null ? (
        percentage === 0 ? (
          <>
            <Span
              text={"No hubo cambios"}
              size={"text-sm"}
              weight="font-bold"
              align={"text-left"}
              color={balanceColorStyles[style].textColor}
            />
          </>
        ) : (
          <>
            <Span
              text={
                Math.trunc(last).toString().length > 10
                  ? new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                      notation: "compact",
                      compactDisplay: "long",
                    }).format(last)
                  : new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                    }).format(last)
              }
              size={"text-sm"}
              weight="font-bold"
              color={balanceColorStyles[style].textColor}
            />
            <div className="flex flex-row items-center gap-1">
              <Span
                text={`${
                  percentageToPresent !== 0
                    ? balanceColorStyles[style].signal
                      ? balanceColorStyles[style].signal
                      : ""
                    : ""
                }${percentageToPresent}%`}
                size={"text-sm"}
                weight="font-bold"
                color={balanceColorStyles[style].textColor}
              />
              <Icon
                icon={balanceColorStyles[style].iconName}
                size={3}
                color={balanceColorStyles[style].iconColor}
              />
            </div>
          </>
        )
      ) : null}
    </Badge>
  );
};

export default BalanceBadge;
