import { fetching } from "@entities/index";
import {
  getDeletedFiles,
  getDocumentsBySearch,
  getFiles,
  getTotalDeletedClientDocuments,
  getTotalDocumentsAndFolders,
} from "@service/Documents.service";
import {
  formatDeletedFilesToState,
  formatFilesToState,
  getFolderPathForCurrentLocation,
  getFolderPathForDeletedFiles,
} from "@utils/Documents";
import { errorMessage } from "@utils/Status";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { deletedDocumentRow } from "@components/molecules/Row/DocumentRow/DeletedDocumentRow";
import { documentRow } from "@components/molecules/Row/DocumentRow/DocumentRow";

import { AuthContext } from "@providers/providers";

import {
  UseDeletedDocuments,
  UseSearchedDocuments,
  UseUserDocuments,
} from "./types";

const useUserDocuments = (): UseUserDocuments => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const [userDocuments, setUserDocuments] = useState<documentRow[]>([]);
  const [userDocumentsCurrentPage, setUserDocumentsCurrentPage] =
    useState<number>(1);
  const [userDocumentsFetchingState, setUserDocumentsFetchingState] =
    useState<fetching>(fetching.Loading);
  const [totalUserDocuments, setTotalUserDocuments] = useState(0);

  const folderPathForUserDocumentsCurrentLocation =
    getFolderPathForCurrentLocation(
      auth,
      location.pathname.split("/"),
      userDocumentsCurrentPage
    );

  const getUserDocuments = async (
    path: string = folderPathForUserDocumentsCurrentLocation
  ) => {
    setUserDocumentsFetchingState(fetching.Loading);
    try {
      const { data } = await getFiles(path, auth!!.token);
      const documentsList = formatFilesToState(data.data.content);
      setUserDocuments(documentsList);
      setUserDocumentsFetchingState(fetching.Success);
      getTotalUserDocuments();
    } catch (error: any) {
      errorMessage(error.response);
      setUserDocumentsFetchingState(fetching.Error);
    }
  };

  const getTotalUserDocuments = async () => {
    try {
      const { data } = await getTotalDocumentsAndFolders(
        folderPathForUserDocumentsCurrentLocation.split("?")[0],
        auth!!.token
      );
      setTotalUserDocuments(data.data.total);
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  return [
    userDocuments,
    userDocumentsFetchingState,
    userDocumentsCurrentPage,
    setUserDocumentsCurrentPage,
    totalUserDocuments,
    getUserDocuments,
  ];
};

const useMatchingDocuments = (): UseSearchedDocuments => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const [matchingDocuments, setMatchingDocuments] = useState<
    documentRow[] | undefined | null
  >(undefined);
  const [searchParam, setSearchParam] = useState("");
  const [matchingDocumentsFetchingState, setMatchingDocumentsFetchingState] =
    useState<fetching>(fetching.Loading);

  const folderPathForMatchingDocuments = getFolderPathForCurrentLocation(
    auth,
    location.pathname.split("/"),
    1
  );

  const getMatchingDocuments = async () => {
    setMatchingDocumentsFetchingState(fetching.Loading);
    if (searchParam.length === 0) return setMatchingDocuments(undefined);
    try {
      const { data } = await getDocumentsBySearch(
        folderPathForMatchingDocuments.split("/").slice(0, 2).join("/"),
        auth!!.token,
        searchParam
      );
      const result =
        data.data === undefined ? null : formatFilesToState(data.data.content);
      setMatchingDocuments(result);
      setMatchingDocumentsFetchingState(fetching.Success);
    } catch (error: any) {
      errorMessage(error.response, undefined, [
        { status: 403, msg: "Error al cargar los datos de los contadores!" },
      ]);
      setMatchingDocumentsFetchingState(fetching.Error);
    }
  };

  return [
    matchingDocuments,
    matchingDocumentsFetchingState,
    searchParam,
    setSearchParam,
    getMatchingDocuments,
  ];
};

const useDeletedDocuments = (): UseDeletedDocuments => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const [deletedDocuments, setDeletedDocuments] = useState<
    deletedDocumentRow[]
  >([]);
  const [deletedDocumentsCurrentPage, setDeletedDocumentsCurrentPage] =
    useState<number>(1);
  const [deletedDocumentsFetchingState, setDeletedDocumentsFetchingState] =
    useState<fetching>(fetching.Loading);
  const [totalDeletedDocuments, setTotalDeletedDocuments] = useState(0);

  const folderPathForDeletedDocuments = getFolderPathForDeletedFiles(
    location.pathname.split("/"),
    deletedDocumentsCurrentPage
  );

  const getDeletedDocuments = async () => {
    setDeletedDocumentsFetchingState(fetching.Loading);
    try {
      const { data } = await getDeletedFiles(
        folderPathForDeletedDocuments,
        auth!!.token
      );
      const deletedDocumentsList = formatDeletedFilesToState(data.data);
      setDeletedDocuments(deletedDocumentsList);
      setDeletedDocumentsFetchingState(fetching.Success);
      getTotalDeletedDocuments();
    } catch (error: any) {
      errorMessage(error.response);
      setDeletedDocumentsFetchingState(fetching.Error);
    }
  };

  const getTotalDeletedDocuments = async () => {
    try {
      const { data } = await getTotalDeletedClientDocuments(
        folderPathForDeletedDocuments.split("?")[0],
        auth!!.token
      );
      setTotalDeletedDocuments(data.data.total);
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  return [
    deletedDocuments,
    deletedDocumentsFetchingState,
    deletedDocumentsCurrentPage,
    setDeletedDocumentsCurrentPage,
    totalDeletedDocuments,
    getDeletedDocuments,
  ];
};

export { useUserDocuments, useMatchingDocuments, useDeletedDocuments };
