import RecoveryPasswordTemplate from "../../templates/RecoveryPassword/RecoveryPassword";

const RecoveryPassword = () => {
  return (
    <>
      <RecoveryPasswordTemplate />
    </>
  );
};

export default RecoveryPassword;
