import { Datepicker as DatepickerInput } from "flowbite-react";
import { ErrorMessage } from "formik";

import Span from "@components/atoms/Span/Span";

type Props = {
  label: string;
  value?: string | undefined;
  id: string;
  setValue: (id: string, value: string) => void;
  error?: string;
  minDate?: Date;
};

const Datepicker = ({
  label,
  value,
  id,
  setValue,
  error,
  minDate = new Date(),
}: Props) => {
  return (
    <>
      <div className="relative w-full">
        <Span
          size={"text-base"}
          weight={"font-bold"}
          text={label}
          align={"text-left"}
        />
        <DatepickerInput
          value={value}
          onSelectedDateChanged={(e: any) => {
            setValue(id, new Date(e).toISOString());
          }}
          minDate={minDate}
        />
        <div className="h-5">
          {error ? (
            <div className="text-red-500 text-sm">{error}</div>
          ) : (
            <ErrorMessage name={id}>
              {(msg: string) => (
                <div className="text-red-500 text-sm">{msg}</div>
              )}
            </ErrorMessage>
          )}
        </div>
      </div>
    </>
  );
};

export default Datepicker;
