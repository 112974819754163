import { Accounting } from "@entities/Accounting.entity";
import { IResult, MonthTypes, ResultTypes } from "@hooks/Accounting/types";
import { Dispatch, SetStateAction } from "react";

import Card from "@components/atoms/Card/Card";
import BarChart from "@components/atoms/Chart/BarChart/BarChart";
import Span from "@components/atoms/Span/Span";
import Text from "@components/atoms/Text/Text";
import TitleH3 from "@components/atoms/TitleH3/TitleH3";

import InfoContainer from "../InfoContainer/InfoContainer";

interface Props {
  accounting: Accounting[] | null | undefined;
  resultInfo: IResult | null | undefined;
  income?: number;
  administrativeExpenses?: number;
  salesCost?: number;
  productionCost?: number;
  month: MonthTypes;
  toolTipContent?: (content: any) => JSX.Element | null;
  setMonth: Dispatch<SetStateAction<MonthTypes>>;
  setResult: Dispatch<SetStateAction<ResultTypes>>;
}

const BarChartResults = ({
  accounting,
  resultInfo,
  income,
  administrativeExpenses,
  salesCost,
  productionCost,
  month,
  toolTipContent,
  setMonth,
  setResult,
}: Props): JSX.Element => {
  let title: string = "";

  if (resultInfo) {
    switch (resultInfo.result) {
      case "income":
        title = "Ventas netas por mes";
        break;
      case "administrativeExpenses":
        title = "Gastos de administración por mes";
        break;
      case "salesCost":
        title = "Gastos de ventas por mes";
        break;
      case "productionCost":
        title = "Gastos de producción por mes";
        break;
    }
  }

  const empty = Array(12)
    .fill(0)
    .map((_, index) => {
      return {
        month: index + 1,
        value: NaN,
      };
    });

  const data = [];
  if (resultInfo) {
    resultInfo.accountingByResult.forEach((el) => {
      empty[el.month].month = el.month + 1;
      empty[el.month].value = el.value;
    });
  }

  data.push(...empty);

  return (
    <InfoContainer title={"Resultados"}>
      <div className="p-7 flex flex-col xl:flex-row gap-3 justify-between">
        <div className="w-full xl:w-[70%]">
          <div className="flex justify-between">
            <TitleH3 text={title} align={"text-left"} />
          </div>
          <br />
          <BarChart
            data={data}
            height={335}
            contentTooltip={accounting === null ? () => <></> : toolTipContent}
            indexHover={month}
            onPressCell={(val) => {
              if (Object.values(val).length > 0) {
                setMonth(
                  (val.activePayload[0].payload.month - 1) as MonthTypes
                );
              }
            }}
          />
        </div>
        <div className="w-full xl:w-[30%] flex flex-col gap-[16px]">
          <Card
            onPress={() => setResult("income")}
            extraStyles={`${
              resultInfo?.result === "income"
                ? "border-blue-400 bg-blue-100"
                : "border-gray-700"
            } h-[80px] px-5 py-1 cursor-pointer`}
          >
            <>
              <Span
                text={"Ventas netas"}
                align={"text-left"}
                size={"text-base"}
              />
              <Text
                text={
                  income !== undefined
                    ? income !== null
                      ? Math.trunc(income).toString().length > 10
                        ? new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                            notation: "compact",
                            compactDisplay: "long",
                          }).format(income)
                        : new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                          }).format(income)
                      : "-"
                    : "-"
                }
                weight="font-bold"
                size="text-2xl"
                align="text-left"
              />
            </>
          </Card>
          <Card
            onPress={() => setResult("administrativeExpenses")}
            extraStyles={`${
              resultInfo?.result === "administrativeExpenses"
                ? "border-blue-400 bg-blue-100"
                : "border-gray-700"
            } h-[80px] px-5 py-1 cursor-pointer`}
          >
            <>
              <Span
                text={"Gastos de administración"}
                align={"text-left"}
                size={"text-base"}
              />
              <Text
                text={
                  administrativeExpenses !== undefined
                    ? administrativeExpenses !== null
                      ? Math.trunc(administrativeExpenses).toString().length >
                        10
                        ? new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                            notation: "compact",
                            compactDisplay: "long",
                          }).format(administrativeExpenses)
                        : new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                          }).format(administrativeExpenses)
                      : "-"
                    : "-"
                }
                weight="font-bold"
                size="text-2xl"
                align="text-left"
              />
            </>
          </Card>
          <Card
            onPress={() => setResult("salesCost")}
            extraStyles={`${
              resultInfo?.result === "salesCost"
                ? "border-blue-400 bg-blue-100"
                : "border-gray-700"
            } h-[80px] px-5 py-1 cursor-pointer`}
          >
            <>
              <Span
                text={"Costos de ventas"}
                align={"text-left"}
                size={"text-base"}
              />
              <Text
                text={
                  salesCost !== undefined
                    ? salesCost !== null
                      ? Math.trunc(salesCost).toString().length > 10
                        ? new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                            notation: "compact",
                            compactDisplay: "long",
                          }).format(salesCost)
                        : new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                          }).format(salesCost)
                      : "-"
                    : "-"
                }
                weight="font-bold"
                size="text-2xl"
                align="text-left"
              />
            </>
          </Card>
          <Card
            onPress={() => setResult("productionCost")}
            extraStyles={`${
              resultInfo?.result === "productionCost"
                ? "border-blue-400 bg-blue-100"
                : "border-gray-700"
            } h-[80px] px-5 py-1 cursor-pointer`}
          >
            <>
              <Span
                text={"Costos de producción"}
                align={"text-left"}
                size={"text-base"}
              />
              <Text
                text={
                  productionCost !== undefined
                    ? productionCost !== null
                      ? Math.trunc(productionCost).toString().length > 10
                        ? new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                            notation: "compact",
                            compactDisplay: "long",
                          }).format(productionCost)
                        : new Intl.NumberFormat("es-CO", {
                            style: "currency",
                            currency: "COP",
                          }).format(productionCost)
                      : "-"
                    : "-"
                }
                weight="font-bold"
                size="text-2xl"
                align="text-left"
              />
            </>
          </Card>
        </div>
      </div>
    </InfoContainer>
  );
};

export default BarChartResults;
