import AccountantTemplate from "../../templates/Accountant/Accountant";

type Props = {};

const Accountant = (props: Props) => {
  return (
    <>
      <AccountantTemplate />
    </>
  );
};

export default Accountant;
