import { fetching } from "@entities/Fetching.entity";
import { New as NewEntity } from "@entities/News.entity";
import { useContext, useState } from "react";

import Button from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import Span from "@components/atoms/Span/Span";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";
import New from "@components/molecules/New/New";
import UrlNew from "@components/molecules/New/UrlNew";
import NewsCarousel from "@components/molecules/NewsCarousel/NewsCarousel";

import AddNew from "@components/organisms/Add/AddNew/AddNew";

import { AuthContext } from "@providers/providers";

import { useNews, useNewsScroll, useUrlNews } from "@hooks/News/useNews";

type Props = {};

const NewsSection = (props: Props) => {
  const { auth } = useContext(AuthContext);
  const [urlNews, setUrlNews, fetchingState, updateUrlData] = useUrlNews();
  const [news, fetchState, updateData] = useNews();
  const [elementRef, hasMore, updatePaging] = useNewsScroll(
    urlNews,
    setUrlNews
  );
  const [openModal, setOpenModal] = useState<boolean>(false);

  const renderNewsList = () => {
    switch (fetchState) {
      case fetching.Loading:
        return (
          <div className="h-full flex justify-center items-center">
            <Loader />
          </div>
        );

      case fetching.Success:
        if (news?.length === 0) {
          return;
        } else {
          return (
            <div className="w-full h-[500px] sm:h-[460px] lg:h-[350px] p-6 lg:mb-0 mb-10">
              <div className="w-full h-full shadow-xl border border-solid border-gray-200 rounded-xl">
                <NewsCarousel>
                  {news?.map((newCard: NewEntity, index: number) => (
                    <New
                      key={index}
                      id={newCard.id}
                      title={newCard.title}
                      img={newCard.img}
                      description={newCard.description}
                      date={newCard.creationDate}
                      updateData={updateData}
                      updatePaging={updatePaging}
                      updateUrlData={updateUrlData}
                    />
                  ))}
                </NewsCarousel>
              </div>
            </div>
          );
        }

      case fetching.Error:
        return (
          <div className="h-full flex justify-center items-center flex-col gap-10 p-5">
            <Icon icon={"gear"} size={16} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-2xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  const renderUrlNewsList = () => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="h-[100vh] p-36 flex justify-start items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        if (urlNews?.length === 0) {
          return (
            <div className="h-[100vh] p-36 flex justify-start items-center flex-col gap-10">
              <Icon icon={"newsPaper"} size={20} />
              <Span
                text={"No hay noticias nuevas"}
                size={"text-3xl"}
                weight={"font-semibold"}
              />
            </div>
          );
        }
        return (
          <>
            {urlNews?.map((newCard: NewEntity, index: number) => (
              <UrlNew
                key={index}
                id={newCard.id}
                title={newCard.title}
                img={newCard.img}
                url={newCard.url}
                date={newCard.creationDate}
                updateData={updateData}
                updatePaging={updatePaging}
                updateUrlData={updateUrlData}
              />
            ))}
          </>
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  };

  return (
    <InfoContainer
      iconSize={6}
      iconName={"newsPaper"}
      title={"Últimas noticias"}
      RightSideInfo={
        auth?.type === "admin" && (
          <Button
            iconSize={4}
            iconName="gridPlus"
            iconColor="white"
            text="Añadir Noticia"
            type="button"
            width="w-auto"
            bgColor="bg-blue-dashboard hover:bg-blue-800"
            padding="py-2 px-4"
            extraStyles="text-xs sm:text-sm lg:text-base"
            extraS={{ backgroundColor: "#25253f" }}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )
      }
    >
      <div className="flex flex-col gap-2">
        {renderNewsList()}

        <div className="w-full p-0 border-b border-solid border-gray-300"></div>
        <div className="w-full p-6 flex flex-col gap-6 justify-center">
          <div className="w-full pt-0 flex flex-row gap-6 justify-center flex-wrap">
            {renderUrlNewsList()}
          </div>
          {hasMore && (
            <div ref={elementRef} className="flex justify-center">
              <Loader />
            </div>
          )}
        </div>
        <AddNew
          isOpenModal={openModal}
          setIsOpenModal={() => {
            setOpenModal(!openModal);
          }}
          updateData={updateData}
          updateDataUrl={updateUrlData}
          updatePaging={updatePaging}
        />
      </div>
    </InfoContainer>
  );
};

export default NewsSection;
