import { FC } from "react";
import { align, size, weight } from "types/fonts";

interface Props {
  text: string;
  size?: size | string;
  weight?: weight;
  color?: string;
  align?: align;
  extraStyles?: string;
  extraS?: React.CSSProperties;
  withTextOverflow?: boolean;
}

const Span: FC<Props> = ({
  text,
  size = "text-lg",
  weight = "font-normal",
  color = "text-black",
  align = "text-center",
  extraStyles,
  extraS,
  withTextOverflow = true
}) => {
  return (
    <span
      className={`${size} ${weight} ${color} ${align} ${extraStyles}`}
      style={{ textOverflow: withTextOverflow ? "ellipsis" : "", ...extraS }}
    >
      {text}
    </span>
  );
};

export default Span;
