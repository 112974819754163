import { PqrsResponse } from "@entities/PqrsResponse.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/pqrs-response`;

export const getPqrsResponseByPqrsId = (id: number, token: string) => {
  return axios.get(`${baseUrl}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addPqrsResponse = (
  pqrsResponse: Partial<PqrsResponse>,
  token: string
) => {
  return axios.post(baseUrl, pqrsResponse, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
