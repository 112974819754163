import { Pqrs, PqrsType } from "@entities/Pqrs.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/pqrs`;

export const getTotalPqrs = (type: PqrsType, token: string) => {
  return axios.get(`${baseUrl}/total`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      type,
    },
  });
};

export const getAllPqrs = (
  token: string,
  pagination: number,
  type: PqrsType
) => {
  return axios.get(baseUrl, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      page: pagination,
      type,
    },
  });
};

export const getPqrsById = (id: number, token: string) => {
  return axios.get(`${baseUrl}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getPqrsByClientNit = (
  id: string,
  token: string,
  pagination: number,
  type: PqrsType
) => {
  return axios.get(`${baseUrl}/client/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      page: pagination,
      type,
    },
  });
};

export const getPqrsByAccountantId = (
  id: number,
  token: string,
  pagination: number,
  type: PqrsType
) => {
  return axios.get(`${baseUrl}/accountant/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      page: pagination,
      type,
    },
  });
};

export const addPqrs = (pqrs: Partial<Pqrs>, token: string) => {
  return axios.post(baseUrl, pqrs, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updatePqrs = (id: number, pqrs: Partial<Pqrs>, token: string) => {
  return axios.patch(`${baseUrl}/${id}`, pqrs, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
