import { Accounting } from "@entities/Accounting.entity";
import { BalanceTypes } from "@hooks/Accounting/types";
import { Dispatch, SetStateAction } from "react";

import Card from "@components/atoms/Card/Card";
import Span from "@components/atoms/Span/Span";
import Text from "@components/atoms/Text/Text";

import BalanceBadge from "./BalanceBadge";

interface Props {
  accounting: Accounting[] | null | undefined;
  asset: number | null;
  liabilities: number | null;
  equity: number | null;
  lastAsset?: number;
  lastLiabilities?: number;
  lastEquity?: number;
  setBalance: Dispatch<SetStateAction<BalanceTypes>>;
}

const CardsForBalance = ({
  accounting,
  asset,
  liabilities,
  equity,
  lastAsset,
  lastLiabilities,
  lastEquity,
  setBalance,
}: Props): JSX.Element => {
  return (
    <div className="flex flex-row gap-[35px] flex-wrap justify-center">
      <Card extraStyles="h-[120px] xl:min-w-[315px] xl:max-w-[350px] w-full p-4">
        <div className="flex flex-row justify-between">
          <div className="h-full flex flex-col justify-evenly">
            <Text text="Activo total" align={"text-left"} />
            <Text
              text={
                asset
                  ? Math.trunc(asset).toString().length > 10
                    ? new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                        notation: "compact",
                        compactDisplay: "long",
                      }).format(asset)
                    : new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                      }).format(asset)
                  : "-"
              }
              align={"text-left"}
              weight="font-bold"
              color="text-blue-600"
              size="text-2xl"
            />
            <BalanceBadge
              accounting={accounting}
              last={asset && lastAsset ? asset - lastAsset : null}
              percentage={
                asset && lastAsset ? ((asset - lastAsset) / lastAsset) * 100 : 0
              }
            />
          </div>
          <div className="flex flex-col gap-1 items-center">
            <BalanceBadge
              accounting={accounting}
              last={asset && lastAsset ? asset - lastAsset : null}
              percentage={
                asset && lastAsset ? ((asset - lastAsset) / lastAsset) * 100 : 0
              }
              iconRight={true}
            />
            <div className="text-center" onClick={() => setBalance("asset")}>
              <Span
                text="Ver gráfico"
                size={"text-sm"}
                extraS={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card extraStyles="h-[120px] xl:min-w-[315px] xl:max-w-[350px] w-full p-4">
        <div className="flex flex-row justify-between">
          <div className="h-full flex flex-col justify-evenly">
            <Text text="Pasivo total" align={"text-left"} />
            <Text
              text={
                liabilities
                  ? Math.trunc(liabilities).toString().length > 10
                    ? new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                        notation: "compact",
                        compactDisplay: "long",
                      }).format(liabilities)
                    : new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                      }).format(liabilities)
                  : "-"
              }
              align={"text-left"}
              weight="font-bold"
              color="text-blue-600"
              size="text-2xl"
            />
            <BalanceBadge
              accounting={accounting}
              last={
                liabilities && lastLiabilities
                  ? liabilities - lastLiabilities
                  : null
              }
              percentage={
                liabilities && lastLiabilities
                  ? ((liabilities - lastLiabilities) / lastLiabilities) * 100
                  : 0
              }
            />
          </div>
          <div className="flex flex-col gap-1 items-center">
            <BalanceBadge
              accounting={accounting}
              last={
                liabilities && lastLiabilities
                  ? liabilities - lastLiabilities
                  : null
              }
              percentage={
                liabilities && lastLiabilities
                  ? ((liabilities - lastLiabilities) / lastLiabilities) * 100
                  : 0
              }
              iconRight={true}
            />
            <div className="text-center" onClick={() => setBalance("liabilities")}>
              <Span
                text="Ver gráfico"
                size={"text-sm"}
                extraS={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card extraStyles="h-[120px] xl:min-w-[315px] xl:max-w-[350px] w-full p-4">
        <div className="flex flex-row justify-between">
          <div className="h-full flex flex-col justify-evenly">
            <Text text="Patrimonio" align={"text-left"} />
            <Text
              text={
                equity
                  ? Math.trunc(equity).toString().length > 10
                    ? new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                        notation: "compact",
                        compactDisplay: "long",
                      }).format(equity)
                    : new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                      }).format(equity)
                  : "-"
              }
              align={"text-left"}
              weight="font-bold"
              color="text-blue-600"
              size="text-2xl"
            />
            <BalanceBadge
              accounting={accounting}
              last={equity && lastEquity ? equity - lastEquity : null}
              percentage={
                equity && lastEquity
                  ? ((equity - lastEquity) / lastEquity) * 100
                  : 0
              }
            />
          </div>
          <div className="flex flex-col gap-1 items-center">
            <BalanceBadge
              accounting={accounting}
              last={equity && lastEquity ? equity - lastEquity : null}
              percentage={
                equity && lastEquity
                  ? ((equity - lastEquity) / lastEquity) * 100
                  : 0
              }
              iconRight={true}
            />
            <div className="text-center" onClick={() => setBalance("equity")}>
              <Span
                text="Ver gráfico"
                size={"text-sm"}
                extraS={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CardsForBalance;
