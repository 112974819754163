import React, { FC } from "react";

import EmailRecoveryTemplate from "../../templates/EmailRecovery/EmailRecovery";

interface Props {}

const Login: FC<Props> = (props) => {
  return (
    <>
      <EmailRecoveryTemplate />
    </>
  );
};

export default Login;
