import Bg_Client from "../../../assets/Icons/Bg_Client.svg";
import Whatsapp from "../../../assets/Icons/Whatsapp.svg";

import Icon from "../Icons/Icon";
import Span from "../Span/Span";
import styles from "./ContactClientCard.module.scss";

interface Props {
  name?: string;
  rol?: string;
  nit?: string;
  email?: string;
  phoneNumber?: string;
}

const ContactClientCard = ({
  name = "Undefined",
  rol = "Undefined",
  nit = "Undefined",
  email = "Undefined",
  phoneNumber = "Undefined",
}: Props) => {
  return (
    <>
      <div className={styles.header}>
        <img src={Bg_Client} className={"absolute"} />
        <Span
          text={"Contacto"}
          weight={"font-extrabold"}
          size={"text-3xl"}
          color={"text-white"}
        />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.textInfoContainer}>
          <Span
            text={name}
            weight={"font-semibold"}
            size={"text-2xl sm:text-3xl"}
          />
        </div>
        <div className={styles.textInfoContainer}>
          <Span text={rol} weight={"font-light"} size={"text-xl"} />
        </div>
        <div
          className={styles.textInfoContainer}
          style={{ paddingTop: "30px" }}
        >
          <Span text={`Nit: ${nit}`} weight={"font-medium"} size={"text-xl"} />
        </div>
        <div className={styles.textInfoContainer}>
          <Icon icon={"envelope"} size={5} />
          <Span
            text={email}
            weight={"font-medium"}
            size={"text-xl"}
            extraS={{ overflowWrap: "anywhere" }}
          />
        </div>
        <div className={styles.textInfoContainer}>
          <div className={styles.Whatsapplink}>
            <a
              href={`https://wa.me/${phoneNumber.replace(/[^0-9]/g, "")}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Whatsapp} className={"relative"} />
              <Span
                weight={"font-medium"}
                size={"text-xl"}
                color={"text-green-bright"}
                text={phoneNumber}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactClientCard;
