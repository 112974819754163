import { useContext } from "react";

import Icon from "@components/atoms/Icons/Icon";
import TitleH2 from "@components/atoms/TitleH2/TitleH2";

import { AuthContext } from "@providers/providers";

import styles from "./RoleBox.module.scss";

type Props = {};

const RoleBox = () => {
  const { auth } = useContext(AuthContext);

  const getRoleName = () => {
    if (auth) {
      switch (auth!!.type) {
        case "admin":
          return "ADMINISTRADOR";
        case "client":
          return "CLIENTE";
        case "accountant":
          return "CONTADOR";
      }
    }
    return "USUARIO";
  };

  return (
    <>
      <div className={styles.iconContainer}>
        <Icon icon={"arrowLeft"} color="white" />
      </div>
      <div className={styles.container}>
        <TitleH2 text={getRoleName()} color="text-white" weight={"font-bold"} />
      </div>
    </>
  );
};

export default RoleBox;
