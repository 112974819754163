import Button from "@components/atoms/Button/Button";
import TitleH2 from "@components/atoms/TitleH2/TitleH2";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";

import styles from "./PQRsCloseTicket.module.scss";

type Props = {
  setIsClosed: () => void;
};

const PQRsCloseTicket = ({ setIsClosed }: Props) => {
  return (
    <InfoContainer title={"Cerrar Ticket"} iconName={"headset"}>
      <div className={styles.pqrsCloseTicket}>
        <TitleH2
          text={"¿Desea cerrar el Ticket?"}
          weight={"font-medium"}
          size="text-2xl"
          align="text-left"
        />
        <Button
          text={"Aceptar"}
          type={"button"}
          width="w-24"
          height="h-9"
          onClick={setIsClosed}
        />
      </div>
    </InfoContainer>
  );
};

export default PQRsCloseTicket;
