import { ErrorMessage, Field } from "formik";
import { FC } from "react";

interface Props {
  name: string;
  icon?: any;
  placeholder?: string;
  validation?: (value: any) => string | null;
  error?: string;
  touched?: boolean;
  options: string[];
}

const InputSelect = ({
  name,
  placeholder,
  icon,
  validation,
  error,
  touched,
  options,
}: Props) => {
  return (
    <>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
          <img src={icon} alt="" className="!w-4" />
        </div>
        <Field
          as={"select"}
          id={name}
          name={name}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 capitalize"
          placeholder={placeholder}
          validate={validation}
        >
          {options.map((val) => {
            return (
              <option value={val} className={"capitalize"}>
                {val}
              </option>
            );
          })}
        </Field>
      </div>
      <div className="h-5 mt-2">
        {touched && error ? (
          <div className="text-red-500 text-sm">{error}</div>
        ) : (
          <ErrorMessage name={name}>
            {(msg: string) => <div className="text-red-500 text-sm">{msg}</div>}
          </ErrorMessage>
        )}
      </div>
    </>
  );
};

export default InputSelect;
