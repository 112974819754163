import { Accountant } from "@entities/Accountant.entity";
import { fetching } from "@entities/Fetching.entity";
import { Banner } from "flowbite-react";
import { useCallback, useContext, useState } from "react";
import { IoDocuments } from "react-icons/io5";
import { MdAnnouncement } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { useParams } from "react-router-dom";

import Button from "@components/atoms/Button/Button";
import Icon, { IconNames } from "@components/atoms/Icons/Icon";
import Loader from "@components/atoms/Loader/Loader";
import ProfileUser from "@components/atoms/ProfileUser/ProfileUser";
import Span from "@components/atoms/Span/Span";
import Tabs from "@components/atoms/Tabs/Tabs";

import InfoContainer from "@components/molecules/InfoContainer/InfoContainer";
import SearchAccountantCard from "@components/molecules/SearchAccountantCard/SearchAccountantCard";

import ClientContent from "@components/organisms/DashboardContent/ClientContent";
import DocumentsTable from "@components/organisms/Table/DocumentsTable/DocumentsTable";

import { AuthContext } from "@providers/providers";

import { useClientById } from "@hooks/Clients/useClients";

import EditClient from "../../Edit/EditClient/EditClient";
import styles from "./ClientProfile.module.scss";

interface Props {}

interface BtnProps {
  text: string;
  onPress?: () => void;
  iconName: IconNames;
  color?: string;
}

const Btn = ({
  text,
  onPress,
  iconName,
  color = "bg-gray-800 hover:bg-gray-900",
}: BtnProps) => {
  return (
    <Button
      text={text}
      type={"button"}
      onClick={onPress}
      iconName={iconName}
      iconColor={"white"}
      iconSize={4}
      width="w-18"
      height="h-9"
      bgColor={color}
      padding="px-3 py-1"
      extraStyles="text-xs rounded-[6px] font-medium focus:ring-4 focus:outline-none focus:ring-gray-300"
    />
  );
};

const ClientProfile = ({}: Props) => {
  const { auth } = useContext(AuthContext);
  const { clientId } = useParams();

  const idUser = auth?.type === "client" ? auth?.nit : clientId;

  const [
    client,
    fetchingState,
    updateProfilePicture,
    updateProfileInfo,
    updateAccountantInClient,
    updateData,
  ] = useClientById(idUser.toString());
  const [openModal, setOpenModal] = useState(false);

  const renderClientProfile = useCallback((): JSX.Element => {
    switch (fetchingState) {
      case fetching.Loading:
        return (
          <div className="p-36 flex justify-center items-center">
            <Loader />
          </div>
        );
      case fetching.Success:
        return (
          <>
            {!client?.active && (
              <Banner>
                <div className="flex w-full justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700">
                  <div className="mx-auto flex items-center">
                    <MdAnnouncement className="mr-4 h-4 w-4" color="#4B5563" />
                    <Span
                      text="Este perfil se encuentra deshabilitado"
                      size={"text-sm"}
                      extraStyles={"text-gray-500 dark:text-gray-400"}
                    />
                  </div>
                </div>
              </Banner>
            )}
            <div className={styles.container}>
              <div className={styles.profileContainer}>
                <ProfileUser
                  name={client?.companyName}
                  profileP={client?.companyLogo as string}
                  updateProfilePFunction={updateProfilePicture}
                  isEditablePicture={
                    auth?.type !== "accountant" && client?.active
                  }
                />
                <ProfileUser
                  name={`${client?.names} ${client?.surnames}`}
                  nit={client?.nit}
                  email={client?.email}
                  phoneNumber={client?.phoneNumber}
                  rol={client?.rol}
                  isImgProfile={false}
                  isClientContact={true}
                />
              </div>
              <div className={styles.accContainer}>
                {client?.accountant !== undefined && (
                  <SearchAccountantCard
                    accountantId={
                      client?.accountant === null
                        ? null
                        : parseInt(
                            (client.accountant as Accountant).id as string
                          )
                    }
                    onlySearchBar={false}
                    onPress={updateAccountantInClient}
                    changeAcc={client.active}
                  />
                )}
              </div>
            </div>
            {auth?.type !== "client" ? (
              <Tabs
                titles={[
                  { title: "Documentos", icon: IoDocuments },
                  { title: "Dashboard", icon: MdDashboard },
                ]}
                Components={[
                  <DocumentsTable
                    uploadDoc={client?.active}
                    createFolder={client?.active}
                  />,
                  <div className="p-4">
                    <ClientContent nit={idUser} />
                  </div>,
                ]}
              />
            ) : (
              <DocumentsTable
                uploadDoc={client?.active}
                createFolder={client?.active}
              />
            )}
          </>
        );
      case fetching.Error:
        return (
          <div className="p-36 flex justify-center items-center flex-col gap-10">
            <Icon icon={"gear"} size={20} />
            <Span
              text={"Ocurrió un error con el servidor, intenta nuevamente"}
              size={"text-3xl"}
              weight={"font-semibold"}
            />
          </div>
        );
    }
  }, [fetchingState, updateProfilePicture]);

  return (
    <>
      <InfoContainer
        iconName={"user"}
        title={"Perfil cliente"}
        RightSideInfo={
          auth?.type === "admin" &&
          client?.active && (
            <Btn
              text={"Editar perfil"}
              iconName={"edit"}
              onPress={() => setOpenModal(!openModal)}
            />
          )
        }
        goBack={auth?.type === "client" ? false : true}
      >
        {renderClientProfile()}
      </InfoContainer>
      {auth?.type === "admin" && client && client.active && (
        <EditClient
          editProfileInfo={updateProfileInfo}
          updateData={updateData}
          isOpenModal={openModal}
          setIsOpenModal={() => {
            setOpenModal(!openModal);
          }}
          email={client.email}
          names={client.names}
          surnames={client.surnames}
          prefix={client.phoneNumber.split(" ")[0]}
          phoneNumber={client.phoneNumber.split(" ")[1]}
          companyName={client.companyName}
          rol={client.rol}
        />
      )}
    </>
  );
};

export default ClientProfile;
