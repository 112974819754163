import Input from "@components/atoms/InputFormik/Input/Input";
import InputFile from "@components/atoms/InputFormik/InputFile/InputFile";
import Span from "@components/atoms/Span/Span";

import styles from "./AddClient.module.scss";

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  valueCompanyLogo: string | undefined;
  errors: { CNError: string | undefined; CLError: string | undefined };
  touched: any;
}

const CompanyForm = ({
  setFieldValue,
  valueCompanyLogo,
  errors,
  touched,
}: Props) => {
  return (
    <>
      <div className={styles.formField} style={{ width: "100%" }}>
        <Span
          weight={"font-bold"}
          size={"text-base"}
          text={"Nombre de la compañia"}
        />
        <Input
          name={"companyName"}
          type={"text"}
          error={errors.CNError}
          touched={touched.TCN}
        />
      </div>
      <InputFile
        label={"Logo de la compañia"}
        value={valueCompanyLogo}
        setValue={setFieldValue}
        id={"companyLogo"}
        error={errors.CLError}
        accept={".jpg, .png"}
        touched={touched.TCL}
      />
    </>
  );
};

export default CompanyForm;
