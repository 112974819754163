import { TabsRef, Tabs as Tbs } from "flowbite-react";
import { ReactNode, useRef, useState } from "react";

type Titles = {
  title: string;
  icon: any;
};

interface Props {
  titles: Titles[];
  Components?: ReactNode[];
}

const Tabs = ({ titles, Components }: Props): JSX.Element => {
  const tabsRef = useRef<TabsRef>(null);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="flex flex-col gap-3">
        <Tbs
          aria-label="Default tabs"
          style="underline"
          ref={tabsRef}
          onActiveTabChange={(tab) => setActiveTab(tab)}
          className="gap-0"
        >
          {titles.map((values, index) => {
            return (
              <Tbs.Item title={values.title} icon={values.icon}>
                {activeTab === index && Components ? Components[index] : null}
              </Tbs.Item>
            );
          })}
        </Tbs>
      </div>
    </>
  );
};

export default Tabs;
