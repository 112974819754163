import AccountantTemplate from "../../../templates/Accountant/AccountantProfile/AccountantProfile";

type Props = {};

const AccountantProfile = (props: Props) => {
  return (
    <>
      <AccountantTemplate />
    </>
  );
};

export default AccountantProfile;
