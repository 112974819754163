import { Navigate, Outlet } from "react-router-dom";

export const Authenticated = (authCookie: any, auth: any) => {
  if (!authCookie && !auth) {
    return <Navigate to={"/"} />;
  }
  return <Outlet />;
};

export const IsAuthenticated = (authCookie: any) => {
  if (authCookie) {
    return <Navigate to={"/dashboard"} />;
  }

  return <Outlet />;
};

export const ProtectEmailRecovery = (auth: any) => {
  if (auth) {
    return <Navigate to={"/"} />;
  }
  return <Outlet />;
};

export const ProtectRecovery = (passwordRecovery: any) => {
  if (!passwordRecovery?.email) {
    return <Navigate to={"/recovery"} />;
  }
  return <Outlet />;
};

export const ProtectPassword = (passwordRecovery: any) => {
  if (!passwordRecovery?.password) {
    return <Navigate to={"/recoverycode"} />;
  }

  return <Outlet />;
};
