import { toastSuccess } from "@utils/Toast";
import { FC, useContext } from "react";
import { useNavigate } from "react-router";

import GA_MainLogo from "@assets/Logos/GA_MainLogo.png";

import ButtonActive from "@components/atoms/Button/Button";
import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import { RecoveryPasswordContext } from "@providers/providers";

import styles from "./RecoveryPassword.module.scss";

interface Props {}

const RecoveryPassword: FC<Props> = () => {
  const passwordRecovery = useContext(RecoveryPasswordContext);
  const navigate = useNavigate();

  const onAccept = () => {
    passwordRecovery?.setPasswordRecovery(undefined);
    navigate("/");
  };

  return (
    <>
      <div className={styles.RecoveryPassword}>
        <img src={GA_MainLogo} alt="" />
        <p>{"Su nueva contraseña es:"}</p>
        <div className={styles.passwordFieldContainer}>
          <Span
            size={"text-sm"}
            text="Pose el mouse encima para ver la nueva contraseña"
            weight={"font-extralight"}
          />
          <div className={styles.passwordValue}>
            {passwordRecovery?.passwordRecovery?.password && (
              <>
                <div
                  className={styles.copyContainer}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      passwordRecovery!!.passwordRecovery!!.password!!
                    );
                    toastSuccess("Contraseña copiada");
                  }}
                >
                  <Icon icon={"fileCopy"} size={5} color={"gray"} />
                </div>
                <div className={styles.passwordBlur}>
                  <Span
                    text={passwordRecovery!!.passwordRecovery!!.password!!}
                    size={"text-lg"}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <ButtonActive text={"Aceptar"} type={"button"} onClick={onAccept} />
        </div>
      </div>
    </>
  );
};

export default RecoveryPassword;
