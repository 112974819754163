import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/clients`;

export const getDocumentsForCalendar = (
  nit: string,
  startDate: Date,
  endDate: Date,
  token: string
) => {
  return axios.get(`${baseUrl}/${nit}/calendar`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      startDate,
      endDate,
    },
  });
};
