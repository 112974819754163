import { Recovery } from "@entities/Recovery.entity";
import { changePassword, resetPassword } from "@service/Auth.service";
import { errorMessage } from "@utils/Status";
import { toastError, toastSuccess } from "@utils/Toast";
import { Form, Formik } from "formik";
import { FC, useContext, useState } from "react";
import * as Yup from "yup";

import GA_MainLogo from "@assets/Logos/GA_MainLogo.png";

import Anchor from "@components/atoms/Anchor/Anchor";
import Button from "@components/atoms/Button/Button";
import Loader from "@components/atoms/Loader/Loader";
import Span from "@components/atoms/Span/Span";
import TimerClock from "@components/atoms/TimerClock/TimerClock";

import RecoveryField from "@components/molecules/RecoveryField/RecoveryField";

import { RecoveryPasswordContext } from "@providers/providers";

import styles from "./RecoveryForm.module.scss";

interface Props {}

type initialValues = {
  firstValue: string;
  secondValue: string;
  thirdValue: string;
  fourthValue: string;
  fifthValue: string;
  sixthValue: string;
};

const RecoveryForm: FC<Props> = ({}) => {
  const [resendCode, setResendCode] = useState(false);
  const [resetTime, setResetTime] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { passwordRecovery, setPasswordRecovery } = useContext(
    RecoveryPasswordContext
  );

  const initialValues: initialValues = {
    firstValue: "",
    secondValue: "",
    thirdValue: "",
    fourthValue: "",
    fifthValue: "",
    sixthValue: "",
  };

  const validationSchema = Yup.object({
    firstValue: Yup.number().required("Todos los campos son obligatorio"),
    secondValue: Yup.number().required("Todos los campos son obligatorio"),
    thirdValue: Yup.number().required("Todos los campos son obligatorio"),
    fourthValue: Yup.number().required("Todos los campos son obligatorio"),
    fifthValue: Yup.number().required("Todos los campos son obligatorio"),
    sixthValue: Yup.number().required("Todos los campos son obligatorio"),
  });

  const onSubmit = async (values: initialValues) => {
    setIsLoading(true);
    const code = Object.values(values).join("");
    const email = passwordRecovery!!.email;
    if (email) {
      const data = {
        email: email,
        recoveryCode: code,
      };
      try {
        const result = await changePassword(data);
        const dataResult: Recovery = {
          email: email,
          password: result.data.data.password,
        };
        if (result.data.status === "success") {
          setPasswordRecovery(dataResult);
          toastSuccess("Esta es nueva contraseña");
        }
      } catch (error: any) {
        errorMessage(error.response, undefined, [
          { status: 400, msg: "Código incorrecto!" },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resendRecoveryCode = async () => {
    if (!resendCode) {
      toastError(
        "Espere a que termine el tiempo para solicitar un nuevo código"
      );
      return;
    }

    setResetTime(!resetTime);
    const email = passwordRecovery!!.email;
    if (email) {
      try {
        await resetPassword({ email });
      } catch (error: any) {
        errorMessage(error.response);
      }
    }
  };

  const handleTimePassed = () => {
    setResendCode(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, setFieldValue }) => {
        return (
          <Form className={styles.RecoveryForm}>
            <img src={GA_MainLogo} alt="" />
            <div className={styles.infoContainer}>
              <p>
                {
                  "Se ha enviado un correo electrónico con un código de verificación"
                }
              </p>
            </div>
            <div className={styles.subtitleContainer}>
              <Span weight={"font-bold"} text="Código de verificación" />
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div>
                  <RecoveryField
                    initialValues={initialValues}
                    setFieldValue={setFieldValue}
                    error={errors?.sixthValue}
                  />
                  <TimerClock
                    time={1}
                    onTimeExpired={handleTimePassed}
                    reset={resetTime}
                  />
                </div>
                <div className={styles.submitContainer}>
                  <Button text={"ENVIAR"} type={"submit"} />
                  {resendCode && (
                    <Anchor
                      link="#"
                      label={"Reenviar código de verificación"}
                      handleFunction={resendRecoveryCode}
                    />
                  )}
                  <Anchor label={"Volver"} link={"/"} />
                </div>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecoveryForm;
