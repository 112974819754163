import { Accounting } from "@entities/Accounting.entity";
import { fetching } from "@entities/Fetching.entity";
import { getAccountingByNit } from "@service/Accounting.service";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading, toastWarning } from "@utils/Toast";
import { useCallback, useContext, useEffect, useState } from "react";

import { AuthContext } from "@providers/providers";

import {
  BalanceTypes,
  IBalance,
  IResult,
  IWorksheetsNames,
  MonthTypes,
  ResultTypes,
  UseAccountingResult,
} from "./types";

const useAccounting = (nit?: string): UseAccountingResult => {
  const { auth } = useContext(AuthContext);
  const [accounting, setAccounting] = useState<Accounting[] | undefined | null>(
    undefined
  );
  const [lastMonthAcct, setLastMonthAcct] = useState<Accounting | undefined>();
  const [worksheetsNames, setWorksheetsNames] = useState<IWorksheetsNames[]>(
    []
  );
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const currentDate = new Date();

  const [date, setDate] = useState<Date>(currentDate);
  const [month, setMonth] = useState<MonthTypes>(
    currentDate.getMonth() as MonthTypes
  );
  const [balance, setBalance] = useState<BalanceTypes>("asset");
  const [result, setResult] = useState<ResultTypes>("income");

  const getAccountingInfo = async () => {
    const idToast = toastLoading(
      `Obteniendo información para el año ${date.getFullYear()}`
    );
    try {
      const { data, status } = await getAccountingByNit(
        nit ? nit : auth!!.nit,
        date.getFullYear(),
        auth!!.token
      );

      if (status === 204) {
        if (data.data === undefined) {
          setAccounting(undefined);
        } else {
          setAccounting(null);
          toastWarning(`No hay información para el año ${date.getFullYear()}.`);
        }
      } else {
        if (data.data.worksheetsNames.length === 0) {
          setAccounting(undefined);
        }
        setWorksheetsNames(data.data.worksheetsNames);

        if (data.data.emptyAcc) {
          setAccounting(null);
          setLastMonthAcct(undefined);
          setFetchingState(fetching.Success);
          return;
        } else {
          const accountingData = data.data.accountingData;
          const last = accountingData.filter(
            (val: Accounting) =>
              new Date(val.dateByYearMonth).getFullYear() !== date.getFullYear()
          );
          const current = accountingData.filter(
            (val: Accounting) =>
              new Date(val.dateByYearMonth).getFullYear() === date.getFullYear()
          );

          if (current.length < month) {
            setMonth((current.length - 1) as MonthTypes);
          }

          if (current.length === 0) {
            setAccounting(null);
            setLastMonthAcct(undefined);
            toastWarning(
              `No hay información para el año ${date.getFullYear()}.`
            );
          } else {
            setAccounting(current.length === 0 ? null : current);
            setLastMonthAcct(last[0]);
          }
        }
      }
      setFetchingState(fetching.Success);
    } catch (error: any) {
      errorMessage(error.response, idToast);
      setFetchingState(fetching.Error);
    } finally {
      toastUpdateLoading(idToast, "Información obtenida", "success", "bell");
    }
  };

  const getAccountingByMonth = useCallback(() => {
    switch (accounting) {
      case undefined:
        return null;
      case null:
        return null;
      default:
        const indexMonth = accounting.findIndex((el) => {
          return new Date(el.dateByYearMonth).getMonth() === month;
        });

        if (indexMonth !== -1) {
          return {
            current: accounting[indexMonth],
            last: lastMonthAcct
              ? indexMonth > 0
                ? accounting[indexMonth - 1]
                : lastMonthAcct
              : accounting[indexMonth - 1],
          };
        } else {
          setMonth(0);

          return {
            current: accounting[1],
            last: lastMonthAcct
              ? month > 0
                ? accounting[0]
                : lastMonthAcct
              : accounting[0],
          };
        }
    }
  }, [accounting, setAccounting, month, setMonth]);

  const getFullYearAccountingByBalance = (): IBalance | null | undefined => {
    switch (accounting) {
      case undefined:
        return undefined;
      case null:
        return null;
      default:
        const accountingB = accounting.map((val) => {
          const monthNumber = new Date(val.dateByYearMonth).getMonth();
          return {
            month: monthNumber,
            value: val[balance],
          };
        });

        return {
          balance,
          accountingByBalance: accountingB,
        };
    }
  };

  const getFullYearResultByResult = (): IResult | null | undefined => {
    switch (accounting) {
      case undefined:
        return undefined;
      case null:
        return null;
      default:
        const accountingR = accounting.map((val) => {
          const monthNumber = new Date(val.dateByYearMonth).getMonth();
          return {
            month: monthNumber,
            value: val[result],
          };
        });

        return {
          result,
          accountingByResult: accountingR,
        };
    }
  };

  useEffect(() => {
    if (auth) getAccountingInfo();
  }, [date, setDate]);

  return [
    accounting,
    worksheetsNames,
    fetchingState,
    date,
    month,
    setDate,
    setMonth,
    setBalance,
    setResult,
    getAccountingByMonth,
    getFullYearAccountingByBalance,
    getFullYearResultByResult,
  ];
};

export { useAccounting };
