import { Roles } from "@entities/User.entity";

import { IconNames } from "@components/atoms/Icons/Icon";

interface SideMenuOptions {
  link: string;
  iconName: IconNames;
  text: string;
  otherOptions?: string[];
}

const SideMenuOptionsByRol: Record<Roles, SideMenuOptions[]> = {
  admin: [
    {
      link: "/dashboard",
      iconName: "home",
      text: "Dashboard",
    },
    {
      link: "/accountants",
      iconName: "user",
      text: "Contadores",
      otherOptions: ["profile-acc"],
    },
    {
      link: "/clients",
      iconName: "users",
      text: "Clientes",
    },
    {
      link: "/news",
      iconName: "newsPaper",
      text: "Noticias",
    },
    {
      link: "/pqrs",
      iconName: "headset",
      text: "PQRS",
    },
  ],
  accountant: [
    {
      link: "/dashboard",
      iconName: "home",
      text: "Dashboard",
    },
    {
      link: "/profile",
      iconName: "user",
      text: "Mi perfil",
      otherOptions: ["clients"],
    },
    {
      link: "/news",
      iconName: "newsPaper",
      text: "Noticias",
    },
    {
      link: "/pqrs",
      iconName: "headset",
      text: "PQRS",
    },
  ],
  client: [
    {
      link: "/dashboard",
      iconName: "home",
      text: "Dashboard",
    },
    {
      link: "/profile",
      iconName: "user",
      text: "Mi perfil",
      otherOptions: ["clients"],
    },
    {
      link: "/news",
      iconName: "newsPaper",
      text: "Noticias",
    },
    {
      link: "/pqrs",
      iconName: "headset",
      text: "PQRS",
    },
  ],
};

export default SideMenuOptionsByRol;
