import { Document } from "@entities/Document.entity";
import {
  updateDocument,
  updateDocumentStatus,
} from "@service/Documents.service";
import { getFolderPathToEditDocumentStatus } from "@utils/Documents";
import { errorMessage } from "@utils/Status";
import { toastLoading, toastUpdateLoading } from "@utils/Toast";
import { Table } from "flowbite-react";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Excel from "@assets/Icons/Excel.svg";
import FolderNotShared from "@assets/Icons/FolderNotShared.svg";
import FolderShared from "@assets/Icons/FolderShared.svg";
import Pdf from "@assets/Icons/Pdf.svg";

import Icon from "@components/atoms/Icons/Icon";
import Span from "@components/atoms/Span/Span";

import FloatMenu, {
  ButtonInfo,
} from "@components/molecules/FloatMenu/FloatMenu";

import DeleteDocument from "@components/organisms/Delete/DeleteDocument/DeleteDocument";
import DeleteFolder from "@components/organisms/Delete/DeleteFolder/DeleteFolder";
import EditDocument from "@components/organisms/Edit/EditDocument/EditDocument";
import EditFolder from "@components/organisms/Edit/EditFolder/EditFolder";

import { AuthContext } from "@providers/providers";

import styles from "./DocumentRow.module.scss";

const STATUS: any = {
  1: {
    name: "vigente",
    color: "bg-green-500 border-green-700 border-2 border-solid",
  },
  2: {
    name: "vencido",
    color: "bg-red-500 border-red-700 border-2 border-solid",
  },
  3: {
    name: "pago",
    color: "bg-blue-500 border-blue-700 border-2 border-solid",
  },
};

export const DOCUMENT_TYPES = {
  folderNotShared: {
    title: "Carpeta de archivos",
    icon: FolderNotShared,
  },
  folderShared: {
    title: "Carpeta de archivos",
    icon: FolderShared,
  },
  xlsx: {
    title: "Documento de Excel",
    icon: Excel,
  },
  pdf: {
    title: "Documento PDF",
    icon: Pdf,
  },
};

export interface documentRow {
  id: string;
  fileId: string;
  shared: boolean;
  name: string;
  documentType: "folderShared" | "folderNotShared" | "pdf" | "xlsx";
  creationDate: string;
  expirationDate: string;
  status: { name: string; id: string };
  seen: boolean | undefined;
}

type Props = {
  data: documentRow;
  updateData: () => void;
};

const DocumentRow = ({ data, updateData }: Props) => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  // const [status, setStatus] = useState<string>("vigente");
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [isDeleteFolderModalVisible, setIsDeleteFolderModalVisible] =
    useState(false);
  const [isDeleteDocumentModalVisible, setIsDeleteDocumentModalVisible] =
    useState(false);
  const [isEditDocumentModalVisible, setIsEditDocumentModalVisible] =
    useState(false);
  const [isEditFolderModalVisible, setIsEditFolderModalVisible] =
    useState(false);

  const moveToFolder = () => {
    let path = location.pathname.split("/");
    const { name, id } = data;
    path.push(`${name}-${id}`);
    navigate(path.join("/"));
  };

  const downloadFile = async () => {
    const aTag = document.createElement("a");
    aTag.href = data.fileId;
    aTag.target = "_blank";
    aTag.setAttribute("download", `${data.name}.${data.documentType}`);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();

    const path = location.pathname.split("/");
    let folderPath = "";

    let documentData: Partial<Document> = {
      viewed: true,
    };

    if (path.length === 3) {
      folderPath = `${auth?.nit}/folders/root/documents/${data.id}`;
    } else {
      folderPath = `${auth?.nit}/folders/${
        path.pop()?.split("-")[1]
      }/documents/${data.id}`;
    }

    if (auth?.type === "client") {
      try {
        const result = await updateDocument(
          folderPath,
          documentData,
          auth!!.token
        );
        if (result) {
          if (updateData) updateData();
        }
      } catch (error: any) {
        errorMessage(error.response);
      }
    }
  };

  const statusMenu = () => {
    if (data.documentType.includes("folder")) {
      return (
        <Span
          text={
            data.documentType === "folderShared" ? "Editable" : "Solo lectura"
          }
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      );
    }
    if (auth?.type === "client") {
      return (
        <div className={`${styles.statusBox} ${STATUS[data.status.id].color}`}>
          {STATUS[data.status.id].name}
        </div>
      );
    }
    return (
      <select
        name="status"
        id="status"
        onChange={(e) => handleUpdateDocumentStatus(data.id, e.target.value)}
        value={STATUS[data.status.id].name}
        className={`${styles.select} ${STATUS[data.status.id].color}`}
      >
        <option value="vigente">Vigente</option>
        <option value="pago">Pago</option>
        <option value="vencido">Vencido</option>
      </select>
    );
  };

  const options: ButtonInfo[] = [
    {
      icon: "editDoc",
      text: "Editar",
      extraStyles: undefined,
      onPress: () =>
        data.documentType.includes("folder")
          ? setIsEditFolderModalVisible(true)
          : setIsEditDocumentModalVisible(true),
    },
    {
      icon: "trash",
      text: "Eliminar",
      extraStyles: "hover:bg-red-200 hover:rounded-sm",
      onPress: () =>
        data.documentType.includes("folder")
          ? setIsDeleteFolderModalVisible(true)
          : setIsDeleteDocumentModalVisible(true),
    },
  ];

  const renderOptionsModal = () => {
    if (
      data.documentType === "folderShared" ||
      data.documentType === "folderNotShared"
    ) {
      return (
        <>
          <EditFolder
            folder={{ id: data.id, name: data.name, shared: data.shared }}
            isOpenModal={isEditFolderModalVisible}
            setIsOpenModal={() =>
              setIsEditFolderModalVisible(!isEditFolderModalVisible)
            }
            updateData={updateData}
          />
          <DeleteFolder
            folder={{ id: data.id, name: data.name }}
            isOpenModal={isDeleteFolderModalVisible}
            setIsOpenModal={() =>
              setIsDeleteFolderModalVisible(!isDeleteFolderModalVisible)
            }
            updateData={updateData}
          />
        </>
      );
    }
    return (
      <>
        <EditDocument
          document={{
            id: data.id,
            name: data.name,
            type: data.documentType,
            creationDate: data.creationDate,
            dueDate: data.expirationDate,
          }}
          isOpenModal={isEditDocumentModalVisible}
          setIsOpenModal={() =>
            setIsEditDocumentModalVisible(!isEditDocumentModalVisible)
          }
          updateData={updateData}
        />
        <DeleteDocument
          document={{
            id: data.id,
            name: data.name,
            type: data.documentType,
            creationDate: data.creationDate,
            dueDate: data.expirationDate,
          }}
          isOpenModal={isDeleteDocumentModalVisible}
          setIsOpenModal={() =>
            setIsDeleteDocumentModalVisible(!isDeleteDocumentModalVisible)
          }
          updateData={updateData}
        />
      </>
    );
  };

  const handleUpdateDocumentStatus = async (
    documentId: string,
    status: string
  ) => {
    const documentPath = getFolderPathToEditDocumentStatus(
      location.pathname.split("/"),
      documentId
    );
    const documentData = {
      status,
    };
    const idToast = toastLoading("Actualizando estado...");
    try {
      const result = await updateDocumentStatus(
        documentPath,
        documentData,
        auth!!.token
      );
      if (result) {
        toastUpdateLoading(idToast, "Estado actualizado", "success", "bell");
        updateData();
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        {
          status: 400,
          msg: "Error al actualizar el estado",
        },
      ]);
    }
  };

  return (
    <Table.Row className="relative h-[66px] border-y-[1px] border-solid border-gray-300 text-base">
      <Table.Cell className="flex items-center gap-5 h-[66px]">
        <div
          className="flex gap-3 hover:underline cursor-pointer"
          title={data.name}
          onClick={() =>
            data.documentType.includes("folder")
              ? moveToFolder()
              : downloadFile()
          }
        >
          <img src={DOCUMENT_TYPES[data.documentType].icon} alt="" />
          <Span
            text={data.name}
            size="text-base"
            align="text-left"
            extraStyles="w-72 truncate"
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <Span
          text={data.creationDate}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
      <Table.Cell>
        <Span
          text={DOCUMENT_TYPES[data.documentType].title}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
      <Table.Cell>
        <Span
          text={data.expirationDate}
          size="text-sm"
          color="text-gray-700"
          weight="font-light"
        />
      </Table.Cell>
      <Table.Cell>{statusMenu()}</Table.Cell>
      <Table.Cell>
        <div className="flex justify-end items-center gap-6">
          {!data.documentType.includes("folder") ? (
            <div className="bg-[#D9D9D9] w-9 h-7 p-2 flex rounded-lg justify-center items-center">
              {data.seen ? (
                <Icon icon={"eye"} color="var(--gray-soft)" />
              ) : (
                <Icon icon={"eyeSlash"} color="var(--gray-soft)" />
              )}
            </div>
          ) : (
            ""
          )}
          {auth?.type !== "client" ? (
            <button
              onClick={() => setOptionsVisible(true)}
              onBlur={() => setOptionsVisible(false)}
            >
              <Icon icon={"dots"} size={5} />
            </button>
          ) : (
            ""
          )}
        </div>
      </Table.Cell>
      <FloatMenu isOpen={optionsVisible} optionsFM={options} />
      {renderOptionsModal()}
    </Table.Row>
  );
};

export default DocumentRow;
