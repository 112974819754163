export const validateEmailDomain = (value: string): string | null => {
  const domain = value.split("@")[1];
  if (!(domain === "globalaccounters.com")) {
    return "El dominio del email no es válido";
  }

  return null;
};

export const validateDocument = (value: number): string | null => {
  {
    const regexColombia = /^((\d{8})|(\d{10})|(\d{11})|(\d{6}-\d{5}))?$/gm;
    if ((value.toString().match(regexColombia) ?? []).length === 0) {
      return "El número de documento no es válido";
    }
    return null;
  }
};

export const validateLength = (value: number): string | null => {
  if (value.toString().length !== 10) {
    return "El número de teléfono debe ser 10 digitos.";
  }
  return null;
};

export const validateNIT = (nit: string) => {
  let regexNitColombia = /(^[0-9]{9}-{1}[0-9]{1})/g;
  if (!nit.includes("-")) {
    regexNitColombia = /^[0-9]{9}$/g;
  }

  if (!((nit.match(regexNitColombia) ?? []).length > 0)) {
    return "El NIT no es de Colombia o no es válido.";
  }

  return null;
};
