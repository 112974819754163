import { Accountant } from "@entities/Accountant.entity";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/accountants`;

export const getTotalAccountants = (active: boolean, token: string) => {
  return axios.get(`${baseUrl}/total`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      active,
    },
  });
};

export const getAllAccountants = (
  active: boolean,
  token: string,
  pagination: number
) => {
  return axios.get(baseUrl, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      page: pagination,
      active,
    },
  });
};

export const getAllAccountantsLikeIdOrNames = (
  param: string,
  token: string
) => {
  return axios.get(`${baseUrl}/search`, {
    params: {
      q: param,
    },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getAccountantById = (id: string, token: string) => {
  return axios.get(`${baseUrl}/acc/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addAccountant = (
  accountant: Partial<Accountant>,
  token: string
) => {
  return axios.post(baseUrl, accountant, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateAccountant = (
  id: string,
  accountant: Partial<Accountant>,
  token: string
) => {
  return axios.patch(`${baseUrl}/${id}`, accountant, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deactiveAccountant = (id: string, data: any, token: string) => {
  return axios.post(`${baseUrl}/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
