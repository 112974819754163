import { Client } from "@entities/Client.entity";
import { fetching } from "@entities/Fetching.entity";
import {
  getAllClients,
  getClientById,
  getClientsLikeQueryParams,
  getTotalClients,
  updateClient,
} from "@service/Client.service";
import { errorMessage } from "@utils/Status";
import { toastError, toastLoading, toastUpdateLoading } from "@utils/Toast";
import { useCallback, useContext, useEffect, useState } from "react";

import { AuthContext } from "@providers/providers";

import {
  UseClientByIdResult,
  UseClientsByParamsResult,
  UseClientsResult,
} from "./types";

const useClients = (accId?: string): UseClientsResult => {
  const { auth } = useContext(AuthContext);
  const [clients, setClients] = useState<Client[] | undefined>(undefined);
  const [pagination, setPagination] = useState<number>(1);
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );
  const [totalClients, setTotalClients] = useState<number>(0);
  const [active, setActive] = useState<boolean>(true);

  const getTClients = async () => {
    try {
      if (auth) {
        const { data } = await getTotalClients(active, auth!!.token, accId);
        setTotalClients(data.data.total);
      }
    } catch (error: any) {
      errorMessage(error.response);
    }
  };

  const getClients = useCallback(async () => {
    try {
      if (auth) {
        const { data } = await getAllClients(
          active,
          auth!!.token,
          pagination,
          accId
        );
        setClients(data.data);
        setFetchingState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.response);
      setFetchingState(fetching.Error);
    }
  }, [pagination, setPagination, active, setActive]);

  useEffect(() => {
    if (auth) {
      getClients();
      getTClients();
    }
  }, [pagination, setPagination, active, setActive]);

  const updateData = () => {
    if (auth) {
      getClients();
      getTClients();
    }
  };

  return [
    clients,
    totalClients,
    active,
    fetchingState,
    pagination,
    setPagination,
    updateData,
    setActive,
  ];
};

const useClientsByParams = (): UseClientsByParamsResult => {
  const { auth } = useContext(AuthContext);

  const [clientsFound, setClientsFound] = useState<Client[] | undefined>();
  const [clientToSearch, setClientToSearch] = useState<string>("");
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );

  const getSearchClients = useCallback(async () => {
    if (clientToSearch.length <= 0) return setClientsFound(undefined);
    try {
      if (auth) {
        const { data } = await getClientsLikeQueryParams(
          clientToSearch,
          auth!!.token
        );
        setClientsFound(data.data);
        setFetchingState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.response, undefined, [
        { status: 403, msg: "Error al cargar los datos de los contadores!" },
      ]);
      setFetchingState(fetching.Error);
    }
  }, [clientToSearch, setClientToSearch]);

  return [clientsFound, clientToSearch, setClientToSearch, getSearchClients];
};

const useClientById = (idUser: string): UseClientByIdResult => {
  const { auth } = useContext(AuthContext);

  const [client, setClient] = useState<Client | undefined>();
  const [fetchingState, setFetchingState] = useState<fetching>(
    fetching.Loading
  );
  const [fetchingStatePicture, setFetchingStatePicture] = useState<fetching>(
    fetching.Loading
  );

  const getClientInfo = useCallback(async () => {
    try {
      const result = await getClientById(idUser, auth!!.token);
      if (result) {
        setClient(result.data.data.cliente);
        setFetchingState(fetching.Success);
      }
    } catch (error: any) {
      errorMessage(error.response);
      setFetchingState(fetching.Error);
    }
  }, [fetchingStatePicture, setFetchingStatePicture]);

  const updateProfilePicture = async (val: File | undefined) => {
    if (!val) return toastError("Imagen no adjuntada, inténtelo nuevamente.");
    const idToast = toastLoading("Actualizando información...");
    try {
      const result = await updateClient(
        idUser,
        { companyLogo: val },
        auth!!.token
      );

      if (result) {
        setFetchingStatePicture(fetching.Success);
        toastUpdateLoading(
          idToast,
          "Imagen del logo de la compañia actualizada",
          "success",
          "bell"
        );
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        { status: 400, msg: "Error al actualizar al cliente!" },
      ]);
    }
  };

  const updateProfileInfo = async (data: Partial<Client>) => {
    const idToast = toastLoading("Actualizando información...");
    try {
      const result = await updateClient(idUser, data, auth!!.token);
      if (result) {
        toastUpdateLoading(
          idToast,
          "Se actualizó la información",
          "success",
          "bell"
        );
      }
    } catch (error: any) {
      errorMessage(error.response, idToast, [
        { status: 400, msg: "Error al actualizar al cliente!" },
      ]);
    }
  };

  const updateAccountantInClient = async (accInfo: number) => {
    if (accInfo) {
      const idToast = toastLoading("Actualizando información...");
      try {
        await updateClient(idUser, { accountant: accInfo }, auth!!.token);
        toastUpdateLoading(
          idToast,
          "Contador asignado actualizado!",
          "success",
          "bell"
        );
      } catch (error: any) {
        errorMessage(error.response, idToast);
      }
    }
  };

  const updateData = () => {
    if (auth) getClientInfo();
  };

  useEffect(() => {
    if (auth) getClientInfo();

    if (fetchingStatePicture === fetching.Success) {
      setFetchingStatePicture(fetching.Loading);
      getClientInfo();
    }
  }, [fetchingStatePicture, setFetchingStatePicture]);

  return [
    client,
    fetchingState,
    updateProfilePicture,
    updateProfileInfo,
    updateAccountantInClient,
    updateData,
  ];
};

export { useClients, useClientsByParams, useClientById };
