import { login } from "@service/Auth.service";
import { addCookie } from "@utils/Cookie";
import { toastSuccess } from "@utils/Toast";
import { startTokenValidationInterval } from "@utils/Token";
// import { startTokenValidationInterval } from "@utils/Token";
import { Form, Formik } from "formik";
import { FC, useContext, useState } from "react";
import * as Yup from "yup";

import Lock from "@assets/Icons/Lock.svg";
import User from "@assets/Icons/User.svg";
import GA_MainLogo from "@assets/Logos/GA_MainLogo.png";

import Anchor from "@components/atoms/Anchor/Anchor";
import Button from "@components/atoms/Button/Button";
import Input from "@components/atoms/InputFormik/Input/Input";
import Loader from "@components/atoms/Loader/Loader";
import TitleH1 from "@components/atoms/TitleH1/TitleH1";

import { AuthContext } from "@providers/providers";

import { errorMessage } from "../../../../utils/Status";
import styles from "./LoginForm.module.scss";

interface Props {}

const LoginForm: FC<Props> = () => {
  const { setAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Email no valido")
      .required("Este campo es obligatorio"),
    password: Yup.string().required("Este campo es obligatorio"),
  });

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const { data, status } = await login(values);
      if (status === 200) {
        setAuth(data.data);
        addCookie("auth", data.data);
        startTokenValidationInterval(false);
        toastSuccess(`Bienvenido/a ${data.data.names}`);
      }
    } catch (error: any) {
      let msg = undefined;

      if (
        error.response.status === 401 &&
        error.response.data.data.message.includes("Usuario deshabilitado")
      ) {
        msg = "Usuario deshabilitado";
      }
      errorMessage(
        error.response,
        undefined,
        msg ? [{ status: 401, msg }] : undefined
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form className={styles.LoginForm}>
            <img src={GA_MainLogo} alt="" />
            <div className={styles.title}>
              <TitleH1
                text="Iniciar Sesión"
                color={"text-gray-soft"}
                weight={"font-bold"}
              />
            </div>
            <div className={styles.fields}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.formField}>
                    <Input
                      name={"email"}
                      placeholder={"Email"}
                      type={"email"}
                      icon={User}
                    />
                  </div>
                  <div className={styles.formField}>
                    <Input
                      name={"password"}
                      placeholder={"Contraseña"}
                      type={"password"}
                      icon={Lock}
                    />
                  </div>
                  <div className={styles.link}>
                    <Anchor
                      label={"¿Olvidaste tu contraseña?"}
                      link={"/recovery"}
                    />
                  </div>
                  <Button text={"INICIAR SESIÓN"} type={"submit"} />
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
