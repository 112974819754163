type Props = {
  color?: string;
  style: string;
};

const ArrowCompleteDown = ({ color = "black", style }: Props) => {
  return (
    <svg
      className={style}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 14"
      style={{ color: color }}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 1v12m0 0 4-4m-4 4L1 9"
      />
    </svg>
  );
};

export default ArrowCompleteDown;
