import Card from "@components/atoms/Card/Card";
import PieChart from "@components/atoms/Chart/PieChart/PieChart";
import Text from "@components/atoms/Text/Text";

interface Props {
  netIncome: number | null;
  debtEquityRatio: number | null;
  indebtedness: number | null;
  netIncomeIndex: number | null;
  returnOnEquity: number | null;
}

const CardsForFinancialIndicator = ({
  netIncome,
  debtEquityRatio,
  indebtedness,
  netIncomeIndex,
  returnOnEquity,
}: Props): JSX.Element => {
  return (
    <div className="flex gap-[30px] flex-wrap xl:flex-nowrap">
      <Card
        style={{ justifyContent: "space-between" }}
        extraStyles="xl:max-w-[300px] max-h-[360px] flex flex-col w-full items-center"
      >
        <div className="flex flex-col items-center gap-2 justify-between">
          <div>
            <Text text="Relación deuda a patrimonio" size="text-2xl" />
          </div>
          <PieChart
            data={
              debtEquityRatio
                ? [
                    {
                      value:
                        debtEquityRatio > 0
                          ? debtEquityRatio
                          : debtEquityRatio * -1,
                      fill: debtEquityRatio > 0 ? "#27d7f9" : "#C81E1E",
                    },
                    {
                      value:
                        debtEquityRatio > 0
                          ? 1 - debtEquityRatio
                          : 1 - debtEquityRatio * -1,
                    },
                  ]
                : []
            }
            width={200}
            height={200}
            label={true}
            labelValue={
              debtEquityRatio ? `${(debtEquityRatio * 100).toFixed(2)}%` : "-"
            }
          />
        </div>
      </Card>

      <div className="flex lg-xl:flex-row flex-col gap-[30px] w-full justify-between">
        <Card extraStyles="max-h-[160px] lg-xl:max-w-[50%] w-full">
          <div className="flex flex-row gap-2 justify-between">
            <div className="h-full flex flex-col justify-evenly w-[70%]">
              <Text
                text={
                  returnOnEquity ? `${(returnOnEquity * 100).toFixed(2)}%` : "-"
                }
                color={
                  returnOnEquity
                    ? returnOnEquity > 0
                      ? "text-blue-600"
                      : "text-red-600"
                    : "text-blue-600"
                }
                weight="font-bold"
                align="text-left"
                size="text-3xl"
              />
              <Text
                text="Relación de patrimonio"
                size="text-2xl"
                align="text-left"
              />
            </div>
            <PieChart
              data={
                returnOnEquity
                  ? [
                      {
                        value:
                          returnOnEquity > 0
                            ? returnOnEquity
                            : returnOnEquity * -1,
                        fill: returnOnEquity > 0 ? "#27d7f9" : "#C81E1E",
                      },
                      {
                        value:
                          returnOnEquity > 0
                            ? 1 - returnOnEquity
                            : 1 - returnOnEquity * -1,
                      },
                    ]
                  : []
              }
              width={120}
              height={120}
            />
          </div>
        </Card>
        <Card extraStyles="max-h-[160px] lg-xl:max-w-[50%] w-full">
          <div className="flex flex-row gap-2 justify-between">
            <div className="h-full flex flex-col justify-evenly w-[70%]">
              <Text
                text={
                  indebtedness ? `${(indebtedness * 100).toFixed(2)}%` : "-"
                }
                color={
                  indebtedness
                    ? indebtedness > 0
                      ? "text-blue-600"
                      : "text-red-600"
                    : "text-blue-600"
                }
                weight="font-bold"
                align="text-left"
                size="text-3xl"
              />
              <Text text="Endeudamiento" size="text-2xl" align="text-left" />
            </div>
            <PieChart
              data={
                indebtedness
                  ? [
                      {
                        value:
                          indebtedness > 0 ? indebtedness : indebtedness * -1,
                        fill: indebtedness > 0 ? "#27d7f9" : "#C81E1E",
                      },
                      {
                        value:
                          indebtedness > 0
                            ? 1 - indebtedness
                            : 1 - indebtedness * -1,
                      },
                    ]
                  : []
              }
              width={120}
              height={120}
            />
          </div>
        </Card>
      </div>

      <Card
        style={{ justifyContent: "space-between" }}
        extraStyles="xl:max-w-[300px] max-h-[360px] flex flex-col w-full items-center"
      >
        <div className="flex flex-col items-center gap-2 justify-between">
          <div>
            <Text text="Margen de utilidad neta" size="text-2xl" />
            <Text
              text={
                netIncome
                  ? new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                    }).format(netIncome)
                  : "-"
              }
              color={
                netIncome && netIncome < 0 ? "text-red-600" : "text-blue-600"
              }
              weight="font-bold"
              size="text-3xl"
            />
          </div>
          <PieChart
            data={
              netIncomeIndex
                ? [
                    {
                      value:
                        netIncomeIndex > 0
                          ? netIncomeIndex
                          : netIncomeIndex * -1,
                      fill: netIncomeIndex > 0 ? "#27d7f9" : "#C81E1E",
                    },
                    {
                      value:
                        netIncomeIndex > 0
                          ? 1 - netIncomeIndex
                          : 1 - netIncomeIndex * -1,
                    },
                  ]
                : []
            }
            width={200}
            height={200}
            label={true}
            labelValue={
              netIncomeIndex ? `${(netIncomeIndex * 100).toFixed(2)}%` : "-"
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default CardsForFinancialIndicator;
